import React, { useState } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import OuterMenu from "./outerMenu";
import OuterFooter from "./outerFooter";
import Footer from "./mobileFooter";
import "../css/CheckoutForm.css";
import { stripePromise } from "./stripePromise";

const stripeKey = loadStripe(stripePromise);

const PayNow = () => {
  const matches = UseMediaQuery("(max-width: 992px)");

  return (
    <div className="">
      <div className=" mb-5">
        <div className="mt-3 border-b border-grey2 pb-2">
          <div className="pb-2">
            <OuterMenu page="other" cartClr="black" />
          </div>
        </div>
        <div className="stripePay mt-12 xl:px-44 lg:px-44 lg3:px-36 lg2:px-36 md:px-28 sm:px-20 xs:px-10">
          <div className="w-2/4 p-3 border border-grey3 rounded">
            <Elements stripe={stripeKey}>
              <CheckoutForm />
            </Elements>
          </div>
        </div>

        <div className="mt-5">
          <OuterFooter />
        </div>
        {matches ? <Footer /> : ""}
      </div>
    </div>
  );
};

export default PayNow;
