import React from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { NavLink } from "react-router-dom";

function ProfessionalMenu() {
  const matches = UseMediaQuery("(max-width: 992px)");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <div>
      {matches ? (
        ""
      ) : (
        <div className="bg-white border-r border-grey7  h-screen pt-5 pl-3 pr-3 min-w-[250px]">
          <div className="">
            <img alt="logo" src="img/logo.png" />
          </div>
          <div className="mt-8 deskMenu">
            <NavLink
              className="w-full block commonA"
              exact
              activeClassName="active"
              to="/professionalDashboard"
            >
              <span className="inline commonMenu dashMenu">s</span>
              <span className="ml-3">News Feed</span>
            </NavLink>
            <NavLink
              className="w-full block mt-4 commonA"
              exact
              activeClassName="active"
              to="/myPost"
            >
              <span className="inline commonMenu postMenu">s</span>
              <span className="ml-3">My Post</span>
            </NavLink>
            <NavLink
              className="w-full block mt-4 commonA"
              exact
              activeClassName="active"
              to={`/${
                userInfo && userInfo.profile_name ? userInfo.profile_name : ""
              }`}
            >
              <span className="inline commonMenu profMenu">s</span>
              <span className="ml-3">Virtual Profile</span>
            </NavLink>
            <NavLink
              exact
              activeClassName="active"
              to="/appointment"
              className="w-full block mt-4 commonA"
            >
              <span className="inline commonMenu bookingMenu">s</span>
              <span className="ml-3">Appointments</span>
            </NavLink>
            <NavLink
              exact
              activeClassName="active"
              to="/addAppointment"
              className="w-full block mt-4 commonA"
            >
              <span className="inline commonMenu bookingMenu">s</span>
              <span className="ml-3">Add Appointment</span>
            </NavLink>
            {/* <NavLink
              exact
              activeClassName="active"
              to="/#"
              className="w-full block mt-4 commonA"
            >
              <span className="inline commonMenu servicesMenu">s</span>
              <span className="ml-3">Services</span>
            </NavLink> */}
            <NavLink
              exact
              activeClassName="active"
              to="/stem"
              className="w-full block mt-4 commonA"
            >
              <span className="inline commonMenu stemMenu">s</span>
              <span className="ml-3">STEM Program</span>
            </NavLink>
            <NavLink
              exact
              activeClassName="active"
              to="/tax"
              className="w-full block mt-4 commonA"
            >
              <span className="inline commonMenu taxMenu">s</span>
              <span className="ml-3">Advisory</span>
            </NavLink>
            <NavLink
              exact
              activeClassName="active"
              to="/professionalReport"
              className="w-full block mt-4 commonA"
            >
              <span className="inline commonMenu taxMenu">s</span>
              <span className="ml-3">Report</span>
            </NavLink>
            {/* <NavLink
              exact
              activeClassName="active"
              to="/tutorial"
              className="w-full block mt-4 commonA"
            >
              <span className="inline commonMenu tutorialMenu">s</span>
              <span className="ml-3">Tutorial</span>
            </NavLink> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfessionalMenu;
