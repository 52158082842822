import React from "react";
import ReactDOM from "react-dom";
import GoogleContacts from "react-google-contacts";

const GoogleContactsComponent = () => {
  const responseCallback = (response) => {
    console.log(response, "himaniii");
  };

  return (
    <div>
      <h1>My Google Contacts</h1>
      <GoogleContacts
        clientId={
          "1053095089432-3sq89pl9genmdgv501ribj3ep2ea4qik.apps.googleusercontent.com"
        }
        onSuccess={responseCallback}
        onFailure={responseCallback}
      >
        <span> Import from Gmail</span>
      </GoogleContacts>
    </div>
  );
};

export default GoogleContactsComponent;
