import React, { useState, useEffect } from "react";
import Logo from "./logo";
import axios from "axios";
import Spinner from "./spinner";
import { useNavigate } from "react-router-dom";
import { UseMediaQuery } from "../hooks/useMediaQuery";

const Interest = () => {
  const [interestCat, setInterestCat] = useState([]);
  const [categoryList, setCategoryList] = useState({});
  const [selectedCategory, setSelectedCategory] = useState();
  const [subCategoryList, setSubCategoryList] = useState();
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const [intErr, setIntErr] = useState(false);
  const [loading, setLoading] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();
  const matches = UseMediaQuery("(max-width: 992px)");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + "interest/category", {
          headers: { authorization: "Bearer " + token },
        });
        if (response) {
          setInterestCat(response.data);
          setSelectedCategory(response.data[0]._id);
          //call subcategories on behalf of categories
          try {
            const subCategoryResponse = await axios.get(
              API_URL +
                "interest/sub-category-by-category/" +
                response.data[0]._id,
              { headers: { authorization: "Bearer " + token } }
            );
            if (subCategoryResponse) {
              setSubCategoryList(subCategoryResponse.data);
            }
          } catch (error) {
            console.error("Error fetching sub categories:", error);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleCat = async (cat) => {
    setSelectedCategory(cat);
    try {
      const subCategoryResponse = await axios.get(
        API_URL + "interest/sub-category-by-category/" + cat,
        { headers: { authorization: "Bearer " + token } }
      );
      if (subCategoryResponse) {
        setSubCategoryList(subCategoryResponse.data);
      }
    } catch (error) {
      console.error("Error fetching sub categories:", error);
    }
  };

  const handleSubCat = (subCat) => {
    let newData = "";

    if (categoryList.hasOwnProperty(selectedCategory)) {
      //check if sub category already exist for that category
      const idsArray = categoryList[selectedCategory]
        .split(",")
        .map((id) => id.trim());
      if (idsArray.includes(subCat)) {
        //sub category exist for that category so remove sub category(uncheck functionality)
        const updatedIds = idsArray.filter((id) => id !== subCat);
        newData = updatedIds.join(",");
      } else {
        //add another sub category comma separated
        newData = `${categoryList[selectedCategory]},${subCat}`;
      }
    } else {
      //new category added for sub category
      newData = subCat;
    }

    setCategoryList((prevState) => ({
      ...prevState,
      [selectedCategory]: newData,
    }));
  };

  const handleSkip = async () => {
    setLoading(true);
    try {
      await axios
        .put(API_URL + "user/skip-interest/" + userInfo.user_id, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          setLoading(false);
          if (userInfo.user_type === "professional") {
            //navigate("/professionalDashboard");
            navigate("appointment");
          } else if (userInfo.user_type === "admin") {
            navigate("/adminDashboard");
          } else {
            navigate("/enthusiastDashboard");
          }
        })
        .catch(function (error) {
          console.log(error, " error");
          setLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async () => {
    if (
      Object.keys(categoryList).length === 0 &&
      categoryList.constructor === Object
    ) {
      setIntErr(true);
    } else {
      setLoading(true);
      setIntErr(false);
      let data = {};
      data["user_id"] = userInfo.user_id;

      let catInfo = {};
      data["categories"] = [];
      Object.keys(categoryList).forEach((category) => {
        catInfo = { category_id: category };
        catInfo["subcategories"] = [];
        categoryList[category].split(",").forEach((subCat) => {
          catInfo["subcategories"].push({ subcategory_id: subCat });
        });
        // catInfo["subcategories"] = {
        //   subcategory_id: categoryList[category],
        // };
        data["categories"].push(catInfo);
      });

      try {
        await axios
          .post(API_URL + "user/add-interest", data, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            if (userInfo.user_type === "professional") {
              navigate("/professionalDashboard");
            } else if (userInfo.user_type === "admin") {
              navigate("/adminDashboard");
            } else {
              navigate("/enthusiastDashboard");
            }

            setLoading(false);
          })
          .catch(function (error) {
            console.log(error, " error");
            setLoading(false);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      {!matches ? <Logo /> : ""}
      <div className="mt-4">
        <div className="text-black2 font-normal text-3xl lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl xxs:text-xl text-center ivymode">
          Most Interested
        </div>
        <div className="mt-2.5 text-center text-base lg:text-base md:text-base sm:text-sm xs:text-xs xxs:text-xs">
          This will customized your new home feed
        </div>
      </div>

      {interestCat && interestCat.length > 0 ? (
        <div>
          <div className="mt-4 bg-white px-1 py-2">
            <div className="bg-white1">
              <div
                className={` ${
                  matches
                    ? "text-grey6 p-1 flex overflow-x-auto"
                    : "grid grid-cols-6 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-4 xs:grid-cols-3 xxs:grid-cols-3 text-grey6 p-1"
                }`}
              >
                {interestCat.map((interest) => (
                  <button
                    className={` ${
                      interest._id === selectedCategory ? "bg-white " : ""
                    } px-2 py-2 text-base lg:text-base md:text-base sm:text-sm xs:text-sm xxs:text-xs`}
                    onClick={() => handleCat(interest._id)}
                  >
                    {interest.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
          {subCategoryList && subCategoryList.length > 0 ? (
            <>
              {subCategoryList.length > 0 ? (
                <div className="mb-5 overflow-y-scroll max-h-[287px] mt-4 grid grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-4 xs:grid-cols-3 xxs:grid-cols-2 gap-3">
                  {subCategoryList.map((subCat) => (
                    <div className="relative">
                      <img
                        alt="interest"
                        src={` ${subCat.image ? subCat.image : "img/int.png"}`}
                        className="w-full h-[160px] lg:h-[160px] md:h-[160px] sm:h-[160px] xs:h-[140px] xxs:h-[130px]"
                      />
                      <span className="text-white text-center bottom-0 imgInt">
                        {subCat.name}
                      </span>
                      <span className="absolute right-0.5 top-0.5">
                        <label class="container ">
                          <input
                            type="checkbox"
                            checked={
                              categoryList[selectedCategory]
                                ? categoryList[selectedCategory]
                                    .split(",")
                                    .map((id) => id.trim())
                                    .includes(subCat._id)
                                : ""
                            }
                            //   checked={() =>
                            //     handleChecked() ? "checked" : "checked"
                            //   }
                            onClick={() => handleSubCat(subCat._id)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center mt-36 mb-36 font-bold text-lg">
                  No data added..
                </div>
              )}

              {loading ? (
                <Spinner /> // Show the spinner while loading
              ) : (
                <div className="float-right mt-4 absolute right-8 bottom-3">
                  {intErr ? (
                    <div className="text-red">
                      Please select at least one interest.
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="float-right mt-1">
                    <button
                      onClick={() => handleSkip()}
                      className="mr-3 bg-white px-3 py-2 rounded"
                    >
                      Skip
                    </button>
                    <button
                      type="submit"
                      onClick={() => handleSubmit()}
                      className="bg-green1 px-3 py-2 text-white rounded"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="text-center font-bold mt-5">
              No sub-category added
            </div>
          )}
        </div>
      ) : (
        <div className="text-center font-bold mt-5">No category added</div>
      )}
    </div>
  );
};

export default Interest;
