import React from "react";
import Menu from "./menu";
import Header from "./header";
import Footer from "./mobileFooter";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { useSnapCarousel } from "react-snap-carousel";

const Stem = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const { scrollRef, pages, activePageIndex, next, prev, goTo } =
    useSnapCarousel();

  return (
    <div>
      <div className="bg-white ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div className={`mb-10 min-h-screen `}>
              <div className="">
                <div className={` ${matches ? "py-10" : "py-20"} `}>
                  <img
                    src="img/stem.png"
                    className={` ${
                      matches ? "max-w-[300px]" : "max-w-[800px]"
                    } mx-auto`}
                  />
                </div>

                <div className={` mt-3 ${matches ? "px-3" : "px-10"}  stemBg`}>
                  <div className="grid grid-cols-3 ">
                    <div className={` ${matches ? "py-8" : "py-28"} `}>
                      <img src="img/stem2.png" />
                    </div>
                    <div className="relative">
                      <img
                        src="img/stem3.png"
                        className={`absolute ${
                          matches
                            ? "top-[-20px] max-h-[100px] left-[15px]"
                            : "top-[-75px] left-[30px]"
                        } `}
                      />
                    </div>
                    <div className={` ${matches ? "py-8" : "py-28"} `}>
                      <img src="img/stem4.png" />
                    </div>
                  </div>
                </div>
                <div className="lg:mt-12 md:mt-10 sm:mt-10 xs:mt-10 xxs:mt-6 lg:px-28 md:px-20 sm:px-20 xs:px-6 xxs:px-4">
                  <div className="text-2xl">Diverse Topics</div>
                  <div className="block mt-3">
                    <div className="inline-block ivymode text-black2 lg:text-4xl md:text-3xl sm:text-2xl xs:text-xl xxs:text-sm font-normal">
                      From Coding Bootcamps To Virtual Science Fairs, <br />{" "}
                      Discover A Variety Of STEM Disciplines.
                    </div>
                    <div
                      className={`inline-block ${
                        matches ? "mt-2" : "float-right"
                      }  `}
                    >
                      {/* <div className="bg-green1 text-white rounded p-2">
                        See More
                      </div> */}
                    </div>
                  </div>

                  <div className="mt-5">
                    <ul
                      className={` flex overflow-auto feature-carousel ${
                        matches ? "max-w-[300px]" : "max-w-[1000px]"
                      }`}
                      ref={scrollRef}
                      style={{
                        scrollSnapType: "x mandatory",
                      }}
                    >
                      <li
                        className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                          matches ? "max-w-[185px]" : "max-w-[285px]"
                        } justify-center items-center`}
                      >
                        <a href="https://phet.colorado.edu/" target="_blank">
                          <img
                            src="img/simulations.jpg"
                            className={` ${
                              matches ? "h-40" : "h-56"
                            } w-auto text-center mt-4 inline rounded`}
                          />
                          <span
                            className={`py-3 w-full ${
                              matches ? "text-base" : "text-xl"
                            } block font-semibold mt-1 `}
                          >
                            PhET Simulations
                            <span
                              className={`text-grey13 font-normal ${
                                matches ? "text-[10px]" : "text-xs"
                              } block mt-1`}
                            >
                              Founded in 2002 by Nobel Laureate Carl Wieman, the
                              PhET Interactive Simulations project at the
                              University of Colorado Boulder creates free
                              interactive math and science simulations. PhET
                              sims are based on extensive education research and
                              engage students through an intuitive, game-like
                              environment where students learn through
                              exploration and discovery.
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                          matches ? "max-w-[185px]" : "max-w-[285px]"
                        } justify-center items-center`}
                      >
                        <a href="https://www.khanacademy.org/" target="_blank">
                          <img
                            src="img/khan.png"
                            className={` ${
                              matches ? "h-40" : "h-56"
                            } w-auto text-center mt-4 inline rounded`}
                          />
                          <span
                            className={`py-3 w-full ${
                              matches ? "text-base" : "text-xl"
                            } block font-semibold mt-1 `}
                          >
                            Khan Academy
                            <span
                              className={`text-grey13 font-normal ${
                                matches ? "text-[10px]" : "text-xs"
                              } block mt-1`}
                            >
                              For every student, every classroom. We’re a
                              nonprofit with the mission to provide a free,
                              world-class education for anyone, anywhere.
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                          matches ? "max-w-[185px]" : "max-w-[285px]"
                        } justify-center items-center`}
                      >
                        <a
                          href="https://www.nasa.gov/learning-resources/"
                          target="_blank"
                        >
                          <img
                            src="img/nasa.png"
                            className={` ${
                              matches ? "h-40" : "h-56"
                            } w-auto text-center mt-4 inline rounded`}
                          />
                          <span
                            className={`py-3 w-full ${
                              matches ? "text-base" : "text-xl"
                            } block font-semibold mt-1 `}
                          >
                            NASA STEM Engagement
                            <span
                              className={`text-grey13 font-normal ${
                                matches ? "text-[10px]" : "text-xs"
                              } block mt-1`}
                            >
                              Space To Learn We’re launching learning to new
                              heights with STEM resources that connect
                              educators, students, parents and caregivers to the
                              inspiring work at NASA.
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                          matches ? "max-w-[185px]" : "max-w-[285px]"
                        } justify-center items-center`}
                      >
                        <a href="https://code.org/" target="_blank">
                          <img
                            src="img/code.png"
                            className={` ${
                              matches ? "h-40" : "h-56"
                            } w-auto text-center mt-4 inline rounded`}
                          />
                          <span
                            className={`py-3 w-full ${
                              matches ? "text-base" : "text-xl"
                            } block font-semibold mt-1 `}
                          >
                            Code.org
                            <span
                              className={`text-grey13 font-normal ${
                                matches ? "text-[10px]" : "text-xs"
                              } block mt-1`}
                            >
                              Help students become superheroes! Join our highly
                              supportive Professional Learning Program for
                              middle and high school educators.
                            </span>
                          </span>
                        </a>
                      </li>

                      <li
                        className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                          matches ? "max-w-[185px]" : "max-w-[285px]"
                        } justify-center items-center`}
                      >
                        <a href="https://stemworks.wested.org/" target="_blank">
                          <img
                            src="img/home-hero.png"
                            className={` ${
                              matches ? "h-40" : "h-56"
                            } w-auto text-center mt-4 inline rounded`}
                          />
                          <span
                            className={`py-3 w-full ${
                              matches ? "text-base" : "text-xl"
                            } block font-semibold mt-1 `}
                          >
                            STEM works
                            <span
                              className={`text-grey13 font-normal ${
                                matches ? "text-[10px]" : "text-xs"
                              } block mt-1`}
                            >
                              The nation’s leading STEM education programs, all
                              in one place. STEMworks is your go-to resource for
                              finding and investing in the nation’s leading STEM
                              education programs for students and educators from
                              pre-kindergarten through highschool.
                            </span>
                          </span>
                        </a>
                      </li>

                      <li
                        className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                          matches ? "max-w-[185px]" : "max-w-[285px]"
                        } justify-center items-center`}
                      >
                        <a
                          href="https://www.exploratorium.edu/"
                          target="_blank"
                        >
                          <img
                            src="https://www.exploratorium.edu/sites/default/files/styles/responsive_1550w/public/2023-03/visit_field-trips_marquee.jpg.webp?itok=ZBwIMWlv"
                            className={` ${
                              matches ? "h-40" : "h-56"
                            } w-auto text-center mt-4 inline rounded`}
                          />
                          <span
                            className={`py-3 w-full ${
                              matches ? "text-base" : "text-xl"
                            } block font-semibold mt-1 `}
                          >
                            Exploratorium
                            <span
                              className={`text-grey13 font-normal ${
                                matches ? "text-[10px]" : "text-xs"
                              } block mt-1`}
                            >
                              The Exploratorium is a public learning laboratory
                              where you can explore the world through science,
                              art, and human perception.
                            </span>
                          </span>
                        </a>
                      </li>

                      <li
                        className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                          matches ? "max-w-[185px]" : "max-w-[285px]"
                        } justify-center items-center`}
                      >
                        <a
                          href="https://www.nationalgeographic.org/society/education-resources/"
                          target="_blank"
                        >
                          <img
                            src="img/imgt.jpg"
                            className={` ${
                              matches ? "h-40" : "h-56"
                            } w-auto text-center mt-4 inline rounded`}
                          />
                          <span
                            className={`py-3 w-full ${
                              matches ? "text-base" : "text-xl"
                            } block font-semibold mt-1 `}
                          >
                            National Geographic Education
                            <span
                              className={`text-grey13 font-normal ${
                                matches ? "text-[10px]" : "text-xs"
                              } block mt-1`}
                            >
                              Explore educational opportunities that ignite the
                              spirit of exploration and bring the wonder of the
                              world to learners. National Geographic is where
                              education meets exploration. We are transforming
                              the learning experience for young people and the
                              educators who reach them with the tools,
                              resources, and support they need to feed their
                              curiosity and become the explorers of tomorrow.
                            </span>
                          </span>
                        </a>
                      </li>

                      <li
                        className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                          matches ? "max-w-[185px]" : "max-w-[285px]"
                        } justify-center items-center`}
                      >
                        <a href="https://www.ck12.org/student/" target="_blank">
                          <img
                            src="img/imgt.jpg"
                            className={` ${
                              matches ? "h-40" : "h-56"
                            } w-auto text-center mt-4 inline rounded`}
                          />
                          <span
                            className={`py-3 w-full ${
                              matches ? "text-base" : "text-xl"
                            } block font-semibold mt-1 `}
                          >
                            CK-12 Foundation
                            <span
                              className={`text-grey13 font-normal ${
                                matches ? "text-[10px]" : "text-xs"
                              } block mt-1`}
                            >
                              CK-12 Foundation
                            </span>
                          </span>
                        </a>
                      </li>

                      <li
                        className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                          matches ? "max-w-[185px]" : "max-w-[285px]"
                        } justify-center items-center`}
                      >
                        <a href="https://ocw.mit.edu/" target="_blank">
                          <img
                            src="img/mit.jpg"
                            className={` ${
                              matches ? "h-40" : "h-56"
                            } w-auto text-center mt-4 inline rounded`}
                          />
                          <span
                            className={`py-3 w-full ${
                              matches ? "text-base" : "text-xl"
                            } block font-semibold mt-1 `}
                          >
                            MIT OpenCourseWare (OCW)
                            <span
                              className={`text-grey13 font-normal ${
                                matches ? "text-[10px]" : "text-xs"
                              } block mt-1`}
                            >
                              UNLOCKING KNOWLEDGE, EMPOWERING MINDS.
                            </span>
                          </span>
                        </a>
                      </li>

                      <li
                        className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                          matches ? "max-w-[185px]" : "max-w-[285px]"
                        } justify-center items-center`}
                      >
                        <a
                          href="https://www.pbslearningmedia.org/"
                          target="_blank"
                        >
                          <img
                            src="img/pbs.PNG"
                            className={` ${
                              matches ? "h-40" : "h-56"
                            } w-auto text-center mt-4 inline rounded`}
                          />
                          <span
                            className={`py-3 w-full ${
                              matches ? "text-base" : "text-xl"
                            } block font-semibold mt-1 `}
                          >
                            PBS LearningMedia
                            <span
                              className={`text-grey13 font-normal ${
                                matches ? "text-[10px]" : "text-xs"
                              } block mt-1`}
                            >
                              Bring the World to Your Classroom PBS and your
                              local station have curated FREE,
                              curriculum-aligned videos, interactives, lesson
                              plans, and more just for teachers like you.
                            </span>
                          </span>
                        </a>
                      </li>

                      <li
                        className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                          matches ? "max-w-[185px]" : "max-w-[285px]"
                        } justify-center items-center`}
                      >
                        <a
                          href="https://helpx.adobe.com/learning.html"
                          // href="https://creativecloud.adobe.com/learn/app/photoshop"
                          target="_blank"
                        >
                          <img
                            src="img/photoshop.jpg"
                            className={` ${
                              matches ? "h-40" : "h-56"
                            } w-auto text-center mt-4 inline rounded`}
                          />
                          <span
                            className={`py-3 w-full ${
                              matches ? "text-base" : "text-xl"
                            } block font-semibold mt-1 `}
                          >
                            Creative Cloud
                            <span
                              className={`text-grey13 font-normal ${
                                matches ? "text-[10px]" : "text-xs"
                              } block mt-1`}
                            >
                              Welcome to Creative Cloud Create, collaborate, and
                              share from anywhere.
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>

                    <div className={`text-center ${matches ? "mt-1" : "mt-6"}`}>
                      <button className="" onClick={() => prev()}>
                        <img
                          src="img/leftA.png"
                          className={` ${matches ? "h-8" : "h-12"} w-fit ${
                            activePageIndex === 0 ? "cursor-not-allowed" : ""
                          }`}
                        />
                      </button>

                      <button className="" onClick={() => next()}>
                        <img
                          src="img/rightA.png"
                          className={`${matches ? "h-8" : "h-12"} w-fit ${
                            activePageIndex === pages.length - 1
                              ? "cursor-not-allowed"
                              : ""
                          }`}
                        />
                      </button>
                    </div>
                  </div>

                  <div className="mt-5">
                    <img src="img/stem9.png" />
                  </div>
                </div>
              </div>
            </div>

            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stem;
