import React, { useState, useEffect } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { toastSuccess, toastError } from "../hooks/toaster";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import Spinner from "./spinner";
import axios from "axios";

const ChangePassword = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const matches = UseMediaQuery("(max-width: 992px)");
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : "";
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    current_password: "",
    password: "",
    confirmpassword: "",
  });
  const [changePasswordError, setChangePasswordError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.current_password) {
      errors.current_password = "Current Password is required";
    } else if (formData.current_password.length < 6) {
      errors.current_password =
        "Current Password must be at least 6 characters long";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }

    if (!formData.confirmpassword) {
      errors.confirmpassword = "Confirm Password is required";
    }

    if (formData.password !== formData.confirmpassword) {
      errors.password = "Passwords do not match";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setChangePasswordError("");
    const isValid = validateForm();

    if (isValid) {
      setLoadingChangePassword(true);
      try {
        await axios
          .put(API_URL + "user/reset-password/" + userInfo.user_id, formData, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            setLoadingChangePassword(false);
            toastSuccess("Password changed successfully");
          })
          .catch(function (error) {
            setChangePasswordError(error.response.data.message);
            console.log(error);
            setLoadingChangePassword(false);
          });
      } catch (error) {
        setLoadingChangePassword(false);
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="">
      <div className="bg-white1 ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div
              className={` ${
                matches ? "mx-2 my-2 min-h-screen" : "mx-4 my-4"
              } `}
            >
              <div className="text-xl font-medium">Change Password</div>
              <div className="mt-3 bg-white rounded border border-grey7">
                <div className="p-4 block max-w-sm">
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label className="text-grey3 text-sm">
                        Current Password*
                      </label>
                      <input
                        type="password"
                        className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
                        placeholder="Current Password"
                        name="current_password"
                        value={formData.current_password}
                        onChange={handleChange}
                      />
                      {errors.current_password && (
                        <span className="text-red text-xs">
                          {errors.current_password}
                        </span>
                      )}
                    </div>

                    <div className="mt-2">
                      <label className="text-grey3 text-sm">Password*</label>
                      <input
                        type="password"
                        className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
                        placeholder="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      {errors.password && (
                        <span className="text-red text-xs">
                          {errors.password}
                        </span>
                      )}
                    </div>

                    <div className="mt-2">
                      <label className="text-grey3 text-sm">
                        Confirm Password*
                      </label>
                      <input
                        type="password"
                        className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
                        placeholder="Confirm Password"
                        name="confirmpassword"
                        value={formData.confirmpassword}
                        onChange={handleChange}
                      />
                      {errors.confirmpassword && (
                        <span className="text-red text-xs">
                          {errors.confirmpassword}
                        </span>
                      )}
                    </div>

                    {loadingChangePassword ? (
                      <Spinner /> // Show the spinner while loading
                    ) : (
                      <>
                        {changePasswordError ? (
                          <div className="mt-3 text-red text-center text-xs">
                            {changePasswordError}
                          </div>
                        ) : (
                          ""
                        )}
                        <input
                          type="submit"
                          value="Change Password"
                          name="Change Password"
                          className="font-bold bg-green1 text-white w-full !py-3 px-5 text-base mt-4 rounded shadowEffect hover:opacity-60"
                        />
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
