import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "./spinner";
import { isNumber } from "../hooks/useMediaQuery";
import { toastSuccess, toastError } from "../hooks/toaster";

function AddAddress({ closeModal, closeUpdateModal, primaryDetail }) {
  const token = localStorage.getItem("userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    address_line1: primaryDetail
      ? primaryDetail.address_line1 && primaryDetail.address_line1 !== undefined
        ? primaryDetail.address_line1
        : ""
      : "",
    address_line2: primaryDetail
      ? primaryDetail.address_line2 && primaryDetail.address_line2 !== undefined
        ? primaryDetail.address_line2
        : ""
      : "",
    city: primaryDetail
      ? primaryDetail.city && primaryDetail.city !== undefined
        ? primaryDetail.city
        : ""
      : "",
    state: primaryDetail
      ? primaryDetail.state && primaryDetail.state !== undefined
        ? primaryDetail.state
        : ""
      : "",
    country: primaryDetail
      ? primaryDetail.country && primaryDetail.country !== undefined
        ? primaryDetail.country
        : ""
      : "",
    pin_code: primaryDetail
      ? primaryDetail.pin_code && primaryDetail.pin_code !== undefined
        ? primaryDetail.pin_code
        : ""
      : "",
    phone_number: primaryDetail
      ? primaryDetail.phone_number && primaryDetail.phone_number !== undefined
        ? primaryDetail.phone_number
        : ""
      : "",
  });
  const [errors, setErrors] = useState({});
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const validateForm = () => {
    const errors = {};

    if (!formData.address_line1.trim()) {
      errors.address_line1 = "Address is required";
    }

    if (!formData.pin_code.trim()) {
      errors.pin_code = "Postal code is required";
    }
    if (!formData.city.trim()) {
      errors.city = "City is required";
    }

    if (!formData.state.trim()) {
      errors.state = "State is required";
    }

    if (!formData.country.trim()) {
      errors.country = "Country is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    console.log("in submit fun");
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      formData["user_id"] = userInfo.user_id;
      setLoading(true);
      if (primaryDetail) {
        try {
          await axios
            .put(
              API_URL + "product/shipping-address/" + primaryDetail._id,
              formData,
              {
                headers: { authorization: "Bearer " + token },
              }
            )
            .then(function (response) {
              toastSuccess("Address updated successfully");
              setLoading(false);
              closeUpdateModal();
            })
            .catch(function (error) {
              toastError(error.response.data.message || "Address not updated");
              console.log(error);
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          console.error("Error:", error);
        }
      } else {
        try {
          await axios
            .post(API_URL + "product/shipping-address/", formData, {
              headers: { authorization: "Bearer " + token },
            })
            .then(function (response) {
              toastSuccess("Address added successfully");
              setLoading(false);
              closeUpdateModal();
            })
            .catch(function (error) {
              toastError(error.response.data.message || "Address not updated");
              console.log(error);
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          console.error("Error:", error);
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label className="text-grey3 text-base w-full mt-3">
          Address Line 1
        </label>
        <input
          type="text"
          className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
          placeholder="Professional Salon / 376 Broadway "
          name="address_line1"
          value={formData.address_line1}
          onChange={handleChange}
        />
        {errors.address_line1 && (
          <span className="text-red text-xs">{errors.address_line1}</span>
        )}

        <label className="text-grey3 text-base w-full mt-3">
          Address Line 2
        </label>
        <input
          type="text"
          className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
          placeholder="Ste 21, American Pharoah, Saratoga Springs"
          name="address_line2"
          value={formData.address_line2}
          onChange={handleChange}
        />

        <label className="text-grey3 text-base w-full mt-3">City</label>
        <input
          type="text"
          className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
          placeholder="Chicago"
          name="city"
          value={formData.city}
          onChange={handleChange}
        />
        {errors.city && <span className="text-red text-xs">{errors.city}</span>}

        <label className="text-grey3 text-base w-full mt-3">State</label>
        <input
          type="text"
          className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
          placeholder="New York"
          name="state"
          value={formData.state}
          onChange={handleChange}
        />
        {errors.state && (
          <span className="text-red text-xs">{errors.state}</span>
        )}

        <label className="text-grey3 text-base w-full mt-3">Country</label>
        <input
          type="text"
          className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
          placeholder="United States"
          name="country"
          value={formData.country}
          onChange={handleChange}
        />
        {errors.country && (
          <span className="text-red text-xs">{errors.country}</span>
        )}

        <label className="text-grey3 text-base w-full mt-3">Postal Code</label>
        <input
          type="text"
          className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
          placeholder="12866"
          name="pin_code"
          value={formData.pin_code}
          onChange={handleChange}
        />
        {errors.pin_code && (
          <span className="text-red text-xs">{errors.pin_code}</span>
        )}

        <label className="text-grey3 text-base w-full mt-3">Phone Number</label>
        <input
          type="text"
          className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded"
          placeholder="Enter mobile number"
          name="phone_number"
          value={formData.phone_number}
          onChange={handleChange}
          onKeyDown={(event) => isNumber(event)}
        />
        {errors.phone_number && (
          <span className="text-red text-xs">{errors.phone_number}</span>
        )}

        <div className="float-right mt-4">
          {loading ? (
            <Spinner /> // Show the spinner while loading
          ) : (
            <>
              <button
                type="button"
                onClick={() => closeModal()}
                className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
              >
                Cancel
              </button>
              <input
                type="submit"
                value="Update"
                name="Update"
                className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
              />
            </>
          )}
        </div>
      </form>
    </div>
  );
}

export default AddAddress;
