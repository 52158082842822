import React, { useState, useEffect } from "react";
import Model from "./model";
import axios from "axios";
import DeleteModal from "./deleteModal";
import { toastSuccess, toastError } from "../hooks/toaster";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { Spinner } from "react-bootstrap";
import Menu from "./menu";
import Header from "./header";
import Footer from "./mobileFooter";
import AddMembers from "./addMembers";
import MemberList from "./memberList";
import AddMemberShift from "./addMemberShift";

const Members = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [modalIsOpenShift, setIsOpenModalShift] = useState(false);
  const [modalIsOpenDelete, setIsOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [memberId, setMemberId] = useState();
  const [memberName, setMemberName] = useState();
  const [memberData, setMemberData] = useState();
  const [count, setCount] = useState(0);

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
    setMemberId("");
  };

  const closeModalShift = () => {
    setIsOpenModalShift(false);
    setMemberId("");
  };

  const openModalUpdate = (id) => {
    setMemberId(id);
    setIsOpenModal(true);
  };

  const openModalShift = (id, fname) => {
    setMemberId(id);
    setMemberName(fname);
    setCount(count + 1);
    setIsOpenModalShift(true);
  };

  const openModalDelete = (id) => {
    setDeleteId(id);
    setIsOpenModalDelete(true);
  };

  const closeModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleDelete = async () => {
    setLoadingDelete(true);
    try {
      await axios
        .delete(API_URL + "member/" + deleteId, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          fetchData();
          setLoadingDelete(false);
          toastSuccess("Member deleted successfully");
          setIsOpenModalDelete(false);
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Member not deleted");
          console.log(error);
          setLoadingDelete(false);
        });
    } catch (error) {
      setLoadingDelete(false);
      toastError(error || "Member not deleted");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "member/get-all-member/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setMemberData(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <div className="border-b border-grey9 pb-4">
        <div className="inline-block font-semibold ">Members</div>
        <div className="inline-block float-right">
          <button onClick={() => openModal()}>
            <img src="img/Add2.png" alt="user" />
          </button>
        </div>
      </div>

      <div
        className={`mt-4 ${
          memberData && memberData.length > 2
            ? "overflow-y-scroll max-h-[330px]"
            : ""
        }`}
      >
        {loading ? (
          <Spinner />
        ) : (
          <div>
            {memberData && memberData.length > 0 ? (
              <MemberList
                openModalShift={openModalShift}
                memberData={memberData}
                openModalUpdate={openModalUpdate}
                openModalDelete={openModalDelete}
              />
            ) : (
              <div className="text-center font-bold">
                Members are not added yet
              </div>
            )}
          </div>
        )}
      </div>

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={
          <AddMembers
            closeModal={closeModal}
            memberId={memberId}
            fetchData={fetchData}
          />
        }
        dialogClassName="modal-width-profile"
      />
      <Model
        modalIsOpen={modalIsOpenShift}
        closeModal={closeModalShift}
        content={
          <AddMemberShift
            closeModal={closeModalShift}
            memberId={memberId}
            memberName={memberName}
            fetchData={fetchData}
          />
        }
        dialogClassName="modal-width-profile"
      />

      <Model
        modalIsOpen={modalIsOpenDelete}
        closeModal={closeModalDelete}
        content={
          <DeleteModal
            closeModalDelete={closeModalDelete}
            title="Member"
            handleDelete={handleDelete}
            loading={loadingDelete}
          />
        }
        dialogClassName="modal-width-30"
      />
    </div>
  );
};

export default Members;
