import React, { useState, useEffect } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import OuterMenu from "./outerMenu";
import OuterFooter from "./outerFooter";
import ContactHours from "./contactHours";
import ServiceList from "./servicesList";
import ProfessionalProducts from "./professionalProducts";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Spinner from "./spinner";
import axios from "axios";
import Footer from "./mobileFooter";

function SalonDetail() {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const matches = UseMediaQuery("(max-width: 992px)");
  const [loading, setLoading] = useState(false);
  const [salonData, setSalonData] = useState();
  const [workImages, setWorkImages] = useState();
  const [profileName, setProfileName] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const pathname = window.location.pathname;
    const pathSegments = pathname.split("/");
    const firstNonEmptyWord = pathSegments.find(
      (segment) => segment.trim() !== ""
    );
    setProfileName(firstNonEmptyWord);
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "user/get-salon-details/" + firstNonEmptyWord
        //{ headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setLoading(false);
        setSalonData(response.data);
        if (response.data.professionalWorkingImages) {
          const imgArray = response.data.professionalWorkingImages.map(
            (item) => item.image_urls
          );
          setWorkImages(response.data.professionalWorkingImages);
          //setWorkImages(imgArray);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const imgItems = [];

  const workImagesData = (count) => {
    if (count === 3) {
      for (let i = 0; i < 3; i++) {
        if (workImages[i]) {
          imgItems.push(
            <img
              alt="work"
              onClick={() => setIsOpen(true)}
              src={workImages[i].image_urls}
              className={` ${matches ? "h-[170px]" : "h-[224px]"} `}
            />
          );
        }
      }
    } else {
    }
  };

  return (
    <div className=" mb-5">
      <div className="mt-3 border-b border-grey2 pb-2">
        <div className="pb-2">
          <OuterMenu page="other" cartClr="black" />
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="xl:px-44 lg:px-44 lg3:px-36 lg2:px-36 md:px-28 sm:px-20 xs:px-8 xxs:px-6 mt-10">
          <div className="font-bold xl:text-4xl lg:text-4xl lg3:text-3xl lg2:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-xl">
            {salonData && salonData.user
              ? salonData.user.salon_name
              : "The Choppers"}
          </div>
          {/* <button type="button" onClick={() => setIsOpen(true)}>
            Open Lightboxw
          </button> */}
          {isOpen && workImages && (
            <Lightbox
              mainSrc={workImages[photoIndex].image_urls}
              nextSrc={workImages[(photoIndex + 1) % workImages.length]}
              prevSrc={
                workImages[
                  (photoIndex + workImages.length - 1) % workImages.length
                ]
              }
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + workImages.length - 1) % workImages.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % workImages.length)
              }
              imageCaption={workImages[photoIndex].caption}
            />
          )}

          <div className="mt-3">
            <div className="grid lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-6 xs:grid-cols-4 xxs:grid-cols-4 gap-2">
              <div className="col-span-4">
                <div
                  class={`container11 ${matches ? "h-[165px]" : "h-[460px]"} `}
                >
                  <img
                    src={` ${
                      salonData && salonData.user && salonData.user.cover_pic
                        ? salonData.user.cover_pic
                        : "img/img8.png"
                    }`}
                    alt="Cover Image"
                    class="image11"
                  />
                  <div class="background-color11"></div>
                </div>
                {/* <img
                  alt="user profile"
                  src={` ${
                    salonData && salonData.user && salonData.user.cover_pic
                      ? salonData.user.cover_pic
                      : "img/img8.png"
                  }`}
                  className={`ml-auto mr-auto w-auto ${
                    matches ? "h-[300px]" : "h-[460px]"
                  } `}
                /> */}
                {/* <div class="parent-div">
                  <img
                    src={` ${
                      salonData && salonData.user && salonData.user.cover_pic
                        ? salonData.user.cover_pic
                        : "img/img8.png"
                    }`}
                    alt="Your Image"
                  />
                </div> */}
                {/* <div className=" justify-center flex bg-grey12">
                  <img
                    alt="user profile"
                    src={` ${
                      salonData && salonData.user && salonData.user.cover_pic
                        ? salonData.user.cover_pic
                        : "img/img8.png"
                    }`}
                    className={` w-auto ${
                      matches ? "h-[300px]" : "h-[460px]"
                    } `}
                  />
                </div> */}
              </div>
              <div className={` ${matches ? "col-span-4" : "col-span-2"}`}>
                <div className="grid grid-cols-2 gap-2">
                  {workImages ? (
                    <>
                      {workImagesData(3)}
                      {imgItems}
                      {workImages[3] ? (
                        <div
                          className="relative"
                          onClick={() => setIsOpen(true)}
                        >
                          <img
                            alt="work"
                            src={
                              workImages[4]
                                ? workImages[4].image_urls
                                : "img/img12.png"
                            }
                            className={` blur-[2px] ${
                              matches ? "h-[170px]" : "h-[224px]"
                            } `}
                          />
                          <span className="absolute imgCenter text-black0 font-bold text-xl">
                            {workImages.length - 4} +
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3 block border-b border-grey2 pb-4">
            <div className="inline-block text-xl font-bold">
              <a
                href={`https://www.google.com/maps/place/${
                  salonData && salonData.user
                    ? salonData.user.address_line1
                      ? salonData.user.address_line1 +
                        " ," +
                        salonData.user.address_line2 +
                        " ," +
                        salonData.user.pincode
                      : ""
                    : ""
                }`}
                target="_blank"
              >
                {salonData && salonData.user.description
                  ? salonData.user.description
                  : ""}
                <span className="text-grey3 text-lg font-normal block">
                  {salonData && salonData.user
                    ? salonData.user.address_line1
                      ? salonData.user.address_line1 + ", "
                      : ""
                    : ""}
                  {salonData && salonData.user
                    ? salonData.user.address_line2
                      ? salonData.user.address_line2 + ", "
                      : ""
                    : ""}
                  {salonData && salonData.user
                    ? salonData.user.pincode
                      ? salonData.user.pincode
                      : ""
                    : ""}
                </span>
              </a>
            </div>
            <div className="inline-block float-right">
              <img
                alt="rating"
                src="img/rating.png"
                className="inline-block align-text-top"
              />
              <span className="ml-2 inline-block lg:text-base md:text-base sm:text-base xs:text-sm xxs:text-xs">
                {salonData && salonData.avgRating
                  ? salonData.avgRating + " (" + salonData.totalRatings + ")"
                  : 0}
              </span>
            </div>
          </div>

          <div className="">
            <div className="grid lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-6 xs:grid-cols-4 xxs:grid-cols-4 gap-2 mt-5">
              <div className="col-span-4 ">
                <div className="text-xl font-bold">Services</div>
                {salonData && salonData.service ? (
                  <ServiceList
                    SalonDetail={salonData}
                    salonId={salonData.user._id}
                    services={salonData.service}
                    page="salonDetail"
                    profileName={profileName}
                  />
                ) : (
                  ""
                )}
                {salonData &&
                salonData.product &&
                salonData.product.length > 0 ? (
                  <div className="mt-10">
                    <div className="text-xl font-bold">Products</div>

                    <ProfessionalProducts
                      page="forEnthu"
                      userId={salonData.user._id}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={` ${matches ? "col-span-4" : "col-span-2"}`}>
                <ContactHours page="enthu" SalonDetail={salonData} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-5">
        <OuterFooter />
      </div>
      {matches ? <Footer /> : ""}
    </div>
  );
}

export default SalonDetail;
