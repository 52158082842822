import React, { useState, useEffect } from "react";
import Menu from "./menu";
import Header from "./header";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Footer from "./mobileFooter";
import TutorialProff from "./tutorialProff";
import TutorialEnthu from "./tutorialEnthu";

const Tutorial = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div>
      <div className="bg-white1 ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div className={` ${matches ? "mx-2 my-2" : "mx-4 my-4"} `}>
              <div className="bg-white">
                <div className="p-3">
                  <div className="">
                    <img
                      alt="tutorial"
                      src="img/logo.png"
                      className="mx-auto"
                    />
                  </div>
                  <div className="mt-3 text-lg font-bold text-center">
                    <span className="text-green1">Welcome to Pelito !. </span>
                    <span className="text-sm font-normal">
                      We are happy to guide you to use Pelito.
                    </span>
                  </div>
                  {userInfo && userInfo.user_type === "professional" ? (
                    <TutorialProff />
                  ) : (
                    <TutorialEnthu />
                  )}
                </div>
              </div>
            </div>
            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
