import React from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

function Model({ modalIsOpen, closeModal, content, dialogClassName }) {
  return (
    <Modal
      show={modalIsOpen}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={dialogClassName}
    >
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  );
}

export default Model;
