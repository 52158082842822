import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import axios from "axios";
import Spinner from "./spinner";
import BookServiceTime from "./bookServiceTime.js";
import OuterMenu from "./outerMenu.js";

const BookService = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const matches = UseMediaQuery("(max-width: 992px)");
  const location = useLocation();
  const { data } = location.state || {};
  const [memberData, setMemberData] = useState();
  const [loading, setLoading] = useState();
  const [member, setMember] = useState();
  const [memberName, setMemberName] = useState();

  useEffect(() => {
    if (data.salonId) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "member/get-all-member/" + data.salonId,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setMemberData(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleProfessional = (id, name) => {
    setMember(id);
    setMemberName(name);
  };

  const handleAnotherProfessional = () => {
    setMember("");
    setMemberName("");
  };

  return (
    <div className={` ${userInfo ? "bg-white1" : ""} `}>
      <div className={`${userInfo ? "flex" : ""}`}>
        {/* if enthusiast not logged in then only outer menu  */}
        {!userInfo ? (
          <div className="mt-3 border-b border-grey2 pb-2">
            <div className="pb-2">
              <OuterMenu page="other" cartClr="black" />
            </div>
          </div>
        ) : (
          ""
        )}
        {userInfo ? <Menu /> : ""}
        <div
          className={` ${
            userInfo
              ? "w-full"
              : "xl:px-44 lg:px-44 lg3:px-36 lg2:px-36 md:px-28 sm:px-20 xs:px-8 xxs:px-6 mt-10"
          } `}
        >
          {userInfo ? <Header /> : ""}
          <div className="mx-4 my-4 min-h-screen">
            <div
              className={`bg-white rounded border border-grey7 ${
                matches ? "p-2" : "p-4"
              }`}
            >
              {!member ? (
                <div>
                  <div className="my-4 font-bold text-lg">
                    Select Professional
                  </div>
                  <div className="">
                    {memberData && memberData.length > 0 ? (
                      <div className="grid grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 xs:grid-cols-2 xxs:grid-cols-2 lg:gap-5 md:gap-5 sm:gap-5 md:gap-5 xs: gap-2 xxs:gap-2">
                        {memberData.map((members) => {
                          let member = members.item;
                          let rating = members.rating;
                          return (
                            <div
                              onClick={() =>
                                handleProfessional(
                                  member._id,
                                  member.first_name + " " + member.last_name
                                )
                              }
                              className={`text-center border border-grey7 cursor-pointer hover:!border-[#5C4ACE] rounded px-2 py-12`}
                            >
                              <div className="relative">
                                <div className="inline-block image-container-50">
                                  <img
                                    className="rounded-full inline relative"
                                    src={
                                      member.profile_picture
                                        ? member.profile_picture
                                        : "img/default.jpg"
                                    }
                                  />
                                  <div className="font-bold bg-white ml-[2%] bottom-[-5%] absolute px-2 py-0.5 border 1border-black rounded-3xl text-[11px] 1text-grey3">
                                    {rating ? rating.avgRating : 0}
                                    <img
                                      src="img/star.png"
                                      className="ml-0.5 inline !h-[10px] !w-[10px]"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mt-2">
                                {member.first_name + " " + member.last_name}
                              </div>
                              <div className="text-sm text-grey3">
                                {member.job_title}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="text-center mt-3">
                        No Professionals are there at moment..
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <BookServiceTime
                  serviceData={data}
                  memberId={member}
                  memberName={memberName}
                  handleAnotherProfessional={handleAnotherProfessional}
                />
              )}
            </div>
          </div>
          {matches ? <Footer /> : ""}
        </div>
      </div>
    </div>
  );
};

export default BookService;
