import React, { useState } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Spinner from "./spinner";
import DeleteModal from "./deleteModal";
import Model from "./model";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";
import Likes from "./likes";
import Comments from "./comments";

function Post({ postData, loading, pageName = "", fetchDataFun }) {
  const matches = UseMediaQuery("(max-width: 992px)");
  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [modalIsOpenComment, setIsOpenModalComment] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [dropdown, setDropdown] = useState(false);
  const [dropdownId, setDropdownId] = useState();
  const [modalIsOpenDelete, setIsOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [editId, setEditId] = useState();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [text, setText] = useState("");
  const [textareaHeight, setTextAreaHeight] = useState("auto");
  const [loadingSub, setLoadingSub] = useState(false);
  const [likeId, setLikeId] = useState();
  const [loadingLike, setLoadingLike] = useState(false);
  const [loadingComment, setLoadingComment] = useState(false);
  const [commentId, setCommentID] = useState();
  const [commentData, setCommentData] = useState();
  const [likesData, setLikesData] = useState();
  const [commentDataModal, setCommentDataModal] = useState();
  const [count, setCount] = useState(0);

  const openModal = (likes) => {
    if (likes.length > 0) {
      setLikesData(likes);
      setIsOpenModal(true);
    }
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const openModalComment = (Comment) => {
    if (Comment.comments.length > 0) {
      setCommentDataModal(Comment);
      setIsOpenModalComment(true);
    }
  };

  const closeModalComment = () => {
    setIsOpenModalComment(false);
  };

  const handleDropdown = (id) => {
    setDropdownId(id);
    setDropdown(!dropdown);
  };

  const handleEdit = (id) => {
    setEditId(id);
    setDropdown(false);
    setCount(count + 1);
  };

  const openModalDelete = (id) => {
    setDeleteId(id);
    setIsOpenModalDelete(true);
  };

  const closeModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleDelete = async () => {
    setLoadingDelete(true);
    try {
      await axios
        .delete(API_URL + "post/professional-post/" + deleteId, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          fetchDataFun("deletePost");
          setLoadingDelete(false);
          toastSuccess("Post deleted successfully");
          setIsOpenModalDelete(false);
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Post not deleted");
          console.log(error);
          setLoadingDelete(false);
        });
    } catch (error) {
      setLoadingDelete(false);
      toastError(error || "Post not deleted");
      console.error("Error:", error);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    const words = value.trim().split(/\s+/);
    if (words.length <= 200) {
      setText(value);

      // Calculate the height of the textarea based on its content
      const lineHeight = 18; // Adjust this value according to your font size
      const rows = value.split("\n").length;
      setTextAreaHeight(`${lineHeight * rows}px`);
    }
  };

  const handleCancel = () => {
    setEditId("");
  };

  const handleSubmit = async () => {
    if (text) {
      setLoadingSub(true);
      setEditId("");
      let formData = {};
      formData["caption"] = text;
      try {
        await axios
          .put(API_URL + "post/professional-post/" + editId, formData, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            setLoadingSub(false);
            toastSuccess("Post updated successfully");
            setText("");
            setTextAreaHeight("auto");
            fetchDataFun();
          })
          .catch(function (error) {
            toastError(error.response.data.message || "Post not updated");
            console.log(error);
            setLoadingSub(false);
          });
      } catch (error) {
        setLoadingSub(false);
        toastError(error || "Post not updated");
        console.error("Error:", error);
      }
    }
  };

  const handleComment = async (id) => {
    setLoadingComment(true);
    setCommentID(id);
    try {
      let formData = {};
      formData["user_id"] = userInfo.user_id;
      formData["post_id"] = id;
      formData["comment_text"] = commentData[id];
      await axios
        .post(API_URL + "post/add-comment", formData, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          setIsOpenModalDelete(false);
          setCommentID("");
          const newData = { ...commentData };
          newData[id] = "";
          setCommentData(newData);
          toastSuccess("Comment added successfully");
        })
        .catch(function (error) {
          console.log(error);
          setCommentID("");
          const newData = { ...commentData };
          newData[id] = "";
          setCommentData(newData);
          toastError("Comment not added");
        });
    } catch (error) {
      setLoadingDelete(false);
      setCommentID("");
      console.error("Error:", error);
      const newData = { ...commentData };
      newData[id] = "";
      setCommentData(newData);
    }
  };

  const handleLike = async (id, like) => {
    setLikeId(id);
    setLoadingLike(true);
    const index = like.findIndex(
      (user) => user.user_id._id === userInfo.user_id
    );
    try {
      let formData = {};
      formData["user_id"] = userInfo.user_id;
      formData["post_id"] = id;
      if (index >= 0) {
        await axios
          .delete(
            API_URL + "post/delete-likes/" + id + "/" + like[index]._id,
            formData,
            { headers: { authorization: "Bearer " + token } }
          )
          .then(function (response) {
            setLoadingLike(false);
            fetchDataFun("postLike", id);
          });
      } else {
        await axios
          .post(API_URL + "post/add-likes", formData, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            setLoadingLike(false);
            fetchDataFun("postLike", id);
          });
      }
    } catch (error) {
      setLoadingLike(false);
      setLoadingDelete(false);
      console.error("Error:", error);
    }
  };

  const changeComment = (e, id) => {
    const newData = { ...commentData };
    newData[id] = e;
    setCommentData(newData);
  };

  const checkLikes = (likes) => {
    if (likes) {
      const index = likes.findIndex(
        (user) => user.user_id._id === userInfo.user_id
      );
      if (index >= 0) {
        return "img/heartR.png";
      } else {
        return "img/heartG.svg";
      }
    } else {
      return "img/heartG.svg";
    }
  };

  const dateTime = (date) => {
    const givenDate = new Date(date); // Your given date
    const currentDate = new Date(); // Current date

    const difference = currentDate.getTime() - givenDate.getTime(); // Difference in milliseconds
    const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    const hoursDifference = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    ); // Convert milliseconds to hours
    let data = "";
    if (daysDifference > 0) {
      data = daysDifference + "d ago";
    } else {
      if (hoursDifference >= 1) {
        data = hoursDifference + "h ago";
      } else {
        data = "just now";
      }
    }
    return data;
  };

  return (
    <>
      {postData && postData.length > 0 ? (
        postData.map((post) => {
          return (
            <>
              {loading ? <Spinner /> : ""}
              <div className="mt-4 bg-white rounded border border-grey7">
                <div className="p-4">
                  <div className="">
                    <div className="block">
                      <div className="inline-flex">
                        <div className="inline image-container-40">
                          <img
                            className="rounded-full  inline"
                            alt="user"
                            src={` ${
                              post && post.user_id.profile_picture
                                ? post.user_id.profile_picture
                                : "img/default.jpg"
                            }`}
                          />
                        </div>
                        <div className="inline ml-2.5">
                          <div className="text-black2 text-base font-medium test">
                            {pageName === "mypost"
                              ? userInfo.first_Name + " " + userInfo.last_Name
                              : post.user_id.first_name +
                                " " +
                                post.user_id.last_name}
                          </div>
                          <div className="text-grey3 font-normal text-xs mt-1">
                            {post.created ? dateTime(post.created) : ""}
                          </div>
                        </div>
                      </div>
                      {pageName === "mypost" ? (
                        <div className="inline inline float-right">
                          <div className="relative ">
                            <div
                              className=" cursor-pointer"
                              onClick={() => {
                                handleDropdown(post._id);
                              }}
                            >
                              <img src="img/dots1.png" alt="option" />
                            </div>
                            {dropdown && dropdownId === post._id ? (
                              <div
                                key={post._id}
                                className="absolute w-[100px]  right-0 z-10  mt-2 border border-1 border-light-gray origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                <div className="py-2 2xl:py-3" role="none">
                                  <a
                                    onClick={(id) => handleEdit(post._id)}
                                    href="#"
                                    className=" block w-full px-3 2xl:px-5 py-1 text-left text-sm 2xl:text-md font-medium hover:bg-green1 hover:text-[#fff]"
                                  >
                                    Edit
                                  </a>
                                  <a
                                    onClick={(id) => openModalDelete(post._id)}
                                    href="#"
                                    className=" block w-full px-3 2xl:px-5 py-1 text-left text-sm 2xl:text-md font-medium hover:bg-green1 hover:text-[#fff]"
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mt-3 text-grey3">
                      {/* {editId && dropdown && dropdownId === post._id ? ( */}
                      {editId && dropdownId === post._id ? (
                        <div>
                          <textarea
                            style={{
                              height: textareaHeight,
                              minHeight: "65px",
                            }}
                            value={text ? text : post.caption}
                            onChange={handleChange}
                            className="w-full ml-2 p-2 inline bg-white1 rounded"
                            placeholder="What's on your mind"
                          />
                          <div className="inline-block float-right">
                            <button
                              onClick={() => handleCancel()}
                              type="button"
                              className="bg-grey2 text-white shadowEffect hover:opacity-60 mr-2 px-2 py-1 rounded text-lg lg:text-lg md:text-lg sm:text-lg xxs:text-sm"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => handleSubmit()}
                              type="button"
                              className="mt-2 bg-black2 text-white px-2 py-1 rounded text-lg lg:text-lg md:text-lg sm:text-lg xxs:text-sm shadowEffect hover:opacity-60"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {post.caption ? (
                            <div
                              className="mb-3"
                              style={{
                                whiteSpace: "pre-line",
                                wordBreak: "break-all",
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: post.caption,
                                }}
                              />{" "}
                              {/* {post.caption}{" "} */}
                            </div>
                          ) : (
                            ""
                          )}
                          {post.image_url ? (
                            <div className="mb-3">
                              {post.image_url.map((img) => {
                                return (
                                  <img
                                    alt="post"
                                    className="inline-block mr-2 h-[115px] w-[115px]"
                                    src={img}
                                  />
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {post.video_url ? (
                            <div className="mb-3">
                              {post.video_url.map((vid) => {
                                return (
                                  <div className="inline-block mr-2">
                                    <video controls width="200" height="150">
                                      <source src={vid} type="video/mp4" />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                    <div className="text-grey3 lg:text-sm md:text-sm sm:text-sm xs:text-xs xxs:text-xs">
                      <div className="mt-3">
                        {loadingLike && likeId === post._id ? (
                          <Spinner customClass="spinnerLike" />
                        ) : (
                          <button
                            type="button"
                            onClick={(id) => handleLike(post._id, post.likes)}
                          >
                            <img
                              alt="user"
                              src={
                                post.likes_count > 0
                                  ? checkLikes(post.likes)
                                  : "img/heartG.svg"
                              }
                              className="inline"
                            />
                          </button>
                        )}
                        <span className="ml-2 inline">
                          <button onClick={() => openModal(post.likes)}>
                            {post.likes_count}{" "}
                            {post.likes_count > 1 ? "Likes" : "Like"}
                          </button>
                        </span>

                        <img
                          alt="user"
                          src="img/comment.png"
                          className="inline ml-3"
                        />
                        <span className="ml-2 inline">
                          <button onClick={() => openModalComment(post)}>
                            {post.comments_count}{" "}
                            {post.comments_count > 1 ? "Comments" : "Comment"}
                          </button>
                        </span>
                      </div>
                    </div>

                    <div className="border-b border-grey8 mt-3"></div>

                    <div className="flex mt-3 ">
                      <div
                        className={` ${
                          matches ? "image-container-58" : "image-container-44"
                        }`}
                      >
                        <img
                          alt="user_profile"
                          src={` ${
                            userInfo && userInfo.profile_picture
                              ? userInfo.profile_picture
                              : "img/default.jpg"
                          }`}
                          className="rounded-full inline"
                        />
                      </div>
                      <input
                        type="text"
                        value={
                          commentData && commentData[post._id]
                            ? commentData[post._id]
                            : ""
                        }
                        className="w-full ml-2 p-2 inline rounded"
                        placeholder="Write your comment"
                        onChange={(e, id) =>
                          changeComment(e.target.value, post._id)
                        }
                      />
                      {loadingComment && commentId === post._id ? (
                        <div className="mt-[-40px]">
                          <Spinner />
                        </div>
                      ) : (
                        <button
                          onClick={(id) => handleComment(post._id)}
                          className="text-black ml-2 font-bold"
                        >
                          Post
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <div className="text-center font-bold p-4 ">No post added yet</div>
      )}

      <Model
        modalIsOpen={modalIsOpenDelete}
        closeModal={closeModalDelete}
        content={
          <DeleteModal
            closeModalDelete={closeModalDelete}
            title="Post"
            handleDelete={handleDelete}
            loading={loadingDelete}
          />
        }
        dialogClassName="modal-width-30"
      />

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={<Likes likesData={likesData} />}
        dialogClassName="modal-width-20"
      />

      <Model
        modalIsOpen={modalIsOpenComment}
        closeModal={closeModalComment}
        content={<Comments commentData={commentDataModal} />}
        dialogClassName="modal-width-30"
      />
    </>
  );
}

export default Post;
