import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import RatingsProfessional from "./ratingsProfessional";
import TopSellingService from "./topSellingService";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import ProfessionalCreatePost from "./professionalCreatePost";
import Post from "./post";

function ProfessionalDashboard() {
  const matches = UseMediaQuery("(max-width: 992px)");
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const [page, setPage] = useState(1);
  const [postData, setPostData] = useState();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const fetchData = async (option = "", post_id = "") => {
    setLoading(true);
    let pageNum =
      option === "create" ? 1 : option === "postLike" ? page - 1 : page;
    if (option === "postLike") {
      try {
        const response = await axios.get(
          API_URL + "post/professional-post/" + post_id,
          { headers: { authorization: "Bearer " + token } }
        );
        if (response) {
          // if post id exist in the post data then update the like and comment count and array
          const index = postData.findIndex((user) => user._id === post_id);
          if (index >= 0) {
            postData[index].likes_count = response.data.post.likes_count;
            postData[index].comments_count = response.data.post.comments_count;
            postData[index].likes = response.data.likes;
            postData[index].comments = response.data.comments;
          }
          setCount(count + 1);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    } else {
      try {
        const response = await axios.get(
          API_URL + "post/get-all-professional-post?limit=5&page=" + pageNum,
          { headers: { authorization: "Bearer " + token } }
        );
        if (response) {
          setCount(count + 1);
          if (postData && option !== "create") {
            setPostData((prevData) => [...prevData, ...response.data]);
          } else {
            setPostData(response.data);
          }
          setPage(page + 1);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;

    if (scrollTop + clientHeight >= scrollHeight - 20 && !loading) {
      fetchData(); // Load more data when scrolled to the bottom (with a 20px buffer)
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div>
      <div className="bg-white1 ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div
              className={` ${matches ? "mx-2 my-2 min-h-screen" : "mx-4 my-4"}`}
            >
              <div className="grid grid-cols-6 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-4 xs:grid-cols-4 xxs:grid-cols-4 gap-3">
                <div className={` ${matches ? "col-span-6" : "col-span-4"} `}>
                  <ProfessionalCreatePost fetchData={fetchData} />
                  <Post
                    postData={postData}
                    loading={loading}
                    fetchDataFun={(option, id) => fetchData(option, id)}
                  />
                </div>
                {!matches ? (
                  <div className="col-span-2">
                    <>
                      <RatingsProfessional />
                      <TopSellingService />
                    </>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfessionalDashboard;
