import React, { useState, useEffect } from "react";
import { getDateFormate } from "../hooks/commonFunctions";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import axios from "axios";
import Spinner from "./spinner";
import SendEmailToCustomer from "./sendEmailToCustomer";
import Model from "./model";
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

const AppointmentList = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const token = localStorage.getItem("userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [eventData, setEventData] = useState();
  const [eventDataMain, setEventDataMain] = useState();
  const [loading, setLoading] = useState(false);
  const [memberData, setMemberData] = useState();
  const [memberSelected, setMemberSelected] = useState(0);
  const [customerData, setCustomerData] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [checkboxData, setCheckboxData] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [emailErr, setEmailErr] = useState();
  const [modalIsOpen, setIsOpenModal] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "member/get-all-member/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setMemberData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      const response = await axios.get(
        API_URL + "service/get-report-salon/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        const idCheckboxMap = response.data.reduce((acc, booking) => {
          const { _id } = booking;
          acc[_id] = false;
          return acc;
        }, {});

        setCheckboxData(idCheckboxMap);

        const uniquePairs = {};

        // Loop through the bookings array
        response.data.forEach((booking) => {
          const { customer_user_id } = booking;

          // Check if the customer_user_id._id is not already present in uniquePairs
          if (!uniquePairs[customer_user_id._id]) {
            // Add the pair to uniquePairs
            uniquePairs[customer_user_id._id] =
              customer_user_id.first_name + " " + customer_user_id.last_name;
          }
        });

        // Convert the object to an array of objects with id and name properties
        const uniquePairsArray = Object.entries(uniquePairs).map(
          ([id, name]) => ({
            id,
            name,
          })
        );
        setCustomerData(uniquePairsArray);
        setEventDataMain(response.data);
        setEventData(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleChange = (e) => {
    setMemberSelected(e.target.value);
  };

  const handleChangeCustomer = (e) => {
    setSelectedCustomer(e.target.value);
  };

  const handleReset = () => {
    setEventData(eventDataMain);
    setEndDate("");
    setStartDate("");
    setMemberSelected("");
  };

  const handleFilter = () => {
    let filteredData = "";
    // if member, start date and end date selected
    if (memberSelected && memberSelected != 0 && startDate && endDate) {
      filteredData = eventDataMain.filter((item) => {
        const itemDate = new Date(item.day);
        return (
          item.member_id._id
            .toLowerCase()
            .includes(memberSelected.toLowerCase()) &&
          itemDate >= startDate &&
          itemDate < endDate
        );
      });
    }
    //if member and customer selected
    else if (
      memberSelected &&
      memberSelected != 0 &&
      selectedCustomer &&
      selectedCustomer != 0
    ) {
      filteredData = eventDataMain.filter((item) => {
        const itemDate = new Date(item.day);
        return (
          item.member_id._id
            .toLowerCase()
            .includes(memberSelected.toLowerCase()) &&
          item.customer_user_id._id
            .toLowerCase()
            .includes(selectedCustomer.toLowerCase())
        );
      });
    }
    //if only member selected
    else if (memberSelected && memberSelected != 0) {
      filteredData = eventDataMain.filter((item) =>
        item.member_id._id.toLowerCase().includes(memberSelected.toLowerCase())
      );
    }
    //if only dates selected
    else if (startDate && endDate) {
      filteredData = eventDataMain.filter((item) => {
        const itemDate = new Date(item.day);
        return itemDate >= startDate && itemDate < endDate;
      });
    }
    //if only customer selected
    else if (selectedCustomer && selectedCustomer != 0) {
      filteredData = eventDataMain.filter((item) =>
        item.customer_user_id._id
          .toLowerCase()
          .includes(selectedCustomer.toLowerCase())
      );
    } else {
      filteredData = eventDataMain;
    }
    setEventData(filteredData);
  };

  const handleCheckboxChange = (e, type) => {
    if (type === "all") {
      const updatedMap = {};
      for (const key in checkboxData) {
        updatedMap[key] = !selectAll;
      }
      setCheckboxData(updatedMap);
      setSelectAll(!selectAll);
    } else {
      setCheckboxData((prevIdCheckboxMap) => ({
        ...prevIdCheckboxMap,
        [e]: !checkboxData[e], // Set the value to true for the specified id
      }));
    }
  };

  const handleEmail = () => {
    if (eventData && eventData.length > 0) {
      const hasTrueValue = Object.values(checkboxData).some(
        (value) => value === true
      );
      if (hasTrueValue) {
        let emailArr = [];
        let uniqueEmailArr = [];
        for (const key in checkboxData) {
          if (checkboxData[key] === true) {
            const index = eventData.findIndex((user) => user._id === key);
            if (index >= 0) {
              if (
                eventData[index].customer_user_id &&
                eventData[index].customer_user_id.email
              ) {
                emailArr.push(eventData[index].customer_user_id.email);
              }
            }
          }
        }
        if (emailArr.length > 0) {
          uniqueEmailArr = Array.from(new Set(emailArr));
        }
        setEmailErr("");
        openModal();
      } else {
        setEmailErr("please select any customer");
      }
    }
  };

  const handleDownload = () => {
    if (eventData && eventData.length > 0) {
      const hasTrueValue = Object.values(checkboxData).some(
        (value) => value === true
      );
      if (hasTrueValue) {
        let dataArr = [];
        let indicesToInclude = [];
        for (const key in checkboxData) {
          if (checkboxData[key] === true) {
            const index = eventData.findIndex((user) => user._id === key);
            indicesToInclude.push(index);
          }
        }
        const newArray = eventData.filter((item, index) =>
          indicesToInclude.includes(index)
        );
        const finalArr = newArray.map(
          ({
            member_id,
            customer_user_id,
            customer_email,
            customer_phone,
            service_id,
            booking_time,
            booking_period,
            price,
            professional_service_fee,
            day,
          }) => ({
            MemberName: member_id.first_name + " " + member_id.last_name,
            CustomerName:
              customer_user_id.first_name + " " + customer_user_id.last_name,
            CustomerEmail: customer_user_id.email,
            CustomerPhoneNumber: customer_user_id.phone_number,
            ServiceName: service_id.service_name,
            booking_time,
            bookingPeriod: service_id.period + " min",
            Price: service_id.price,
            professional_service_fee,
            Date: getDateFormate(day),
          })
        );
        const csvData = convertToCSV(finalArr);
        downloadCSV(csvData);
      } else {
        setEmailErr("please select any data");
      }
    }
  };

  const downloadCSV = (csvData) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", "data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((obj) => Object.values(obj).join(","));
    return header + "\n" + rows.join("\n");
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeUpdateModal = () => {
    setIsOpenModal(false);
    fetchData();
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <div className="">
      <div className="bg-white1 ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div
              className={` ${
                matches ? "mx-2 my-2 min-h-screen" : "mx-4 my-4"
              } `}
            >
              <div className="">
                <div className="font-bold ">Appointment Report</div>
                <div className="mt-1">
                  {/* <div className="inline-block">
                    <label className="font-bold">Filters : </label>
                  </div> */}
                  <div className="inline-block mt-1">
                    <label className="ml-2">Member : </label>
                    <select
                      name="member"
                      className="inline-block ml-2 p-2 border border-grey-9 rounded"
                      value={memberSelected}
                      onChange={handleChange}
                    >
                      <option value="0">All</option>
                      {memberData &&
                        memberData.length > 0 &&
                        memberData.map((members) => {
                          let member = members.item;
                          return (
                            <option key={member._id} value={member._id}>
                              {member.first_name + " " + member.last_name}
                            </option>
                          );
                        })}
                    </select>
                    <label className="ml-2">Customer : </label>
                    <select
                      name="customer"
                      className="inline-block ml-2 p-2 border border-grey-9 rounded"
                      value={selectedCustomer}
                      onChange={handleChangeCustomer}
                    >
                      <option value="0">All</option>
                      {customerData &&
                        customerData.length > 0 &&
                        customerData.map((customer) => {
                          return (
                            <option key={customer.id} value={customer.id}>
                              {customer.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {/* <label className="ml-2">Date : </label>
                  <select
                    onChange={filterByDate}
                    value={dateSelected}
                    className="inline-block ml-2 p-2 border border-grey-9 rounded"
                  >
                    <option value="all">All</option>
                    <option value="today">Today</option>
                    <option value="tomorrow">Tomorrow</option>
                    <option value="custom">Custom Date</option>
                  </select> */}

                  <div className="inline-block  mt-1">
                    {/* <label className="ml-2">Start Date:</label> */}
                    {/* <DatePicker
                      className="ml-2"
                      selected={startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="MMMM d, yyyy"
                    /> */}
                  </div>
                  <div className="inline-block  mt-1">
                    {/* <label className="ml-3">End Date:</label> */}
                    {/* <DatePicker
                      className="ml-2"
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="MMMM d, yyyy"
                    /> */}
                  </div>
                  <div className="inline-block  mt-1">
                    <button
                      onClick={handleFilter}
                      className="bg-green1 text-white ml-2 border border-grey-9 rounded font-bold px-3 py-2"
                    >
                      Apply
                    </button>

                    <button
                      onClick={handleReset}
                      className="bg-white ml-2 border border-grey-9 rounded font-bold px-3 py-2"
                    >
                      Reset
                    </button>
                  </div>

                  <div className="inline-block float-right">
                    <button
                      onClick={handleEmail}
                      className="p-2 border border-grey9 rounded bg-white"
                    >
                      Send Email
                    </button>
                  </div>

                  <div className="inline-block float-right">
                    <button
                      onClick={() => handleDownload()}
                      className="mr-1 p-2 border border-grey9 rounded bg-white"
                    >
                      Download
                    </button>
                  </div>
                </div>
                {emailErr ? (
                  <div className="inline-block w-full">
                    <div className="text-red float-right mt-2">{emailErr} </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div class="bg-white mt-3 mb-3 rounded flex flex-col overflow-x-auto">
                <div class="sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div class="overflow-x-auto">
                      <table class="min-w-full text-left text-sm font-light">
                        <thead class="border-b font-medium dark:border-neutral-500">
                          <tr>
                            <th scope="col" class="px-6 py-4">
                              <input
                                name="selectAll"
                                type="checkbox"
                                id="selectAll"
                                //checked={selectAll}
                                onChange={() => handleCheckboxChange("", "all")}
                              />
                            </th>
                            <th scope="col" class="px-6 py-4">
                              Date
                            </th>
                            <th scope="col" class="px-6 py-4">
                              Member Name
                            </th>
                            <th scope="col" class="px-6 py-4">
                              Customer Name
                            </th>
                            <th scope="col" class="px-6 py-4">
                              Service Name
                            </th>
                            <th scope="col" class="px-6 py-4">
                              Time
                            </th>
                            <th scope="col" class="px-6 py-4">
                              Period
                            </th>
                            <th scope="col" class="px-6 py-4">
                              Price
                            </th>
                            <th scope="col" class="px-6 py-4">
                              Network Fee
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading ? (
                            <>
                              {eventData && eventData.length > 0 ? (
                                <>
                                  {eventData &&
                                    eventData.map((appointment) => {
                                      return (
                                        <tr class="border-b dark:border-neutral-500">
                                          <td class="whitespace-nowrap px-6 py-2">
                                            <input
                                              name="selectOne"
                                              type="checkbox"
                                              checked={
                                                checkboxData[appointment._id]
                                              }
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  appointment._id
                                                )
                                              }
                                            />
                                          </td>
                                          <td class="whitespace-nowrap px-6 py-2 font-medium">
                                            {getDateFormate(appointment.day)}
                                          </td>
                                          <td class="whitespace-nowrap px-6 py-2">
                                            {appointment.member_id.first_name +
                                              " " +
                                              appointment.member_id.last_name}
                                          </td>
                                          <td class="whitespace-nowrap px-6 py-2">
                                            {
                                              appointment.customer_user_id
                                                .first_name
                                            }
                                            {appointment.customer_user_id
                                              .last_name
                                              ? " " +
                                                appointment.customer_user_id
                                                  .last_name
                                              : ""}
                                            <br />
                                            {appointment.customer_user_id ? (
                                              appointment.customer_user_id
                                                .email === "not exist" ? (
                                                ""
                                              ) : (
                                                <>
                                                  {
                                                    appointment.customer_user_id
                                                      .email
                                                  }{" "}
                                                  <br />
                                                </>
                                              )
                                            ) : (
                                              ""
                                            )}

                                            {
                                              appointment.customer_user_id
                                                .phone_number
                                            }
                                          </td>
                                          <td class="whitespace-nowrap px-6 py-2">
                                            {
                                              appointment.service_id
                                                .service_name
                                            }
                                          </td>
                                          <td class="whitespace-nowrap px-6 py-2">
                                            {appointment.booking_time}
                                          </td>
                                          <td class="whitespace-nowrap px-6 py-2">
                                            {appointment.service_id.period}
                                          </td>
                                          <td class="whitespace-nowrap px-6 py-2">
                                            ${appointment.orignal_price}
                                          </td>
                                          <td class="whitespace-nowrap px-6 py-2">
                                            ${appointment.tax}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </>
                              ) : (
                                <tr className="">
                                  <td colSpan="9" className="text-center p-2">
                                    No data available
                                  </td>
                                </tr>
                              )}
                            </>
                          ) : (
                            <tr className="">
                              <td colSpan="9">
                                <Spinner />
                              </td>
                            </tr>
                            //   <tr className="">
                            //     <td colSpan="9">No data available</td>
                            //   </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={
          <SendEmailToCustomer
            closeModal={closeModal}
            closeUpdateModal={closeUpdateModal}
          />
        }
        dialogClassName="modal-width-30"
      />
    </div>
  );
};

export default AppointmentList;
