import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : "";

  return (
    <div className="bg-white z-50 bottom-0 sticky w-full">
      <div
        className={`grid ${
          userInfo.user_type === "enthusiastic"
            ? "grid-cols-5"
            : userInfo.user_type === "admin"
            ? "grid-cols-5"
            : "grid-cols-6"
        } py-2.5 text-center`}
      >
        <a href="/" className="mobileHome">
          .
        </a>
        <NavLink
          exact
          activeClassName="active"
          to={
            userInfo.user_type === "enthusiastic"
              ? "/enthusiastDashboard"
              : userInfo.user_type === "admin"
              ? "/adminDashboard"
              : "/professionalDashboard"
          }
          className="mobileDashboard"
        ></NavLink>
        {userInfo.user_type === "professional" ? (
          <NavLink
            exact
            activeClassName="active"
            to={"/" + userInfo.profile_name}
            className="mobileProfileV"
          ></NavLink>
        ) : (
          ""
        )}
        <NavLink
          exact
          activeClassName="active"
          to={
            userInfo.user_type === "enthusiastic"
              ? "/enthusiastProfile"
              : userInfo.user_type === "admin"
              ? "/adminProfile"
              : "/professionalProfile"
          }
          className="mobileProfile"
        ></NavLink>

        {userInfo.user_type === "admin" ? (
          <NavLink
            exact
            activeClassName="active"
            to="/adminReport"
            className="mobileAdminReport"
          ></NavLink>
        ) : (
          ""
        )}

        {userInfo.user_type != "admin" ? (
          <NavLink
            exact
            activeClassName="active"
            to={
              userInfo.user_type === "enthusiastic"
                ? "/bookings"
                : "/appointment"
            }
            className="mobileAppointment"
          ></NavLink>
        ) : (
          ""
        )}
        {userInfo.user_type != "admin" ? (
          <NavLink
            exact
            activeClassName="active"
            to={
              userInfo.user_type === "enthusiastic"
                ? "/mobileMenuEnthu"
                : "/mobileMenu"
            }
            className="mobileMenuIcon"
          ></NavLink>
        ) : (
          ""
        )}
        {userInfo.user_type === "admin" ? (
          <NavLink
            exact
            activeClassName="active"
            to="/category"
            className="mobileAppointment"
          ></NavLink>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Footer;
