import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Model from "./model";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import EditProfileEnthu from "./editProfileEnthu";

const EnthusiastProfile = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loadingInit, setLoadingInit] = useState(false);
  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [primaryDetail, setPrimaryDetail] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoadingInit(true);
    try {
      const response = await axios.get(
        API_URL + "user/profile/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setPrimaryDetail(response.data.user);
        setLoadingInit(false);
        const userData = userInfo;
        userData.first_Name = response.data.user.first_name;
        userData.last_Name = response.data.user.last_name;
        if (response.data.user.profile_picture) {
          userData.profile_picture = response.data.user.profile_picture;
        }
        if (response.data.user.profile_name) {
          userData.profile_name = response.data.user.profile_name;
        }
        if (response.data.user.phone_number) {
          userData.phone_number = response.data.user.phone_number;
        }
        localStorage.setItem("userInfo", JSON.stringify(userData));
      }
    } catch (error) {
      setLoadingInit(false);
      console.error("Error fetching data:", error);
    }
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeUpdateModal = () => {
    setIsOpenModal(false);
    fetchData();
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <div>
      <div className="bg-white1 ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div
              className={` ${
                matches ? "mx-2 my-2 min-h-screen" : "mx-4 my-4"
              } `}
            >
              <div className="grid grid-cols-6 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-4 xs:grid-cols-4 xxs:grid-cols-4 gap-3">
                <div className={` ${matches ? "col-span-6" : "col-span-4"} `}>
                  <div className="text-xl font-medium">
                    Manage Profile for{" "}
                    <span className="font-semibold ">
                      {primaryDetail
                        ? primaryDetail.first_name +
                          " " +
                          primaryDetail.last_name
                        : ""}
                    </span>
                  </div>
                  <div className="mt-3 bg-white rounded border border-grey7">
                    <div className="px-4 pt-4 block ">
                      {!loadingInit ? (
                        <div className="border-b border-grey9 pb-4">
                          <div className="inline-block font-semibold ">
                            {primaryDetail
                              ? primaryDetail.first_name +
                                " " +
                                primaryDetail.last_name
                              : "Please update your profile"}

                            {primaryDetail && primaryDetail.email ? (
                              <span className="font-normal text-sm block">
                                {primaryDetail.email}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="inline-block float-right">
                            <button onClick={() => openModal()}>
                              <img alt="user" src="img/edit.png" />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <Spinner />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={
          <EditProfileEnthu
            closeModal={closeModal}
            closeUpdateModal={closeUpdateModal}
            primaryDetail={primaryDetail}
          />
        }
        dialogClassName="modal-width-profile"
      />
    </div>
  );
};

export default EnthusiastProfile;
