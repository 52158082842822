import React from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Header from "./header";
import Footer from "./mobileFooter";

function MostFamousProfessionals() {
  const matches = UseMediaQuery("(max-width: 992px)");

  return (
    <>
      {matches ? <Header /> : ""}
      <div
        className={` ${
          matches ? "" : "border border-grey7"
        } bg-white rounded p-4`}
      >
        {/* {matches ? (
        <>
          <img alt="arrow" src="img/larr.png" className="inline" />
          <button
            className="text-sm font-semibold mb-3 ml-1.5"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </>
      ) : (
        ""
      )} */}

        <div className="block">
          <div className="inline text-base font-semibold">
            Most Famous Professionals
          </div>
        </div>

        <div className="mt-3">
          <div className=" block">
            <div className="inline-block align-middle">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
            </div>
          </div>

          <div className=" block mt-3">
            <div className="inline-block align-middle">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
            </div>
          </div>

          <div className=" block mt-3">
            <div className="inline-block align-middle">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
            </div>
          </div>
          <div className=" block mt-3">
            <div className="inline-block align-middle">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
            </div>
          </div>

          <div className=" block mt-3">
            <div className="inline-block align-middle">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
            </div>
          </div>

          <div className=" block mt-3">
            <div className="inline-block align-middle">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
            </div>
          </div>

          <div className=" block mt-3">
            <div className="inline-block align-middle">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
            </div>
          </div>

          {matches ? (
            ""
          ) : (
            <div className="text-green1 font-bold mt-4 text-sm">View all</div>
          )}
        </div>
      </div>
      {matches ? <Footer /> : ""}
    </>
  );
}

export default MostFamousProfessionals;
