import React, { useState, useEffect, useRef } from "react";
import Spinner from "./spinner";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";
import DeleteModal from "./deleteModal";
import Model from "./model";
import { UseMediaQuery } from "../hooks/useMediaQuery";

const AddWorkIMg = ({ closeModal }) => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [workImg, setWorkImg] = useState();
  const [modalIsOpenDelete, setIsOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [imgErr, setImgErr] = useState(false);
  const [priorityData, setPriorityData] = useState([]);
  const inputRef = useRef();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoadingInit(true);
    try {
      const response = await axios.get(
        API_URL + "user/get-all-professional-images/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setWorkImg(response.data);
        setLoadingInit(false);
        let data = [];
        response.data.map((img) => {
          const newItem = {
            work_id: img._id,
            priority: img.priority,
            caption: img.caption,
          };
          data.push(newItem);
        });
        const newArray = [...priorityData, data];
        setPriorityData(data);
      }
    } catch (error) {
      setLoadingInit(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleUpload = async () => {
    const files = inputRef.current.files;
    setLoading(true);
    if (files.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 5242880) {
          setImgErr(true);
        } else {
          formData.append("media", files[i]);
        }
      }

      if (formData) {
        await axios
          .post(API_URL + "upload/multi-upload", formData, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            let arr = [];

            response.data.forEach((item) => {
              arr.push(item.media);
            });

            let formDataAdd = {};
            formDataAdd["user_id"] = userInfo.user_id;
            formDataAdd["image_urls"] = arr;

            axios
              .post(API_URL + "user/professional-images", formDataAdd, {
                headers: { authorization: "Bearer " + token },
              })
              .then(function (response) {
                fetchData();
                setLoading(false);
                toastSuccess("Images added successfully");
              })
              .catch(function (error) {
                setLoading(false);
                console.log(error);
                toastError("Images not added");
              });
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
            toastError("Images not added");
          });
      }
    }
  };

  const openModalDelete = (id) => {
    setDeleteId(id);
    setIsOpenModalDelete(true);
  };

  const closeModalDelete = () => {
    fetchData();
    setIsOpenModalDelete(false);
  };

  const handleDelete = async () => {
    setLoadingDelete(true);
    try {
      await axios
        .delete(API_URL + "user/professional-images/" + deleteId, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          fetchData();
          setLoadingDelete(false);
          toastSuccess("Image deleted successfully");
          setIsOpenModalDelete(false);
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Image not deleted");
          console.log(error);
          setLoadingDelete(false);
        });
    } catch (error) {
      setLoadingDelete(false);
      toastError(error || "Image not deleted");
      console.error("Error:", error);
    }
  };

  const fetchPriorityData = (id) => {
    const index = priorityData.findIndex((user) => user.work_id === id);
    if (index >= 0) {
      return priorityData[index].priority;
    }
    return 0;
  };

  const fetchCaptionData = (id) => {
    const index = priorityData.findIndex((user) => user.work_id === id);
    if (index >= 0) {
      return priorityData[index].caption;
    }
    return "";
  };

  const updatePriorityData = (val, id, data, type) => {
    if (priorityData) {
      const index = priorityData.findIndex((user) => user.work_id === id);
      if (index >= 0) {
        const newData = [...priorityData];
        // Update the priority of the specified element
        if (type === "order") {
          newData[index].priority = val;
        } else {
          newData[index].caption = val;
        }

        // Set the updated array to the state
        setPriorityData(newData);
      } else {
        const newItem = {};
        if (type === "order") {
          newItem = {
            work_id: id,
            priority: val,
          };
        } else {
          newItem = {
            work_id: id,
            caption: val,
          };
        }
        const newArray = [...priorityData, newItem];
        setPriorityData(newArray);
      }
    } else {
      let priorityData = [];
      priorityData.push(data);
      setPriorityData(priorityData);
    }
  };

  const handleOrder = (val, id) => {
    let data = {};
    data["work_id"] = id;
    data["priority"] = val;
    updatePriorityData(val, id, data, "order");
  };

  const handleCaption = (val, id) => {
    let data = {};
    data["work_id"] = id;
    data["caption"] = val;
    updatePriorityData(val, id, data, "caption");
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    let data = {};
    data["data"] = priorityData;

    try {
      await axios
        .put(API_URL + "user/professional-images", data, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          setLoadingSubmit(false);
          toastSuccess("Image Order updated successfully");
          closeModal();
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Image Order not updated");
          console.log(error);
          setLoadingSubmit(false);
        });
    } catch (error) {
      setLoadingSubmit(false);
      toastError(error || "Image Order not updated");
      console.error("Error:", error);
    }
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="block border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          <div className="inline-block">Our Work</div>
          <div className={` ${matches ? "" : "float-right"} inline-block`}>
            <>
              {loading ? (
                <div className="mt-[-40px]">
                  <Spinner />
                </div>
              ) : (
                <div className="file-container addWork">
                  <label
                    htmlFor="fileInput"
                    className="text-green1 font-semibold opacity-80 cursor-pointer text-lg"
                  >
                    + Upload Images from computer
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    id="fileInput"
                    ref={inputRef}
                    onChange={handleUpload}
                    className="file-input"
                  />
                  {imgErr ? (
                    <div className="text-xs text-red">
                      Image more than 5 MB will not get uploaded
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </>
          </div>
        </div>
        {!loadingInit ? (
          <div className="">
            <div className="gap-4 grid lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-5 xs:grid-cols-3 xxs:grid-cols-3 max-h-[300px] overflow-y-scroll">
              {workImg &&
                workImg.map((img) => {
                  return (
                    <div className="relative">
                      <img
                        alt="workImg"
                        src={img.image_urls ? img.image_urls : "img/img2.png"}
                        className={` ${
                          matches ? "h-[90px]" : "h-[117px]"
                        } mt-3  w-[117px]`}
                      />
                      <button
                        className="absolute top-[25px] right-[18px]"
                        onClick={(id) => openModalDelete(img._id)}
                      >
                        <img alt="delete" src="img/deleteR.png" />
                      </button>
                      <input
                        type="number"
                        className="w-2/5 p-1 mt-2 border border-grey-9 mx-auto block"
                        onChange={(e, id) =>
                          handleOrder(e.target.value, img._id)
                        }
                        value={fetchPriorityData(img._id)}
                      />
                      <input
                        type="text"
                        placeholder="Caption"
                        className="w-4/5 p-1 mt-2 border border-grey-9 mx-auto block"
                        onChange={(e, id) =>
                          handleCaption(e.target.value, img._id)
                        }
                        value={fetchCaptionData(img._id)}
                      />
                    </div>
                  );
                })}
            </div>

            <div className="float-right mt-3">
              {loadingSubmit ? (
                <Spinner />
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => closeModal()}
                    className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                  >
                    Cancel
                  </button>

                  <input
                    onClick={() => handleSubmit()}
                    type="submit"
                    value="Update"
                    name="Update"
                    className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>

      <Model
        modalIsOpen={modalIsOpenDelete}
        closeModal={closeModalDelete}
        content={
          <DeleteModal
            closeModalDelete={closeModalDelete}
            title="Image"
            handleDelete={handleDelete}
            loading={loadingDelete}
          />
        }
        dialogClassName="modal-width-30"
      />
    </div>
  );
};

export default AddWorkIMg;
