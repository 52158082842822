import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  convertDate,
  convertTimeHourMinute,
  convertTimeString,
} from "../hooks/commonFunctions";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import axios from "axios";
import Spinner from "./spinner";
import DeleteModal from "./deleteModal";
import Model from "./model";
import { toastSuccess, toastError } from "../hooks/toaster";

const localizer = momentLocalizer(moment);

const CalendarViewProfessional = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const token = localStorage.getItem("userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [memberData, setMemberData] = useState();
  const [loading, setLoading] = useState(false);
  const [memberSelected, setMemberSelected] = useState();
  //const [memberAppointmentData, setMemberAppointmentData] = useState();
  const [eventData, setEventData] = useState();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [customMsg, setCustomMsg] = useState();
  const [modalIsOpenDelete, setIsOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const endWithIncreasedDuration = moment(new Date(2024, 0, 31, 15, 0)).add(
    90,
    "minutes"
  );
  const eventsStatic = [
    {
      title: "Hair cut with Rick",
      start: new Date(2024, 1, 5, 10, 0),
      end: new Date(2024, 1, 5, 11, 0),
    },
    {
      title: "Hair spa with Jessica",
      start: new Date(2024, 0, 31, 15, 0),
      end: endWithIncreasedDuration, //new Date(2024, 0, 25, 16.3, 0),
    },
    // More events...
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "member/get-all-member/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setMemberData(response.data);
        fetchMemberData(response.data[0].item._id);
        //fetchBreakData(response.data[0]._id);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchBreakData = async (memberId) => {
    let eveData = [];
    try {
      const responseM = await axios.get(
        API_URL + "slot/get-break-time-by-member/" + memberId,
        { headers: { authorization: "Bearer " + token } }
      );
      if (responseM) {
        responseM.data.map((data) => {
          let eveRow = {};
          let date = convertDate(data.slot_id.slot_Date);
          let time = convertTimeHourMinute(data.start_time);
          let timeE = convertTimeHourMinute(data.end_time);
          eveRow["title"] = "Break Time";
          eveRow["start"] = new Date(
            date[0],
            date[1],
            date[2],
            time[0],
            time[1]
          );
          eveRow["end"] = new Date(
            date[0],
            date[1],
            date[2],
            timeE[0],
            timeE[1]
          );
          eveData.push(eveRow);
        });
        setEventData((prevEvents) => [...prevEvents, ...eveData]);
        //setEventData((prevEvents) => (prevEvents, eveData));
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleEventCancel = (canceledEvent) => {
    // Perform cancellation logic (e.g., update the event status)
    // You might want to update the events array or make an API call here
    // The onEventCancel prop can be used to notify the parent component about the cancellation

    setCustomMsg(
      convertTimeString(canceledEvent.start) +
        " - " +
        convertTimeString(canceledEvent.end) +
        " - " +
        canceledEvent.title
    );
    setIsOpenModalDelete(true);
    setDeleteId(canceledEvent.id);
  };

  const EventDetail = ({ event, onCancel }) => (
    <div className="text-sm">
      <p>{event.title}</p>
      {/* <strong>{event.title}</strong>
      <p>{`Start: ${moment(event.start).format("MMMM Do YYYY, h:mm a")}`}</p>
      <p>{`End: ${moment(event.end).format("MMMM Do YYYY, h:mm a")}`}</p> */}
      <button className="text-[#4C2620]" onClick={() => onCancel(event)}>
        Cancel Booking
      </button>
    </div>
  );

  const fetchMemberData = async (memberId) => {
    let eveData = [];
    try {
      const responseM = await axios.get(
        API_URL + "service/get-member-booking-service/" + memberId,
        { headers: { authorization: "Bearer " + token } }
      );
      if (responseM) {
        //setMemberAppointmentData(responseM.data);
        responseM.data.map((data) => {
          let eveRow = {};
          let date = convertDate(data.day);
          let time = convertTimeHourMinute(data.booking_time);
          eveRow["title"] =
            data.service_id.service_name +
            " with " +
            data.customer_user_id.first_name +
            " (" +
            data.customer_user_id.phone_number +
            ")";
          eveRow["start"] = new Date(
            date[0],
            date[1],
            date[2],
            time[0],
            time[1]
          );
          eveRow["end"] = calculateEndTime(
            date[0],
            date[1],
            date[2],
            time[0],
            time[1],
            data.service_id.period
          );
          eveRow["id"] = data._id;
          eveData.push(eveRow);
        });
        //setEventData((prevEvents) => (prevEvents, eveData));
        setEventData(eveData, setBreakData(memberId));
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const setBreakData = (memberId) => {
    fetchBreakData(memberId);
  };

  const calculateEndTime = (year, month, date, hour, min, period) => {
    return moment(new Date(year, month, date, hour, min)).add(
      period,
      "minutes"
    );
  };

  const handleChange = (e) => {
    setMemberSelected(e.target.value);
    fetchMemberData(e.target.value);
    //fetchBreakData(e.target.value);
  };

  const handleSelect = ({ start, end }) => {
    const title = window.prompt("Appointment name");
    const duration = window.prompt("Appointment Duration");
    const newEnd = moment(start).add(90, "minutes").toDate();
    if (title) {
      const newEvent = {
        title,
        start,
        end: newEnd,
      };
      setEvents([...events, newEvent]);
    }
  };

  const handleEventClick = (event) => {
    if (event && event.title) {
      const title = window.prompt("Edit Event name", event.title);
      if (title) {
        const updatedEvent = { ...event, title };
        const updatedEvents = events.map((e) =>
          e === event ? updatedEvent : e
        );
        setEvents(updatedEvents);
      }
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    // Check the event title and assign colors accordingly
    if (event.title.includes("Break Time")) {
      return {
        style: {
          backgroundColor: "#DBDDFF",
          color: "black",
        },
      };
    }

    // Default styling for other events
    return {
      style: {
        backgroundColor: "#539D90",
        color: "white",
      },
    };
  };

  const closeModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleDelete = async () => {
    setLoadingDelete(true);

    try {
      const response = await axios.put(
        API_URL + "service/cancel-booking-by-salon/" + deleteId,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        fetchData();
        toastSuccess("Booking cancelled successfully");
        setDeleteId("");
        setLoadingDelete(false);
        setIsOpenModalDelete(false);
      }
    } catch (error) {
      setLoadingDelete(false);
      setIsOpenModalDelete(false);
      toastError("Booking not cancelled");
      setDeleteId("");
    }
  };

  return (
    <div>
      <div className="bg-white1 ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div
              className={` ${
                matches ? "mx-2 my-2 min-h-screen" : "mx-4 my-4"
              } `}
            >
              {!loading ? (
                <>
                  <div className={` ${matches ? "inline-block" : "block"}`}>
                    <select
                      name="member"
                      className="inline-block ml-2 p-2 border border-grey-9 rounded"
                      id="breakStartTime"
                      value={memberSelected}
                      onChange={handleChange}
                    >
                      {memberData &&
                        memberData.length > 0 &&
                        memberData.map((members) => {
                          let member = members.item;
                          return (
                            <option key={member._id} value={member._id}>
                              {member.first_name + " " + member.last_name}
                            </option>
                          );
                        })}
                    </select>
                    <a
                      className={` ${
                        matches ? "text-sm" : ""
                      } ml-1 bg-white inline-block float-right p-2 border border-grey9 rounded`}
                      href="/addAppointment"
                    >
                      Add Appointment
                    </a>
                    <a
                      className={`${
                        matches ? "text-sm" : ""
                      } bg-white inline-block float-right p-2 border border-grey9 rounded`}
                      href="/appointmentList"
                    >
                      Appointment Listing
                    </a>
                  </div>

                  <div className="bg-white p-2 rounded mt-2">
                    <Calendar
                      localizer={localizer}
                      events={eventData} //{eventsStatic}
                      components={{
                        event: (eventProps) => (
                          <EventDetail
                            {...eventProps}
                            onCancel={handleEventCancel}
                          />
                        ),
                      }}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 500 }}
                      selectable
                      //onSelectSlot={handleSelect}
                      onSelectEvent={(event) => setSelectedEvent(event)}
                      onDoubleClickEvent={handleEventClick}
                      //views={["week", "day"]}
                      min={new Date(0, 0, 0, 9, 0, 0)}
                      max={new Date(0, 0, 0, 21, 0, 0)}
                      eventPropGetter={eventStyleGetter}
                    />
                  </div>
                </>
              ) : (
                <Spinner />
              )}
            </div>
            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>
      <Model
        modalIsOpen={modalIsOpenDelete}
        closeModal={closeModalDelete}
        content={
          <DeleteModal
            closeModalDelete={closeModalDelete}
            title="Booking"
            handleDelete={handleDelete}
            loading={loadingDelete}
            headerTitle="Cancel"
            customMsg={customMsg}
          />
        }
        dialogClassName="modal-width-30"
      />
    </div>
  );
};

export default CalendarViewProfessional;
