import React, { useState } from "react";
import { Eventcalendar } from "@mobiscroll/react-lite";

const CalendarExample = () => {
  const [view, setView] = useState("week");

  const switchToWeekView = () => {
    setView("week");
  };

  const switchToMonthView = () => {
    setView("month");
  };

  return (
    <div>
      <div>
        <button onClick={switchToWeekView}>Switch to Week View</button>
        <button onClick={switchToMonthView}>Switch to Month View</button>
      </div>
      <Eventcalendar view={view} />
    </div>
  );
};

export default CalendarExample;
