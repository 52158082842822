import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import axios from "axios";
import Spinner from "./spinner";
import CategoryList from "./categoryList";

const Category = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div className="bg-white1 ">
      <div className="flex">
        <Menu />
        <div className="w-full ">
          <Header />
          <div className={` ${matches ? "mx-2 my-2" : "mx-4 my-4"} `}>
            <div className="mt-3 bg-white rounded border border-grey7">
              <CategoryList />
            </div>
          </div>
          {matches ? <Footer /> : ""}
        </div>
      </div>
    </div>
  );
};

export default Category;
