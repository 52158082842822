import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/menu.css";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";
import Model from "./model";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import OuterMenu from "./outerMenu";
import OuterFooter from "./outerFooter";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import axios from "axios";
import Spinner from "./spinner";
import Interest from "./interest";

function Home() {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const matches = UseMediaQuery("(max-width: 992px)");
  const options = ["Service", "Professional"];
  const [type, setType] = useState(0);
  const [formData, setFormData] = useState({
    service: "",
    location: "",
  });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalIsOpenInterest, setModalIsOpenInterest] = useState(false);

  const images = [
    "img/home/001.png",
    "img/home/002.png",
    "img/home/003.png",
    "img/home/004.png",
    "img/home/005.png",
    "img/home/006.png",
    "img/home/007.png",
    "img/home/008.png",
    "img/home/008.png",
    "img/home/010.png",
    "img/home/011.png",
    "img/home/012.png",
    "img/home/013.png",
    "img/home/014.png",
    "img/home/015.png",
    "img/home/016.png",
    "img/home/017.png",
    "img/home/018.png",
    "img/home/019.png",
    "img/home/020.png",
    "img/home/021.png",
    "img/home/022.png",
    "img/home/023.png",
    "img/home/024.png",
    "img/home/025.png",
    "img/home/026.png",
    "img/home/027.png",
    "img/home/028.png",
    "img/home/029.png",
    "img/home/030.png",
    "img/home/031.png",
    "img/home/032.png",
    "img/home/033.png",
    "img/home/034.png",
    "img/home/035.png",
    "img/home/036.png",
    "img/home/037.png",
    "img/home/038.png",
    "img/home/039.png",
    "img/home/040.png",
    "img/home/041.png",
    "img/home/042.png",
  ];

  function closeModalInterest() {
    setModalIsOpenInterest(false);
  }

  useEffect(() => {
    // Function to handle automatic sliding
    const autoSlide = () => {
      // Generate a random index different from the current index
      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * images.length);
      } while (randomIndex === currentIndex);

      // Set the new index
      setCurrentIndex(randomIndex);
    };

    // Set up the interval for automatic sliding
    const intervalId = setInterval(autoSlide, 3000); // Change 3000 to the desired interval in milliseconds

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [currentIndex, images]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(API_URL + "user/login/success", {
  //       withCredentials: true,
  //     });

  //     if (response) {
  //       console.log(response, "response 123");
  //       localStorage.setItem("userInfo", JSON.stringify(response.data.user));
  //       if (response.data.user.interest_status) {
  //         if (response.data.user.user_type === "professional") {
  //           navigate("/professionalDashboard");
  //         } else {
  //           navigate("/enthusiastDashboard");
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handleSearch = async () => {
    setLoading(true);
    if (!formData.service && !formData.location) {
      // service/professional and location both not added
      setError("Please add service or professional or location");
      setLoading(false);
    } else {
      setError("");
      let url = "";
      // if (formData.service && formData.location) {
      //   //service/professional and location and type = service added
      //   if (type === 0) {
      //     //service/professional and location added
      //     url =
      //       "user/salon-list?search_query_1=" +
      //       formData.service +
      //       "&search_query_2=" +
      //       formData.location +
      //       "&type=service";
      //   } else {
      //     //service/professional and location and type = professional added
      //     url =
      //       "user/salon-list?search_query_1=" +
      //       formData.service +
      //       "&search_query_2=" +
      //       formData.location +
      //       "&type=salon";
      //   }
      // } else {
      //   //service/professional or location added
      //   if (formData.service) {
      //     //service/professional added
      //     if (type === 0) {
      //       url =
      //         "user/salon-list?search_query_1=" +
      //         formData.service +
      //         "&type=service";
      //     } else {
      //       url =
      //         "user/salon-list?search_query_1=" +
      //         formData.service +
      //         "&type=salon";
      //     }
      //   } else {
      //     //location added
      //     if (type === 0) {
      //       url = "user/salon-list?search_query_2=" + formData.location;
      //     } else {
      //       url = "user/salon-list?search_query_2=" + formData.location;
      //     }
      //   }
      // }

      url = "user/professional-user";
      await axios
        .get(API_URL + url + "?limit=500", {
          headers: {},
        })
        // .get(API_URL + url + "&limit=100&page=1", {
        //   headers: {},
        // })
        .then(function (response) {
          setLoading(false);
          navigate("/salonListing", { state: { data: response.data } });
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleType = (e) => {
    if (e.value === "Professional") {
      setType(1);
    } else {
      setType(0);
    }
  };

  return (
    <div>
      <div className="homecls ">
        <OuterMenu />

        <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 xxs:grid-cols-1">
          <div className="lg:pl-44 md:pl-28 sm:pl-20 xs:pl-10 xs:pr-10 xxs:pl-8 xxs:pr-8 text-white">
            <div className="ivymode lg:text-5xl md:text-5xl sm:text-4xl xs:text-3xl font-normal lg:mt-20 md:mt-20 sm:mt-20 xs:mt-10 xxs:mt-10">
              Reserve services from nearby beauty experts.
            </div>
            <div className="lg:text-xl md:text-xl sm:text-lg xs:text-md font-normal mt-4">
              Discover beauty at your Fingertips! Effortlessly schedule
              appointments with skilled professionals for a pampering session in
              the comfort of your own space.
            </div>
          </div>
          <div className="homeR">
            <div className="homeRight"></div>
            <div className="lg:pr-44 lg2:pr-32 md:pr-28 sm:pr-20 xs:pr-10 xxs:pr-8 lg:pl-16 md:pl-28 sm:pl-20 xs:pl-10 xxs:pl-8 homeR2 sm:mt-10 xs:mt-10 xxs:mt-10">
              {/* <img
                alt="home"
                className="max-h-[500px] w-[700px]"
                src="img/home2.png"
              /> */}

              <img
                src={images[currentIndex]}
                alt={`Slide ${currentIndex + 1}`}
                className="max-h-[500px] w-[700px]"
                //style={{ maxWidth: "100%", maxHeight: "100%" }}
              />

              <div className="text-3xl mt-4 font-semibold">
                Discover & book local beauty professionals
              </div>
              <div className="lg:text-[16px] md:text-[16px] sm:text-[14px] xs:text-[14px] xxs:text-[14px] mt-4">
                {!matches ? (
                  <>
                    <div className="grid grid-cols-7 border border-grey3 rounded relative">
                      <input
                        value={formData.service}
                        name="service"
                        onChange={handleChange}
                        type="text"
                        className="px-2 py-2  border-r border-grey4 col-span-4 placeholder-gray-500 text-gray-900"
                        placeholder="Search Services or professionals"
                      />
                      {/* <Dropdown
                        className="col-span-2 text-gray-900 border-r border-grey4"
                        options={options}
                        value={options[type]}
                        placeholder="Select an option"
                        onChange={(e) => handleType(e)}
                      /> */}
                      <div className="col-span-3 grid grid-cols-3">
                        <input
                          value={formData.location}
                          name="location"
                          onChange={handleChange}
                          type="text"
                          className="px-3 py-2 col-span-2 placeholder-gray-500 text-gray-900"
                          placeholder="Location"
                        />
                        <button
                          onClick={() => handleSearch()}
                          className="col-span-1 float-right align-middle ml-auto shadowEffect hover:opacity-60"
                        >
                          <img alt="search" src="img/search.png" />
                        </button>
                      </div>
                    </div>
                    {loading ? <Spinner /> : ""}
                    {error ? <div className="text-red mt-1">{error}</div> : ""}
                  </>
                ) : (
                  <div className="">
                    <input
                      value={formData.service}
                      name="service"
                      onChange={handleChange}
                      placeholder="Search Services or professionals"
                      type="text"
                      className="rounded border border-grey3 placeholder-gray-500 text-gray-900 px-3 py-2.5 w-full "
                      // className="rounded border border-grey3 placeholder-gray-500 text-gray-900 px-3 py-3 w-full locationSearch"
                    />
                    {/* <Dropdown
                      className="col-span-2 text-gray-900 rounded border border-grey4 mt-2"
                      options={options}
                      value={options[type]}
                      placeholder="Select an option"
                      onChange={(e) => handleType(e)}
                    /> */}
                    <div className="rounded border border-grey4 mt-2">
                      <input
                        value={formData.location}
                        name="location"
                        onChange={handleChange}
                        type="text"
                        className="px-3 py-2.5 col-span-2 placeholder-gray-500 text-gray-900"
                        placeholder="Location"
                      />
                      <button
                        onClick={() => handleSearch()}
                        className="col-span-1 float-right align-middle ml-auto shadowEffect hover:opacity-60"
                      >
                        <img alt="search" src="img/search.png" />
                      </button>
                    </div>
                    {loading ? <Spinner /> : ""}
                    {error ? <div className="text-red mt-1">{error}</div> : ""}
                  </div>
                )}
              </div>
              {/* <div className="mt-4 border border-grey3 rounded ">
                <input
                  type="text"
                  className="px-3 py-4 w-[280px] border-r border-grey4"
                  placeholder="Search Services or professionals"
                />
                <input
                  type="text"
                  className="p-4 max-w-[180px]"
                  placeholder="Location"
                />
                <button className="align-middle">
                  <img src="img/search.png" />
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="lg:mt-12 md:mt-10 sm:mt-10 xs:mt-10 xxs:mt-6 lg:px-44 md:px-28 sm:px-20 xs:px-10 xxs:px-8">
        <div className="ivymode text-black2 lg:text-4xl md:text-3xl sm:text-2xl xs:text-xl font-normal">
          Discover a personalized services <br /> designed for both men and
          women
        </div>
        <div className="lg:mt-12 md:mt-10 sm:mt-8 xs:mt-6 xxs:mt-5">
          <img alt="pelito" src="img/home3.png" />
        </div>

        {/* <div className="mt-12 mb-12 justify-center flex">
          <img alt="pelito" src="img/home4.png" />
        </div>

        <img alt="pelito" src="img/home5.png" /> */}
      </div>

      <div className="bg-black2">
        <div className="mt-14 pt-12 lg:px-44 md:px-28 sm:px-20 xs:px-10 xxs:px-8">
          <div className="grid grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 xxs:grid-cols-1">
            <div className="text-white">
              {/* <div className="mt-4 text-xl font-medium">
                Our Narrative Through the Years
              </div>
              <div className="text-3xl ivymode mt-3">
                Where <span className="text-green1">Hair</span> and{" "}
                <span className="text-green1"> Innovation </span>Unite!
              </div>
              <div className="lg:mt-20 md:mt-16 sm:mt-14 xs:mt-12 xxs:mt-10">
                What Sets Us Apart:
              </div>
              <div className="bg-black3 mt-2 px-3 py-3 rounded font-medium">
                <Accordion allowZeroExpanded>
                  <AccordionItem key="">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Innovative Network
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      Pelito goes beyond conventional platforms. It’s a dynamic
                      space where hairstylists, enthusiasts, and trendsetters
                      connect, collaborate, and create.
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>

              <div className="bg-black3 mt-2 px-3 py-3 rounded font-medium">
                <Accordion allowZeroExpanded>
                  <AccordionItem key="">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Appointment Revolution
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      Pelito goes beyond conventional platforms. It’s a dynamic
                      space where hairstylists, enthusiasts, and trendsetters
                      connect, collaborate, and create.
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>

              <div className="bg-black3 mt-2 px-3 py-3 rounded font-medium">
                <Accordion allowZeroExpanded>
                  <AccordionItem key="">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Empowering Professionals
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      Pelito goes beyond conventional platforms. It’s a dynamic
                      space where hairstylists, enthusiasts, and trendsetters
                      connect, collaborate, and create.
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>

              <div className="bg-black3 mt-2 px-3 py-3 rounded font-medium">
                <Accordion allowZeroExpanded>
                  <AccordionItem key="">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Enthusiast Delight
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      Pelito goes beyond conventional platforms. It’s a dynamic
                      space where hairstylists, enthusiasts, and trendsetters
                      connect, collaborate, and create.
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>

              <div className="bg-black3 mt-2 px-3 py-3 rounded font-medium">
                <Accordion allowZeroExpanded>
                  <AccordionItem key="">
                    <AccordionItemHeading>
                      <AccordionItemButton>Cultural Fusion</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      Pelito goes beyond conventional platforms. It’s a dynamic
                      space where hairstylists, enthusiasts, and trendsetters
                      connect, collaborate, and create.
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div> */}
            </div>
            {/* <div className="text-white lg:pl-10 md:mt-3 sm:mt-2.5 xs:mt-2 xxs:mt-2">
              <div className=" lg:text-2xl md:text-xl sm:text-lg xs:text-base xxs:text-base">
                Welcome to <span className="text-green1">Pelito</span>, A
                revolutionary platform designed to transform the hair and
                fashion industry. We’re not just a network, we’re a community, a
                hub where professionals, enthusiasts, and influencers come
                together to redefine style and beauty.
              </div>
              <div className="mt-5">
                <img
                  alt="pelito"
                  src="img/home6.png"
                  className="h-96 w-[30rem]"
                />
              </div>
            </div> */}
          </div>
          <div className="mt-10 text-white lg:text-2xl md:text-xl sm:text-lg xs:text-base xxs:text-base font-normal pb-12">
            <div className="mb-4 text-center text-green1 text-xl">
              Pelito: Your All-in-One Destination for Hair Industry Triumphs and
              Tech Advancements!
            </div>
            Welcome to Pelito, The Hair Network, where professionals and
            enthusiasts come together to redefine the standards of excellence in
            the hair industry. Our platform offers a wealth of resources, tools,
            and support tailored to meet the diverse needs of hairstylists and
            barbers worldwide. Whether you’re a seasoned professional looking to
            expand your clientele or an enthusiast eager to explore the latest
            trends and techniques, Pelito.net is your ultimate destination.
            <br /> <br />
            And that’s not all! We invite you to explore our STEM Programs,
            where you can discover a range of exciting opportunities to engage
            with technology, engineering, mathematics, and science. From coding
            workshops to virtual science fairs, our STEM Programs offer
            something for everyone. Join us today and unlock the full potential
            of your career in the hair and fashion industry, while also diving
            into the fascinating world of STEM!
            {/* Whether you’re a professional looking to elevate your career or an
            enthusiast seeking the latest trends, Pelito.net welcomes you to a
            world where every strand tells a story. */}
            <div className="mt-10 text-green1 text-xl">
              #PelitoNet #HairRevolution #InnovationInBeauty
            </div>
          </div>
        </div>
      </div>

      <div className="peachGradient text-white px-10 py-20 text-center lg:text-5xl md:text-4xl sm:text-3xl xs:text-2xl xxs:text-2xl ivymode font-normal leading-[50px]">
        <span>
          "Where scissors meet creativity, transformations <br /> unfold in the
          mirror of the salon."
        </span>
      </div>

      <OuterFooter />

      <Model
        modalIsOpen={modalIsOpenInterest}
        closeModal={closeModalInterest}
        content={<Interest />}
        dialogClassName="modal-width"
      />
    </div>
  );
}

export default Home;
