import React from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Header from "./header";
import Footer from "./mobileFooter";

function RatingsProfessional() {
  const matches = UseMediaQuery("(max-width: 992px)");

  return (
    <>
      {matches ? <Header /> : ""}
      <div
        className={` ${
          matches ? "" : "border border-grey7"
        } bg-white rounded p-4 `}
      >
        <div className="block border-b border-grey9 pb-3">
          <div className="inline text-base font-semibold ">
            Review & Ratings
          </div>
        </div>

        <div className="mt-4">
          <div className="border border-grey7 rounded px-2 py-2  text-[9px]">
            <div className="block pb-1.5 border-b border-grey9">
              <div className="mt-2 pb-2  inline-block">
                <div className=" font-semibold">
                  Hair Cut w/Finishing & Style
                </div>
                <div className="">By, Ramon chock</div>
              </div>
              <div className="float-right inline-block">
                <img src="img/rating.png" alt="ratings" />
              </div>
            </div>
            <div className="mt-2">
              "Exceptional experience at XYZ Salon! The skilled stylist
              understood my vision, delivering a precision haircut that exceeded
              expectations. The salon exuded a trendy yet welcoming ambiance.
              ...
              <span className="">show more</span>
            </div>
          </div>

          <div className="border border-grey7 rounded px-2 py-2  text-[9px] mt-3">
            <div className="block pb-1.5 border-b border-grey9">
              <div className="mt-2 pb-2  inline-block">
                <div className=" font-semibold">
                  Hair Cut w/Finishing & Style
                </div>
                <div className="">By, Ramon chock</div>
              </div>
              <div className="float-right inline-block">
                <img src="img/rating.png" alt="ratings" />
              </div>
            </div>
            <div className="mt-2">
              "Exceptional experience at XYZ Salon! The skilled stylist
              understood my vision, delivering a precision haircut that exceeded
              expectations. The salon exuded a trendy yet welcoming ambiance.
              ...
              <span className="">show more</span>
            </div>
          </div>

          <div className="border border-grey7 rounded px-2 py-2  text-[9px] mt-3">
            <div className="block pb-1.5 border-b border-grey9">
              <div className="mt-2 pb-2  inline-block">
                <div className=" font-semibold">
                  Hair Cut w/Finishing & Style
                </div>
                <div className="">By, Ramon chock</div>
              </div>
              <div className="float-right inline-block">
                <img src="img/rating.png" alt="ratings" />
              </div>
            </div>
            <div className="mt-2">
              "Exceptional experience at XYZ Salon! The skilled stylist
              understood my vision, delivering a precision haircut that exceeded
              expectations. The salon exuded a trendy yet welcoming ambiance.
              ...
              <span className="">show more</span>
            </div>
          </div>

          <div className="border border-grey7 rounded px-2 py-2  text-[9px] mt-3">
            <div className="block pb-1.5 border-b border-grey9">
              <div className="mt-2 pb-2  inline-block">
                <div className=" font-semibold">
                  Hair Cut w/Finishing & Style
                </div>
                <div className="">By, Ramon chock</div>
              </div>
              <div className="float-right inline-block">
                <img src="img/rating.png" alt="ratings" />
              </div>
            </div>
            <div className="mt-2">
              "Exceptional experience at XYZ Salon! The skilled stylist
              understood my vision, delivering a precision haircut that exceeded
              expectations. The salon exuded a trendy yet welcoming ambiance.
              ...
              <span className="">show more</span>
            </div>
          </div>

          {matches ? (
            ""
          ) : (
            <div className="text-green1 font-bold mt-4 text-sm">View all</div>
          )}
        </div>
      </div>
      {matches ? <Footer /> : ""}
    </>
  );
}

export default RatingsProfessional;
