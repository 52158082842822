import React, { useState, useEffect } from "react";

const TutorialEnthu = () => {
  return (
    <div>
      <div className="mt-5">
        <span className="font-bold ">
          Step 1 : You can search any service or any professionals from here.
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[800px] md:max-w-[650px] sm:max-w-[500px] xs:max-w-[400px] xxs:max-w-[300px]"
          src="img/tutorial/img4.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 1.1 : After click on search button, you will be redirected to
          Salon list based on your search, where you can on any of the salon you
          wish to book your service with.
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[800px] md:max-w-[650px] sm:max-w-[500px] xs:max-w-[400px] xxs:max-w-[300px]"
          src="img/tutorial/img4.1.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 2 : From the professional profile, you can book any services or
          can purchase their product from here.
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[800px] md:max-w-[650px] sm:max-w-[500px] xs:max-w-[400px] xxs:max-w-[300px]"
          src="img/tutorial/img5.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 3 : After booking a service, select any professional you want to
          book with. After that you can book any available time slots from here.
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[800px] md:max-w-[650px] sm:max-w-[500px] xs:max-w-[400px] xxs:max-w-[300px]"
          src="img/tutorial/img6.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 4 : In your cart, you can pay from here
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[800px] md:max-w-[650px] sm:max-w-[500px] xs:max-w-[400px] xxs:max-w-[300px]"
          src="img/tutorial/img7.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 5 : You can check upcoming, completed and cancelled bookings from
          here.
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[800px] md:max-w-[650px] sm:max-w-[500px] xs:max-w-[400px] xxs:max-w-[300px]"
          src="img/tutorial/img8.PNG"
        />
      </div>
    </div>
  );
};

export default TutorialEnthu;
