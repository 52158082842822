import React from "react";

function TimeSlotViewAll({ closeModal, timeSlotData }) {
  const filterData = (day) => {
    return timeSlotDataAdv.filter((user) => user.slot_day === day);
  };
  const timeSlotDataBasic = timeSlotData.filter(
    (user) => user.type === "basic"
  );
  const timeSlotDataAdv = timeSlotData.filter(
    (user) => user.type === "advance"
  );

  const dayArr = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-2">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          Time Slots
        </div>
        <div className="">
          <div className="">
            {timeSlotDataBasic ? (
              <>
                <div className="text-center font-bold p-1 border border-grey9">
                  Basic
                </div>
                <div className="border border-grey9 grid grid-cols-2">
                  {timeSlotDataBasic.map((time) => {
                    return (
                      <>
                        <div className="border border-grey9 p-2">
                          {time.time}
                        </div>
                        <div className="border border-grey9 p-2">
                          {time.capacity}
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="mt-4">
            {timeSlotDataAdv ? (
              <>
                {dayArr.map((day) => {
                  return (
                    <div>
                      <div className="mt-4 text-center font-bold p-1 border border-grey9">
                        Advance - {day}
                      </div>
                      <div className="border border-grey9 grid grid-cols-2">
                        {filterData(day) && filterData(day).length > 0 ? (
                          filterData(day).map((time) => {
                            return (
                              <>
                                <div className="border border-grey9 p-2">
                                  {time.time}
                                </div>
                                <div className="border border-grey9 p-2">
                                  {time.capacity}
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <div className="text-center text-sm col-span-2">
                            No Slot added for {day}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* <div className="">
          {timeSlotData.map((time) => {
            return (
              <div>
                <div className="block mt-2 pb-2">
                  <div className=" inline-block">
                    <div className=" font-semibold">
                      <span className="block">
                        Type :{" "}
                        <span className="text-grey3 font-normal">
                          {time.type}
                        </span>
                      </span>
                      {time.type === "advance" ? (
                        <span className="inline-block">
                          Day :{" "}
                          <span className="text-grey3 font-normal">
                            {time.slot_day}
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                      <span className="block">
                        Time :{" "}
                        <span className="text-grey3 font-normal">
                          {time.time}
                        </span>
                      </span>
                      <span className="block">
                        Capacity :{" "}
                        <span className="text-grey3 font-normal">
                          {time.capacity}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
}

export default TimeSlotViewAll;
