import React, { useState } from "react";
import axios from "axios";
import Spinner from "./spinner";
import { toastSuccess, toastError } from "../hooks/toaster";

function AddToBag({ userId, product }) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const addToCart = async () => {
    if (!userInfo) {
      setError("please Login before adding products");
    } else {
      try {
        setLoading(true);
        let formData = {};
        formData["customer_user_id"] = userInfo.user_id;
        formData["seller_user_id"] = userId;
        formData["product_id"] = product._id;
        formData["price"] = product.price;
        formData["quantity"] = 1; //product.quantity;

        const response = await axios.post(
          API_URL + "product/add-to-cart",
          formData,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );
        if (response) {
          setLoading(false);
          toastSuccess("Product added to cart successfully");
        }
      } catch (error) {
        setLoading(false);
        toastError("Product not added to cart");
        console.error("Error fetching data:", error);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Spinner customClass="spinnerLike" />
      ) : (
        <button
          onClick={addToCart}
          className="p-2 bg-green1 text-white rounded"
        >
          Add to bag
        </button>
      )}
    </>
  );
}

export default AddToBag;
