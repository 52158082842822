import React, { useState, useEffect } from "react";
import Model from "./model";
import HoursAdd from "./hoursAdd";
import axios from "axios";
import ContactHoursAdd from "./contactHoursAdd";

function ContactHours({ page, SalonDetail }) {
  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [hours, setHours] = useState();
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoadingInit(true);
    let id = "";
    page === "enthu"
      ? (id = SalonDetail ? SalonDetail.user._id : "")
      : (id = userInfo.user_id);
    if (id) {
      try {
        const response = await axios.get(
          API_URL + "working-hours/get-working-hours/" + id,
          {
            headers: { authorization: "Bearer " + token },
          }
        );
        if (response) {
          setHours(response.data);
          setLoadingInit(false);
        }
      } catch (error) {
        setLoadingInit(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const findTime = (day) => {
    let time = "10:00 AM - 07:00 PM";
    if (hours && hours.length > 0) {
      const index = hours.findIndex((user) => user.day === day);
      if (index >= 0) {
        time = hours[index].time;
      }
    }
    return time;
  };

  return (
    <div
      className={` ${
        page === "enthu" ? "border border-grey9 p-2 rounded" : ""
      }`}
    >
      <div className="block border-b border-grey9 pb-3">
        <div className="inline-block text-base font-semibold ">
          Contact & Business hours
        </div>
        {page !== "enthu" ? (
          <div className="inline-block float-right">
            <button onClick={() => openModal()}>
              <img alt="user" src="img/edit.png" />
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="mt-4">
        <div className="  text-xs">
          <div className="border-b border-grey9 pb-3">
            <div className="block mt-2 pb-2">
              <div className=" inline-block">
                <div className=" font-semibold">Sunday</div>
              </div>
              <div className="float-right inline-block text-grey3">
                {findTime("Sunday")}
              </div>
            </div>

            <div className="block mt-2 pb-2">
              <div className=" inline-block">
                <div className=" font-semibold">Monday</div>
              </div>
              <div className="float-right inline-block text-grey3">
                {findTime("Monday")}
              </div>
            </div>

            <div className="block mt-2 pb-2">
              <div className=" inline-block">
                <div className=" font-semibold">Tuesday</div>
              </div>
              <div className="float-right inline-block text-grey3">
                {findTime("Tuesday")}
              </div>
            </div>

            <div className="block mt-2 pb-2">
              <div className=" inline-block">
                <div className=" font-semibold">Wednesday</div>
              </div>
              <div className="float-right inline-block text-grey3">
                {findTime("Wednesday")}
              </div>
            </div>

            <div className="block mt-2 pb-2">
              <div className=" inline-block">
                <div className=" font-semibold">Thursday</div>
              </div>
              <div className="float-right inline-block text-grey3">
                {findTime("Thursday")}
              </div>
            </div>

            <div className="block mt-2 pb-2">
              <div className=" inline-block">
                <div className=" font-semibold">Friday</div>
              </div>
              <div className="float-right inline-block text-grey3">
                {findTime("Friday")}
              </div>
            </div>

            <div className="block mt-2 pb-2">
              <div className=" inline-block">
                <div className=" font-semibold">Saturday</div>
              </div>
              <div className="float-right inline-block text-grey3">
                {findTime("Saturday")}
              </div>
            </div>
          </div>
          {page === "enthu" ? (
            <div className="mt-3">
              <span className="inline ml-4 font-semibold">
                <a
                  href={`tel:${
                    SalonDetail && SalonDetail.user
                      ? SalonDetail.user.phone_number
                      : ""
                  }`}
                >
                  {" "}
                  {SalonDetail && SalonDetail.user
                    ? SalonDetail.user.phone_number
                    : ""}{" "}
                </a>
              </span>
            </div>
          ) : userInfo && userInfo.phone_number ? (
            <div className="mt-3 ">
              <img src="img/Call.png" alt="phone" className="inline" />
              <span className="inline ml-4 font-semibold">
                {userInfo.phone_number}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={
          <ContactHoursAdd
            closeModal={closeModal}
            hours={hours}
            fetchData={fetchData}
          />
        }
        dialogClassName="modal-width-profile"
      />
    </div>
  );
}

export default ContactHours;
