import React, { useState } from "react";
import axios from "axios";
import Spinner from "./spinner";
import { toastSuccess, toastError } from "../hooks/toaster";
import Select from "react-select";
import timeOptions from "./timeOptions";

function HoursAdd({ closeModal, fetchData }) {
  const [selectedOption, setSelectedOption] = useState("basic");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [errorTime, setErrorTime] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [formData, setFormData] = useState({
    capacity: 5,
    startTime: "",
    endTime: "",
  });
  const [formDataAdv, setFormDataAdv] = useState({
    capacity: 5,
    startTime: "",
    endTime: "",
    slot_day: "Monday",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e);
  };
  const dayArr = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
    // Add more options as needed
  ];
  // const dayArr = [
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  //   "Sunday",
  // ];

  const handleStartTimeChange = (e) => {
    if (e.target.name === "start") {
      setStartTime(e.target.value);
    } else {
      //   if (e.target.value < startTime) {
      //     setErrorTime("End time is less than start time");
      //   } else {
      setEndTime(e.target.value);
      //}
    }
  };

  const handleStartTimeChangeAdv = (e) => {
    const { name, value } = e.target;
    setFormDataAdv({ ...formDataAdv, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.capacity) {
      errors.capacity = "Capacity is required";
    }
    if (selectedOption === "basic") {
      if (!startTime.trim()) {
        errors.startTime = "Start time is required";
      }
      if (!endTime.trim()) {
        errors.endTime = "End time is required";
      }
    } else {
      if (!formDataAdv.startTime.trim()) {
        errors.startTime = "Start time is required";
      }
      if (!formDataAdv.endTime.trim()) {
        errors.endTime = "End time is required";
      }
      if (selectedOptions.length === 0) {
        errors.days = "Day is required";
      }
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setLoading(true);
      formData["user_id"] = userInfo.user_id;
      formData["type"] = selectedOption;
      formDataAdv["user_id"] = userInfo.user_id;
      formDataAdv["type"] = selectedOption;

      let formDataSub = "";
      if (selectedOption === "basic") {
        formDataSub = formData;
        formDataSub["time"] = startTime + " - " + endTime;
      } else {
        let advc = [];
        selectedOptions.forEach((adv) => {
          advc.push(adv.value);
        });
        formDataAdv["slot_day_array"] = advc;
        formDataAdv["time"] =
          formDataAdv.startTime + " - " + formDataAdv.endTime;
        formDataSub = formDataAdv;
      }

      //if (selectedOption === "basic") {
      try {
        await axios
          .post(API_URL + "slot/add-slot", formDataSub, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            toastSuccess("Time Slot updated successfully");
            setLoading(false);
            fetchData();
            closeModal();
          })
          .catch(function (error) {
            toastError(error.response.data.message || "Time Slot not updated");
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
      }
      //}
    }
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          Add Time Slots
        </div>
        <div className="">
          <div className="">
            <div class="relative inline-block flex items-center group">
              <span className="text-lg">Type</span>
              <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <div className="">
                <div class="absolute  left-1 flex items-center hidden ml-14 group-hover:flex">
                  <div class=" bg-black"></div>
                  <span class="relative max-w-[90%] z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg">
                    There are 2 types here. If you select basic, then it will be
                    same time for all days. but, if you select advance, then
                    there will be feature to add different time slots for
                    different days. Booking capacity is how many bookings can be
                    done at one single time slot.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 grid grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 xxs:grid-cols-1">
            <label className="text-xl">
              <input
                className="mr-1"
                type="radio"
                value="basic"
                checked={selectedOption === "basic"}
                onChange={(e) => handleOptionChange(e.target.value)}
              />
              Basic
            </label>
            <label className="text-xl">
              <input
                className="mr-1"
                type="radio"
                value="advance"
                checked={selectedOption === "advance"}
                onChange={(e) => handleOptionChange(e.target.value)}
              />
              Advance
            </label>
          </div>

          <div className="text-[12px] text-grey3 mt-3">
            Note : Add 1-1 hours available time slot and except your break
            hours. i.e 10:00 AM to 11:00 AM, 11:00 AM to 12:00 PM
          </div>

          <div className="">
            <form onSubmit={handleSubmit}>
              {selectedOption === "basic" ? (
                <>
                  <div className="mt-3 text-2xl">Select Time : </div>
                  <div className="mt-3 grid grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 xxs:grid-cols-1">
                    <div className="">
                      <label htmlFor="startTime" className="text-lg">
                        Start Time :
                      </label>
                      <select
                        name="start"
                        className="ml-2 p-2 border border-grey-9 rounded"
                        id="startTime"
                        value={startTime}
                        onChange={handleStartTimeChange}
                      >
                        {timeOptions.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="">
                      <label htmlFor="endTime" className="text-lg">
                        End Time :
                      </label>
                      <select
                        name="end"
                        className="ml-2 p-2 border border-grey-9 rounded"
                        id="endTime"
                        value={endTime}
                        onChange={handleStartTimeChange}
                      >
                        {timeOptions.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errorTime && (
                      <p style={{ color: "red", marginTop: "10px" }}>
                        {errorTime}
                      </p>
                    )}
                    {errors.startTime && (
                      <span className="text-red text-xs">
                        {errors.startTime}
                      </span>
                    )}
                    {errors.endTime && (
                      <span className="text-red text-xs">{errors.endTime}</span>
                    )}
                  </div>

                  <label className="text-black text-base w-full mt-3">
                    Booking Capacity :
                  </label>
                  <input
                    type="number"
                    className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
                    placeholder="Black"
                    name="capacity"
                    value={formData.capacity}
                    onChange={handleChange}
                  />
                  {errors.capacity && (
                    <span className="text-red text-xs">{errors.capacity}</span>
                  )}
                </>
              ) : (
                <>
                  <div className="mt-3 text-2xl">Select Time : </div>
                  <div className="mt-3 grid grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 xs:grid-cols-1 xxs:grid-cols-1">
                    <div className="">
                      <label htmlFor="startTime" className="text-lg">
                        Day :
                      </label>
                      <Select
                        className="ml-2 inline-block w-[70%] "
                        isMulti
                        options={dayArr}
                        value={selectedOptions}
                        onChange={handleSelectChange}
                      />
                      {/* <select
                        name="slot_day"
                        className="ml-2 p-2"
                        id="startTime"
                        value={formDataAdv.slot_day}
                        onChange={handleStartTimeChangeAdv}
                        multiple
                      >
                        {dayArr.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))}
                      </select> */}
                      {errors.days && (
                        <span className="text-red text-xs block">
                          {errors.days}
                        </span>
                      )}
                    </div>

                    <div className="">
                      <label htmlFor="startTime" className="text-lg">
                        Start Time :
                      </label>
                      <select
                        name="startTime"
                        className="ml-2 p-2 border border-grey-9 rounded"
                        id="startTime"
                        value={formDataAdv.startTime}
                        onChange={handleStartTimeChangeAdv}
                      >
                        {timeOptions.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                      {errors.startTime && (
                        <span className="text-red text-xs block">
                          {errors.startTime}
                        </span>
                      )}
                    </div>

                    <div className="">
                      <div className="">
                        <label htmlFor="endTime" className="text-lg">
                          End Time :
                        </label>
                        <select
                          name="endTime"
                          className="ml-2 p-2 border border-grey-9 rounded"
                          id="endTime"
                          value={formDataAdv.endTime}
                          onChange={handleStartTimeChangeAdv}
                        >
                          {timeOptions.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                        {errors.endTime && (
                          <span className="text-red text-xs block">
                            {errors.endTime}
                          </span>
                        )}
                      </div>
                    </div>
                    {errorTime && (
                      <p
                        style={{
                          display: "block",
                          color: "red",
                          marginTop: "10px",
                        }}
                      >
                        {errorTime}
                      </p>
                    )}
                  </div>

                  <label className="text-black text-base w-full mt-3">
                    Booking Capacity :
                  </label>
                  <input
                    type="number"
                    className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
                    placeholder="5"
                    name="capacity"
                    value={formDataAdv.capacity}
                    onChange={handleStartTimeChangeAdv}
                  />
                  {errors.capacity && (
                    <span className="text-red text-xs">{errors.capacity}</span>
                  )}
                </>
              )}
              <div className="float-right mt-4">
                {loading ? (
                  <Spinner /> // Show the spinner while loading
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() => closeModal()}
                      className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                    >
                      Cancel
                    </button>
                    <input
                      type="submit"
                      value="Update"
                      name="Update"
                      className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                    />
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoursAdd;
