import React, { useState, useEffect } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Model from "./model";
import axios from "axios";
import Spinner from "./spinner";
import AddWorkIMg from "./addWorkImg";

const ProfessionalWork = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [workImg, setWorkImg] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoadingInit(true);
    try {
      const response = await axios.get(
        API_URL + "user/get-all-professional-images/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setWorkImg(response.data);
        setLoadingInit(false);
      }
    } catch (error) {
      setLoadingInit(false);
      console.error("Error fetching data:", error);
    }
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <div className="px-4 pt-4 pb-4 block ">
      <div className="border-b border-grey9 pb-4">
        <div className="inline-block font-semibold ">Our Work</div>
        <div className="inline-block float-right">
          <button onClick={() => openModal()}>
            <img alt="user" src="img/edit.png" />
          </button>
        </div>
      </div>
      {!loadingInit ? (
        <div>
          {workImg && workImg.length > 0 ? (
            <div className="gap-4 grid lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-5 xs:grid-cols-3 xxs:grid-cols-3 max-h-[300px] overflow-y-scroll">
              {workImg.map((img) => {
                return (
                  <img
                    alt="workImg"
                    src={img.image_urls ? img.image_urls : "img/img2.png"}
                    className={` ${
                      matches ? "h-[90px]" : "h-[117px]"
                    } mt-3  w-[117px]`}
                  />
                );
              })}
            </div>
          ) : (
            <div className="text-center font-bold mt-3">
              No Images are added yet.
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={<AddWorkIMg closeModal={closeModal} />}
        dialogClassName="modal-width-profile"
      />
    </div>
  );
};

export default ProfessionalWork;
