import React, { useState, useEffect } from "react";
import Spinner from "./spinner";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";
import { UseMediaQuery } from "../hooks/useMediaQuery";

const CancellationModal = ({ closeModal, cancellationText }) => {
  const matches = UseMediaQuery("(max-width: 992px)");
  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          Cancellation Policy
        </div>
      </div>
      {cancellationText ? (
        <div
          className="mb-3"
          style={{
            whiteSpace: "pre-line",
            wordBreak: "break-all",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: cancellationText,
            }}
          />{" "}
          {/* {post.caption}{" "} */}
        </div>
      ) : (
        <div className={` mt-3`}>
          <div className="font-bold">Cancellation policy</div>
          <div className={` ${matches ? "text-base" : "text-lg"} `}>
            At Pelito, The Hair Network, we value the time and commitment of
            both our professionals and enthusiasts. To ensure a smooth and
            efficient experience for everyone using our platform, we have
            established the following appointment cancellation policy:
          </div>
          <div className="mt-2 font-bold">
            1. Cancellation by Professionals:
          </div>
          <li>
            Professionals have the option to cancel appointments if necessary,
            but we encourage them to do so only in unavoidable circumstances.
          </li>
          <li>
            In the event of a cancellation by the professional, they are
            responsible for notifying the enthusiast promptly and offering
            alternative options or rescheduling the appointment.
          </li>
          <div className="mt-2 font-bold">2. Cancellation by Enthusiasts:</div>
          <li>
            Enthusiasts are expected to honor their scheduled appointments.
            However, if cancellation is unavoidable, we request that enthusiasts
            notify the professional as soon as possible.
          </li>
          <li>
            Enthusiasts may be subject to a cancellation fee if appointments are
            canceled within a specified timeframe before the scheduled
            appointment. This fee is intended to compensate the professional for
            their time and potential loss of revenue.
          </li>
          <div className="mt-2 font-bold">3 Cancellation Fees:</div>
          <li>
            Any cancellation fees incurred will be clearly communicated to the
            enthusiast during the booking process.
          </li>
          <li>
            The amount of the cancellation fee may vary depending on the timing
            of the cancellation and the policies set by the professional.
          </li>
          <div className="mt-2 font-bold">4 Refunds:</div>
          <li>
            Pelito, The Hair Network, does not facilitate refunds for canceled
            appointments. Any refund requests should be directed to the
            professional with whom the appointment was booked.
          </li>
          <div className="mt-2 font-bold">5 Exceptions:</div>
          <li>
            We understand that unforeseen circumstances may arise, and
            exceptions to the cancellation policy may be considered on a
            case-by-case basis.
          </li>
          <li>
            Enthusiasts are encouraged to communicate with professionals
            directly to discuss any extenuating circumstances that may warrant a
            cancellation or rescheduling.
          </li>
          <div className="mt-2 font-bold">6 Feedback and Support:</div>
          <li>
            If you encounter any issues or have questions regarding our
            appointment cancellation policy, please don’t hesitate to reach out
            to our customer support team. We are here to assist you and ensure a
            positive experience for all users.
          </li>
          <div className="mt-2">
            By adhering to these guidelines, we aim to foster a respectful and
            cooperative environment where professionals and enthusiasts can
            collaborate effectively and enjoy the benefits of Pelito’s services.
            Thank you for your cooperation and understanding.
          </div>
        </div>
      )}
    </div>
  );
};

export default CancellationModal;
