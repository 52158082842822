import React, { useState, useEffect } from "react";
import Spinner from "./spinner";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { replaceLinks } from "../hooks/commonFunctions";

const AddInviteUsers = ({ closeModal }) => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const API_URL = process.env.REACT_APP_API_URL;
  const FRONT_URL = process.env.REACT_APP_FRONT_URL;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("userToken");
  const [formData, setFormData] = useState({
    email_array: "",
    subject: "",
    body: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [textareaHeight, setTextAreaHeight] = useState("auto");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeBody = (event) => {
    const { value } = event.target;
    const words = value.trim().split(/\s+/);
    setFormData({ ...formData, body: value });

    // Calculate the height of the textarea based on its content
    const lineHeight = 18; // Adjust this value according to your font size
    const rows = value.split("\n").length;
    setTextAreaHeight(`${lineHeight * rows}px`);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.email_array && !formData.email_array.trim()) {
      errors.email_array = "Recipient is required";
    }

    if (!formData.subject.trim()) {
      errors.subject = "Subject is required";
    }

    if (!formData.body.trim()) {
      errors.body = "Body is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid) {
      setLoading(true);
      formData["seller_user_id"] = userInfo.user_id;
      formData["body_html"] = replaceLinks(formData["body"]);
      formData["email_array"] = formData["email_array"]
        .split(",")
        .map((email) => email.trim());

      await axios
        .post(API_URL + "user/invite", formData, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          setLoading(false);
          toastSuccess("User Invited successfully");
          closeModal();
        })
        .catch(function (error) {
          toastError(error.response.data.message || "User not invited");
          console.log(error);
          setLoading(false);
        });
    }
  };

  const copyToClipboard = (text) => {
    const textToCopy = text.replace(/<a\b[^>]*>(.*?)<\/a>/g, "$1");
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toastSuccess("Text Copied");
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
        alert("Failed to copy text to clipboard.");
      });
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div class="">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          Invite User
          {userInfo && userInfo.profile_name ? (
            <button
              onClick={() => copyToClipboard(FRONT_URL + userInfo.profile_name)}
              className="ml-2 font-normal text-xs p-2 rounded border border-grey9 bg-white"
            >
              Copy Virtual Profile Link
            </button>
          ) : (
            ""
          )}
        </div>

        <form onSubmit={handleSubmit}>
          <label className="text-grey3 text-base w-full mt-3">
            Recipient (Add all recipient in comma separated)
          </label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Recipient "
            name="email_array"
            value={formData.email_array}
            onChange={handleChange}
          />
          {errors.email_array && (
            <span className="text-red text-xs">{errors.email_array}</span>
          )}

          <label className="text-grey3 text-base w-full mt-3">Subject</label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />
          {errors.subject && (
            <span className="text-red text-xs">{errors.subject}</span>
          )}

          <label className="text-grey3 text-base w-full mt-3">Body</label>
          <textarea
            style={{
              height: textareaHeight,
              minHeight: "65px",
            }}
            value={formData.body}
            onChange={handleChangeBody}
            className="w-full  p-2 inline bg-white border border-grey9 rounded"
            placeholder="Body"
          />
          {errors.body && (
            <span className="text-red text-xs">{errors.body}</span>
          )}

          <div className=" mt-4">
            {loading ? (
              <Spinner /> // Show the spinner while loading
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                >
                  Cancel
                </button>
                <input
                  type="submit"
                  value="Invite"
                  name="Update"
                  className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                />
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddInviteUsers;
