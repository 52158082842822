import React, { useState } from "react";
import axios from "axios";
import Spinner from "./spinner";
import { toastSuccess, toastError } from "../hooks/toaster";

const SendEmailToCustomer = ({ closeModal, closeUpdateModal }) => {
  const [formData, setFormData] = useState({
    subject: "",
    body: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [textareaHeight, setTextAreaHeight] = useState("auto");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeBody = (event) => {
    const { value } = event.target;
    const words = value.trim().split(/\s+/);
    if (words.length <= 200) {
      setFormData({ ...formData, body: value });

      // Calculate the height of the textarea based on its content
      const lineHeight = 18; // Adjust this value according to your font size
      const rows = value.split("\n").length;
      setTextAreaHeight(`${lineHeight * rows}px`);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.subject.trim()) {
      errors.subject = "Subject is required";
    }

    if (!formData.body.trim()) {
      errors.body = "Body is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
    }
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          Send Email
        </div>
        <form onSubmit={handleSubmit}>
          <label className="text-grey3 text-base w-full mt-3">Subject</label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />
          {errors.subject && (
            <span className="text-red text-xs">{errors.subject}</span>
          )}

          <label className="text-grey3 text-base w-full mt-3">Body</label>
          <textarea
            style={{
              height: textareaHeight,
              minHeight: "65px",
            }}
            value={formData.body}
            onChange={handleChangeBody}
            className="w-full  p-2 inline bg-white border border-grey9 rounded"
            placeholder="Body"
          />
          {/* <input
            type="text"
            className=" border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Body"
            name="body"
            value={formData.body}
            onChange={handleChange}
          /> */}
          {errors.body && (
            <span className="text-red text-xs">{errors.body}</span>
          )}

          <div className="float-right mt-4">
            {loading ? (
              <Spinner /> // Show the spinner while loading
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                >
                  Cancel
                </button>
                <input
                  type="submit"
                  value="Send"
                  name="Update"
                  className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                />
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendEmailToCustomer;
