import React, { useState, useEffect } from "react";

const TutorialProff = () => {
  return (
    <div>
      <div className="mt-5">
        <span className="font-bold ">
          Step 1 : Please click on the edit profile to complete your profile
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[800px] md:max-w-[650px] sm:max-w-[500px] xs:max-w-[400px] xxs:max-w-[300px]"
          src="img/tutorial/img1.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 2 : Please click on the edit icon to update basic details
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[500px] md:max-w-[400px] sm:max-w-[300px] xs:max-w-[350px] xxs:max-w-[300px]"
          src="img/tutorial/img2.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 2.1 : Clicking on the edit icon, popup will be shown up and there
          you will need to update your basic details like name, phone number,
          email.
        </span>
        <span className="block font-normal text-grey3 text-sm">
          Note: Business name is the name that will be used to create your
          virtual profile. for example, https://pelito.net/michaelTorres will be
          your virtual profile.{" "}
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-h-[500px] md:max-h-[450px] sm:max-h-[400px] xs:max-h-[350px] xxs:max-h-[300px]"
          src="img/tutorial/img2.1.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 2.2 : Next is to upload cover photo. When you click on the edit
          icon next to title "Cover Photo", pop up will be open and you will be
          able to upload cover photo. This cover photo will be displayed in your
          Profile.
        </span>
        <span className="block font-normal text-grey3 text-sm">
          Note: Upload photo as suggested size.
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[400px] md:max-w-[300px] sm:max-w-[350px] xs:max-w-[300px] xxs:max-w-[250px]"
          src="img/tutorial/img2.2.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 2.3 : Next is to upload work images. When you click on the edit
          icon next to title "Our Work", pop up will be open and you will be
          able to upload all your work images. This images will be displayed in
          your Profile.
        </span>
        <span className="block font-normal text-grey3 text-sm">
          Note: After uploading images you can index them in order you want to
          display in your profile. And you can give caption along with the index
          to be displayed in your profile.
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[500px] md:max-w-[400px] sm:max-w-[350px] xs:max-w-[300px] xxs:max-w-[250px]"
          src="img/tutorial/img2.3.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 2.4 : Next is to add services. You can add your all services with
          name, description, price and time period
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[500px] md:max-w-[400px] sm:max-w-[300px] xs:max-w-[350px] xxs:max-w-[300px]"
          src="img/tutorial/img2.4.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 2.5 : Next is to add products which you want to sell. You can add
          all your products with name, description, price and images
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[500px] md:max-w-[400px] sm:max-w-[300px] xs:max-w-[350px] xxs:max-w-[300px]"
          src="img/tutorial/img2.5.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 2.6 : Next is to add members working in your salon. You can add
          their basic details like name, phone number, photo
        </span>
        <span className="block font-normal text-grey3 text-sm">
          Note: You can add member's capacity to handle number of clients at one
          time, that will help to book multiple appointments on single time.
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[500px] md:max-w-[400px] sm:max-w-[300px] xs:max-w-[350px] xxs:max-w-[300px]"
          src="img/tutorial/img2.6.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 2.6.1 : Next is to set each member's regular shifts. This is to
          identify their regular work timings.
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[500px] md:max-w-[400px] sm:max-w-[300px] xs:max-w-[350px] xxs:max-w-[300px]"
          src="img/tutorial/img2.6.1.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 2.6.2 : You can update their regular start and end time from
          here.
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[500px] md:max-w-[400px] sm:max-w-[300px] xs:max-w-[350px] xxs:max-w-[300px]"
          src="img/tutorial/img2.6.2.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 2.6.3 : Next is to set each member's custom shifts. If you want
          to change start and end time of any particular day, you can set from
          here. Or you want to add any break time for any particular day, then
          you can add from here.
        </span>
        <span className="block font-normal text-grey3 text-sm">
          Note: If Jimmy wants to come on 14th Feb from 9:00 AM to 4:00 PM then
          you can set from here. It will applicable for only 14th Feb.
          <br />
          If Jimmy wants break on 15th Feb from 3:00 PM to 3:30 PM, then you can
          add multiple breaks from here.
          <br /> This off times will not be shown to enthusiast for booking an
          appointment.
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[500px] md:max-w-[400px] sm:max-w-[300px] xs:max-w-[350px] xxs:max-w-[300px]"
          src="img/tutorial/img2.6.3.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 3 : You can check your member's appointments from here.
        </span>
        <img
          alt="tutorial"
          className="mt-2 border border-grey9 rounded lg:max-w-[750px] md:max-w-[700px] sm:max-w-[600px] xs:max-w-[370px] xxs:max-w-[320px]"
          src="img/tutorial/img3.PNG"
        />
      </div>

      <div className="mt-5">
        <span className="font-bold ">
          Step 4 : You can get onboard for your Stripe Account to get easily
          paid from here.
        </span>
        <div className="block">
          Click on edit profile and you will see a button to generate a link for
          getting onboard. After you click on that button, you will be
          redirected to stripe page into a new tab and you need to complete that
          page to get onboarded.
          <img
            alt="tutorial"
            className="mt-2 border border-grey9 rounded lg:max-w-[750px] md:max-w-[700px] sm:max-w-[600px] xs:max-w-[370px] xxs:max-w-[320px]"
            src="img/tutorial/img9.PNG"
          />
        </div>
      </div>
    </div>
  );
};

export default TutorialProff;
