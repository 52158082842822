import React, { useState, useEffect } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { toastSuccess, toastError } from "../hooks/toaster";
import OuterMenu from "./outerMenu";
import OuterFooter from "./outerFooter";
import Spinner from "./spinner";
import axios from "axios";
import Footer from "./mobileFooter";

function Wishlist() {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const matches = UseMediaQuery("(max-width: 992px)");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(false);
  const [wishList, setWishList] = useState();
  const [wishId, setWishId] = useState();
  const [wishlistProductData, setWishlistProductData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "wishlist/by-userid/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setWishlistProductData(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleWishlist = async (id) => {
    setWishId(id);
    let formData = {};
    const index = wishlistProductData.findIndex((user) => user._id === id);
    if (index >= 0) {
      await axios
        .delete(API_URL + "wishlist/" + wishlistProductData[index]._id, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          setWishId("");
          setLoading(false);
          toastSuccess("Wishlist deleted successfully");
          fetchData();
        })
        .catch(function (error) {
          setWishId("");
          toastError(
            error.response ? error.response.data.message : "" || "Not deleted"
          );
          console.log(error);
          setLoading(false);
        });
    } else {
      formData["customer_user_id"] = userInfo.user_id;
      //formData["seller_user_id"] = userId;
      formData["product_id"] = id;
      await axios
        .post(API_URL + "wishlist", formData, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          setWishId("");
          setLoading(false);
          toastSuccess("Wishlisted successfully");
          fetchData();
        })
        .catch(function (error) {
          setWishId("");
          toastError(
            error.response
              ? error.response.data.message
              : "" || "Not wishlisted"
          );
          console.log(error);
          setLoading(false);
        });
    }
  };

  const description = (text) => {
    const words = text.split(" ");

    // Display only the first 5 words
    const truncatedText = words.slice(0, 4).join(" ");
    return words.length > 5 ? `${truncatedText} ...` : truncatedText;
  };

  const fetchWishlist = (id) => {
    if (wishlistProductData) {
      const index = wishlistProductData.findIndex((user) => user._id === id);
      return index;
    }
    return -1;
  };

  return (
    <div className="">
      <div className=" mb-5">
        <div className="mt-3 border-b border-grey2 pb-2">
          <div className="pb-2">
            <OuterMenu page="other" cartClr="black" />
          </div>
        </div>
        <div className="mt-12 xl:px-44 lg:px-44 lg3:px-36 lg2:px-36 md:px-28 sm:px-20 xs:px-10 xxs:px-4">
          <div className="font-bold text-3xl">Wishlist Products</div>
          {wishlistProductData && wishlistProductData.length > 0 ? (
            <div className="mt-5 grid grid-cols-6 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-4 xs:grid-cols-3 xxs:grid-cols-2 gap-2">
              {wishlistProductData.map((product) => {
                return (
                  <div className="border border-grey-9 rounded p-2">
                    <div className="relative">
                      {product.product_id.img_urls &&
                      product.product_id.img_urls.length > 0 ? (
                        <>
                          <img
                            src={product.product_id.img_urls[0]}
                            alt="all product"
                            className={` ${
                              matches
                                ? "max-w-[135px] max-h-[160px] "
                                : "max-w-[170px] max-h-[180px]"
                            } rounded`}
                          />
                        </>
                      ) : (
                        <img
                          src="img/noImg.jpg"
                          className={` ${
                            matches
                              ? "max-w-[135px] max-h-[160px] "
                              : "max-w-[170px] max-h-[180px]"
                          } rounded`}
                        />
                      )}
                    </div>
                    <div className="block mt-2">
                      <div className=" inline-block text-black2 text-sm">
                        {product.product_id.name}
                      </div>
                      <div className="inline-block float-right text-green1 font-bold">
                        ${product.product_id.price}
                      </div>
                    </div>

                    <div className="text-[10px]">
                      {product.product_id.description
                        ? description(product.product_id.description)
                        : ""}
                    </div>

                    <div className="text-[8px] mt-1 text-black0">
                      <img
                        alt="user"
                        src="img/rating.png"
                        className="inline mr-1 h-[10px]"
                      />
                      5.0 (1000)
                    </div>

                    <div className="mt-3 text-[9px]">
                      <div className="grid gap-2 grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 xxs:grid-cols-2 ">
                        <>
                          <button className="p-2 bg-green1 text-white rounded">
                            Add to bag
                          </button>
                          {wishId === product.product_id._id ? (
                            <Spinner />
                          ) : (
                            <button
                              onClick={() => handleWishlist(product._id)}
                              className={` ${
                                fetchWishlist(product._id) >= 0
                                  ? "font-bold bg-orange1"
                                  : ""
                              } p-2 bg-grey11 rounded`}
                            >
                              {fetchWishlist(product._id) >= 0
                                ? "Remove "
                                : "Wishlist"}
                            </button>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-center font-bold mt-5">
              No Products Wishlisted
            </div>
          )}
        </div>

        <div className="mt-5">
          <OuterFooter />
        </div>
        {matches ? <Footer /> : ""}
      </div>
    </div>
  );
}

export default Wishlist;
