import React, { useState, useEffect, useRef } from "react";
import Spinner from "./spinner";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";
import { UseMediaQuery } from "../hooks/useMediaQuery";

const AddSubCategory = ({ category, closeModal, subCategoryId }) => {
  const inputRef = useRef();
  const matches = UseMediaQuery("(max-width: 992px)");
  const API_URL = process.env.REACT_APP_API_URL;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [formData, setFormData] = useState({
    category_id: category[0]._id,
    name: "",
    image: "",
  });
  const [errors, setErrors] = useState({});
  const [loadingImg, setLoadingImg] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (subCategoryId) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setLoadingInit(true);
    try {
      const response = await axios.get(
        API_URL + "interest/sub-category/" + subCategoryId,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        let category = {};
        category["name"] = response.data.name ? response.data.name : "";
        category["category_id"] = response.data.category_id
          ? response.data.category_id
          : "";
        category["image"] = response.data.image ? response.data.image : "";
        setFormData(category);
        setLoadingInit(false);
      }
    } catch (error) {
      setLoadingInit(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleImg = async () => {
    const files = inputRef.current.files;

    if (files.length > 0) {
      setLoadingImg(true);

      const formData1 = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData1.append("media", files[i]);
      }

      await axios
        .post(API_URL + "upload/single-upload", formData1, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          setFormData({
            ...formData,
            image: response.data.media,
          });
          setLoadingImg(false);
          setCount(count + 1);
        })
        .catch(function (error) {
          setLoadingImg(false);
          console.log(error);
        });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setLoading(true);
      try {
        if (subCategoryId) {
          await axios
            .put(API_URL + "interest/sub-category/" + subCategoryId, formData, {
              headers: { authorization: "Bearer " + token },
            })
            .then(function (response) {
              setLoading(false);
              toastSuccess("Category updated successfully");
              closeModal();
            })
            .catch(function (error) {
              toastError(error.response.data.message || "Category not updated");
              console.log(error);
              setLoading(false);
            });
        } else {
          await axios
            .post(API_URL + "interest/sub-category", formData, {
              headers: { authorization: "Bearer " + token },
            })
            .then(function (response) {
              setLoading(false);
              toastSuccess("Category added successfully");
              closeModal();
            })
            .catch(function (error) {
              toastError(error.response.data.message || "Category not added");
              console.log(error);
              setLoading(false);
            });
        }
      } catch (error) {
        setLoading(false);
        toastError(error || "Category not added");
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          {subCategoryId ? "Update" : "Add"} Sub Category
        </div>
        {!loadingInit ? (
          <form onSubmit={handleSubmit}>
            <label className="text-grey3 text-base w-full">Category</label>
            <select
              name="category_id"
              className="p-2 border border-grey-9 rounded"
              id="breakStartTime"
              value={formData.category_id}
              onChange={handleChange}
            >
              {category.map((cat, index) => (
                <option key={index} value={cat._id}>
                  {cat.name}
                </option>
              ))}
            </select>

            <label className="text-grey3 text-base w-full mt-2">Name</label>
            <input
              type="text"
              className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
              placeholder="Sub Category Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && (
              <span className="text-red text-xs">{errors.name}</span>
            )}

            <div className="file-container addWork mt-2">
              <label
                htmlFor="fileInput"
                className="text-green1 font-semibold opacity-80 cursor-pointer text-lg"
              >
                + Upload Image
              </label>
              <input
                type="file"
                accept="image/*"
                id="fileInput"
                ref={inputRef}
                onChange={() => handleImg()}
                className="file-input"
              />
            </div>
            {formData["image"] ? (
              loadingImg ? (
                <Spinner />
              ) : (
                <img
                  src={formData["image"]}
                  alt="user profile"
                  className="h-[150px] mt-1"
                />
              )
            ) : loadingImg ? (
              <Spinner />
            ) : (
              ""
            )}

            <div className="float-right mt-4">
              {loading ? (
                <Spinner /> // Show the spinner while loading
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => closeModal()}
                    className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                  >
                    Cancel
                  </button>

                  <input
                    type="submit"
                    value={subCategoryId ? "Update" : "Add"}
                    name="Add"
                    className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                  />
                </>
              )}
            </div>
          </form>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default AddSubCategory;
