import React from "react";

const Logo = () => {
  return (
    <>
      <img alt="logo" src="img/logo.png" className="mx-auto" />
    </>
  );
};

export default Logo;
