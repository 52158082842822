import React, { useState } from "react";
import Menu from "./menu";
import Header from "./header";
import Footer from "./mobileFooter";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { useSnapCarousel } from "react-snap-carousel";
import Spinner from "./spinner";
import { toastSuccess, toastError } from "../hooks/toaster";
import axios from "axios";

const Tax = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const { scrollRef, pages, activePageIndex, next, prev, goTo } =
    useSnapCarousel();
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const [formDataAll, setFormDataAll] = useState({
    name: "",
    subject_type: "",
    msg: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataAll({ ...formDataAll, [name]: value });
  };

  const validateForm = () => {
    const errors = {};

    if (!formDataAll.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formDataAll.msg.trim()) {
      errors.msg = "Message is required";
    }

    if (!formDataAll.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formDataAll.email)) {
      errors.email = "Invalid email address";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setLoading(true);

      try {
        await axios
          .post(API_URL + "user/inquiry-email", formDataAll, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            setLoading(false);
            let data = { name: "", subject_type: "", msg: "", email: "" };
            setFormDataAll(data);
            toastSuccess("Inquiry Sent successfully");
          })
          .catch(function (error) {
            toastError(error.response.data.message || "Inquiry not Sent");
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        toastError(error || "Inquiry not Sent");
        console.error("Error:", error);
      }
    }
  };

  return (
    <div>
      <div className="bg-white ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div className={`mb-10 min-h-screen `}>
              <div className="">
                <img
                  src="img/tax1.png"
                  className={` w-full lg:h-[550px] md:h-[500px] sm:h-[400px] xs:h-[300px] xxs:h-[200px] `}
                />
              </div>
              <div className="">
                <img
                  src="img/tax2.png"
                  className={`mx-auto ${
                    matches ? "max-w-[350px] mt-8" : "max-w-[750px] mt-16"
                  }  `}
                />
              </div>
              <div className="lg:mt-12 md:mt-10 sm:mt-10 xs:mt-10 xxs:mt-6 lg:px-28 md:px-20 sm:px-20 xs:px-6 xxs:px-4">
                <div
                  className={` grid grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 xxs:grid-cols-1 ${
                    matches ? "gap-3" : "gap-5"
                  }`}
                >
                  <img src="img/tax3.png" />
                  <img src="img/tax4.png" />
                </div>

                <div
                  className={`p-3 border border-grey9 bg-grey5 inline-block rounded ${
                    matches ? "mt-4" : "mt-5"
                  }`}
                >
                  <div className={` ${matches ? "text-sm" : "text-base"}`}>
                    Inquiry Form
                  </div>
                  <div
                    className={` ${
                      matches ? "text-[10px]" : "text-sm"
                    } text-grey13 border-b border-grey9 pb-2`}
                  >
                    Our experts are here to assist you with tax and HR
                    questions. Enter your contact info and questions below to
                    get started! <br /> <br />
                    <div
                      className={` text-black0 ${
                        matches ? "text-sm" : "text-base"
                      }`}
                    >
                      Example Questions:
                    </div>
                    1. What tax deductions are available for hairstylists?
                    <br />
                    2. How do I handle payroll taxes for my salon employees?
                    <br />
                    3. What are the compliance requirements for salon owners?
                    <br />
                    4. Can you provide guidance on retirement planning options?
                    <br />
                    5. What employee benefits should I offer in my salon?
                    <br />
                    6. How can I navigate tax implications when expanding my
                    business?
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="mt-2 gap-4 grid grid-cols-2 lg:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 xxs:grid-cols-1">
                      <div className="">
                        <label className="text-grey3 text-base w-full mt-2">
                          Name
                        </label>
                        <input
                          type="text"
                          className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
                          placeholder="Enter your name"
                          name="name"
                          value={formDataAll.name}
                          onChange={handleChange}
                        />
                        {errors.name && (
                          <span className="text-red text-xs">
                            {errors.name}
                          </span>
                        )}

                        <label className="text-grey3 text-base w-full mt-2">
                          Subject
                        </label>
                        <select
                          onChange={handleChange}
                          name="subject_type"
                          className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
                        >
                          <option>tax</option>
                          <option>HR</option>
                        </select>
                        {/* <input
                          type="text"
                          className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
                          placeholder="Subj"
                          name="subject"
                          value={formDataAll.name}
                          onChange={handleChange}
                        /> */}
                        {errors.subject_type && (
                          <span className="text-red text-xs">
                            {errors.subject_type}
                          </span>
                        )}
                      </div>
                      <div className="">
                        <label className="text-grey3 text-base w-full mt-2">
                          Email
                        </label>
                        <input
                          type="text"
                          className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
                          placeholder="Enter your name"
                          name="email"
                          value={formDataAll.email}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <span className="text-red text-xs">
                            {errors.email}
                          </span>
                        )}

                        <label className="text-grey3 text-base w-full mt-2">
                          Your Message
                        </label>
                        <input
                          type="text"
                          className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
                          placeholder="Enter your message"
                          name="msg"
                          value={formDataAll.msg}
                          onChange={handleChange}
                        />
                        {errors.msg && (
                          <span className="text-red text-xs">{errors.msg}</span>
                        )}
                      </div>
                    </div>

                    <div className="float-right mt-4">
                      {loading ? (
                        <Spinner />
                      ) : (
                        <>
                          <input
                            type="submit"
                            value="Submit"
                            name="Add"
                            className="rounded font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                          />
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>

              <div className={` ${matches ? "mt-4" : "mt-5"}`}>
                <img src="img/tax5.png" />
              </div>

              <div className="lg:mt-12 md:mt-10 sm:mt-10 xs:mt-10 xxs:mt-6 lg:px-28 md:px-20 sm:px-20 xs:px-6 xxs:px-4">
                <div className="text-2xl">Downloadable Resources</div>
                <div className="block mt-3">
                  <div className="inline-block ivymode text-black2 lg:text-4xl md:text-3xl sm:text-2xl xs:text-xl xxs:text-sm font-normal">
                    Access tools for simplified tax and HR <br /> management.
                    Download now!
                  </div>
                  <div
                    className={`inline-block ${
                      matches ? "align-top ml-2" : "float-right"
                    }  `}
                  ></div>
                </div>

                <div className={` ${matches ? "mt-4" : "mt-5"}`}>
                  <ul
                    className={` flex overflow-auto feature-carousel ${
                      matches ? "max-w-[300px]" : "max-w-[1000px]"
                    }`}
                    ref={scrollRef}
                    style={{
                      scrollSnapType: "x mandatory",
                    }}
                  >
                    <li
                      className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                        matches ? "max-w-[185px]" : "max-w-[285px]"
                      } justify-center items-center`}
                    >
                      <img
                        src="img/tax6.png"
                        className={` ${
                          matches ? "h-40" : "h-56"
                        } w-auto text-center mt-4 inline rounded`}
                      />
                      <span
                        className={`py-3 w-full ${
                          matches ? "text-sm" : "text-base"
                        } block font-semibold mt-1 `}
                      >
                        Tax Planning Checklist
                        <span
                          className={`text-grey13 font-normal ${
                            matches ? "text-[10px]" : "text-xs"
                          } block mt-1`}
                        >
                          A comprehensive checklist to guide you through the
                          essential steps for effective tax planning. Download
                          and ensure you’re prepared for tax season
                        </span>
                        <a
                          className="text-green1 text-xs"
                          href="pdf/Enhanced_Tax_Planning_Checklist_Barbers_Hairstylists.pdf"
                          download
                        >
                          Download now{" "}
                          <img
                            src="img/Right1.png"
                            className="ml-1 inline-block h-[10px]"
                          />
                        </a>
                      </span>
                    </li>
                    <li
                      className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                        matches ? "max-w-[185px]" : "max-w-[285px]"
                      } justify-center items-center`}
                    >
                      <img
                        src="img/tax7.png"
                        className={` ${
                          matches ? "h-40" : "h-56"
                        } w-auto text-center mt-4 inline rounded`}
                      />
                      <span
                        className={`py-3 w-full ${
                          matches ? "text-sm" : "text-base"
                        } block font-semibold mt-1 `}
                      >
                        Payroll Spreadsheet Template
                        <span
                          className={`text-grey13 font-normal ${
                            matches ? "text-[10px]" : "text-xs"
                          } block mt-1`}
                        >
                          Simplify payroll management with our easy-to-use
                          spreadsheet template. Track employee hours, calculate
                          wages, and stay on top of your payroll
                          responsibilities
                        </span>
                        <a
                          className="text-green1 text-xs"
                          href="pdf/Payroll_spreadsheet.zip"
                          download
                        >
                          Download now{" "}
                          <img
                            src="img/Right1.png"
                            className="ml-1 inline-block h-[10px]"
                          />
                        </a>
                      </span>
                    </li>
                    <li
                      className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                        matches ? "max-w-[185px]" : "max-w-[285px]"
                      } justify-center items-center`}
                    >
                      <img
                        src="img/tax8.png"
                        className={` ${
                          matches ? "h-40" : "h-56"
                        } w-auto text-center mt-4 inline rounded`}
                      />
                      <span
                        className={`py-3 w-full ${
                          matches ? "text-sm" : "text-base"
                        } block font-semibold mt-1 `}
                      >
                        Employee Documentation Guide
                        <span
                          className={`text-grey13 font-normal ${
                            matches ? "text-[10px]" : "text-xs"
                          } block mt-1`}
                        >
                          Understand the key documents needed for effective HR
                          management. Download our guide to ensure your employee
                          documentation is thorough and compliant
                        </span>
                        <a
                          className="text-green1 text-xs"
                          href="pdf/Employee_Documentation_Guide.pdf"
                          download
                        >
                          Download now{" "}
                          <img
                            src="img/Right1.png"
                            className="ml-1 inline-block h-[10px]"
                          />
                        </a>
                      </span>
                    </li>
                    <li
                      className={`relative shrink-0 bg-grey16 rounded-md mr-2 ${
                        matches ? "max-w-[185px]" : "max-w-[285px]"
                      } justify-center items-center`}
                    >
                      <img
                        src="img/tax6.png"
                        className={` ${
                          matches ? "h-40" : "h-56"
                        } w-auto text-center mt-4 inline rounded`}
                      />
                      <span
                        className={`py-3 w-full ${
                          matches ? "text-sm" : "text-base"
                        } block font-semibold mt-1 `}
                      >
                        Business Expenses Tracker
                        <span
                          className={`text-grey13 font-normal ${
                            matches ? "text-[10px]" : "text-xs"
                          } block mt-1`}
                        >
                          Stay organized with our business expenses tracker.
                          Monitor your expenses, categorize deductions, and
                          optimize your financial planning
                        </span>
                        <a
                          className="text-green1 text-xs"
                          href="pdf/Business_Expenses_Tracker.zip"
                          download
                        >
                          Download now{" "}
                          <img
                            src="img/Right1.png"
                            className="ml-1 inline-block h-[10px]"
                          />
                        </a>
                      </span>
                    </li>
                  </ul>

                  <div className={`text-center ${matches ? "mt-1" : "mt-6"}`}>
                    <button className="" onClick={() => prev()}>
                      <img
                        src="img/leftA.png"
                        className={` ${matches ? "h-8" : "h-12"} w-fit ${
                          activePageIndex === 0 ? "cursor-not-allowed" : ""
                        }`}
                      />
                    </button>

                    <button className="" onClick={() => next()}>
                      <img
                        src="img/rightA.png"
                        className={`${matches ? "h-8" : "h-12"} w-fit ${
                          activePageIndex === pages.length - 1
                            ? "cursor-not-allowed"
                            : ""
                        }`}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tax;
