import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Home from "./pages/home";
import "./css/index.css";
import "./css/dashboard.css";
import "./css/mobileFooter.css";
import Interest from "./pages/interest";
import EnthuDashboard from "./pages/enthuDashboard";
import ProfessionalDashboard from "./pages/professionalDashboard";
import UpcomingAppointment from "./pages/upcomingAppointments";
import TopSellingService from "./pages/topSellingService";
import MostFamousProfessionals from "./pages/mostFamousProfessionals";
import Bookings from "./pages/bookings";
import MySalons from "./pages/mySalon";
import ProfessionalProfile from "./pages/professionalProfile";
import EnthusiastProfile from "./pages/enthusiastProfile";
import RatingsProfessional from "./pages/ratingsProfessional";
import TimeSlotProfessional from "./pages/timeSlotProff";
import MobileMenu from "./pages/mobileMenu";
import MobileMenuEnthu from "./pages/mobileMenuEnthu";
import SalonListing from "./pages/salonListing";
import SalonDetail from "./pages/salonDetail";
import MyPost from "./pages/myPost";
import PaymentSummary from "./pages/paymentSummary";
import ProductSummary from "./pages/productSummary";
import Cart from "./pages/cart";
import PayNow from "./pages/payNow";
import Wishlist from "./pages/wishlist";
import Members from "./pages/members";
import ScheduleShift from "./pages/scheduleShift";
import CalendarViewProfessional from "./pages/calendarViewProfessional";
import BookService from "./pages/calendarViewEnthusiast";
import ThankYou from "./pages/thankyou";
import ChangePassword from "./pages/changePassword";
import { checkExpToken } from "./hooks/commonFunctions";
import Stem from "./pages/stem";
import Tax from "./pages/tax";
import AppointmentList from "./pages/appointmentList";
import Tutorial from "./pages/tutorial";
import AddAppointment from "./pages/addAppointment";
import MyPurchasedProduct from "./pages/myPurchasedProduct";
import AdminDashboard from "./pages/adminDashboard";
import AdminProfile from "./pages/adminProfile";
import AdminReport from "./pages/adminReport";
import InviteUsers from "./pages/inviteUsers";
import Category from "./pages/category";
import ProfessionalList from "./pages/professionalList";
import ProfessionalReport from "./pages/professionalReport";

import StarRating from "./pages/location1";
import CalendarExample from "./pages/location2";
import GoogleContactsComponent from "./pages/location3";

function App() {
  const token = localStorage.getItem("userToken");
  if (token) {
    const checkExpiryToken = checkExpToken();
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="interest" element={<Interest />}></Route>
        <Route path="adminDashboard" element={<AdminDashboard />}></Route>
        <Route path="enthusiastDashboard" element={<EnthuDashboard />}></Route>
        <Route
          path="professionalDashboard"
          element={<ProfessionalDashboard />}
        ></Route>
        <Route path="upcomingApp" element={<UpcomingAppointment />}></Route>
        <Route path="topSellingService" element={<TopSellingService />}></Route>
        <Route
          path="mostFamousProfessionals"
          element={<MostFamousProfessionals />}
        ></Route>
        <Route path="bookings" element={<Bookings />}></Route>
        <Route path="mySalons" element={<MySalons />}></Route>
        <Route
          path="professionalProfile"
          element={<ProfessionalProfile />}
        ></Route>
        <Route path="enthusiastProfile" element={<EnthusiastProfile />}></Route>
        <Route path="adminProfile" element={<AdminProfile />}></Route>
        <Route
          path="ratingsProfessional"
          element={<RatingsProfessional />}
        ></Route>
        <Route
          path="timeSlotProfessional"
          element={<TimeSlotProfessional />}
        ></Route>
        <Route path="salonListing" element={<SalonListing />}></Route>
        <Route path="salonDetail" element={<SalonDetail />}></Route>
        <Route path="/:name" element={<SalonDetail />}></Route>
        <Route path="mobileMenu" element={<MobileMenu />}></Route>
        <Route path="mobileMenuEnthu" element={<MobileMenuEnthu />}></Route>
        <Route path="myPost" element={<MyPost />}></Route>
        <Route path="paymentSummary" element={<PaymentSummary />}></Route>
        <Route path="productSummary" element={<ProductSummary />}></Route>
        <Route path="cart" element={<Cart />}></Route>
        <Route path="payNow" element={<PayNow />}></Route>
        <Route path="wishlist" element={<Wishlist />}></Route>
        <Route path="members" element={<Members />}></Route>
        <Route path="scheduleShift" element={<ScheduleShift />}></Route>
        <Route
          path="appointment"
          element={<CalendarViewProfessional />}
        ></Route>
        <Route path="bookService" element={<BookService />}></Route>
        <Route path="ThankYou" element={<ThankYou />}></Route>
        <Route path="changePassword" element={<ChangePassword />}></Route>
        <Route path="stem" element={<Stem />}></Route>
        <Route path="tax" element={<Tax />}></Route>
        <Route path="appointmentList" element={<AppointmentList />}></Route>
        <Route path="tutorial" element={<Tutorial />}></Route>
        <Route path="addAppointment" element={<AddAppointment />}></Route>
        <Route path="products" element={<MyPurchasedProduct />}></Route>
        <Route path="adminReport" element={<AdminReport />}></Route>
        <Route path="inviteUsers" element={<InviteUsers />}></Route>
        <Route path="category" element={<Category />}></Route>
        <Route path="professionals" element={<ProfessionalList />}></Route>
        <Route
          path="professionalReport"
          element={<ProfessionalReport />}
        ></Route>

        <Route path="StarRating" element={<StarRating />}></Route>
        <Route path="CalendarExample" element={<CalendarExample />}></Route>
        <Route
          path="GoogleContactsComponent"
          element={<GoogleContactsComponent />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
