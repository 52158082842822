import React from "react";

function Likes({ likesData }) {
  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-2">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          Likes
        </div>
        <div className="">
          {likesData.map((like) => {
            return (
              <div className="mt-2">
                {like.user_id ? (
                  <>
                    <span className="">
                      <img
                        src={
                          like.user_id.profile_picture
                            ? like.user_id.profile_picture
                            : "img/default.jpg"
                        }
                        className="rounded-full h-[30px] w-[30px] inline"
                      />
                    </span>
                    <span className="ml-3">{like.user_id.first_name}</span>
                  </>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Likes;
