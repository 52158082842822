import React, { useState, useEffect, useRef } from "react";
import Spinner from "./spinner";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";
import { UseMediaQuery } from "../hooks/useMediaQuery";

const AddProfessionalProducts = ({ closeModal, productId }) => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [imgErr, setImgErr] = useState(false);
  const inputRef = useRef();
  const [formDataAll, setFormDataAll] = useState({
    name: "",
    description: "",
    price: "",
    quantity: "",
  });
  const [errors, setErrors] = useState({});
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (productId) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL + "product/" + productId, {
        headers: { authorization: "Bearer " + token },
      });
      if (response) {
        let service = {};
        service["name"] = response.data.name ? response.data.name : "";
        service["description"] = response.data.description
          ? response.data.description
          : "";
        service["price"] = response.data.price ? response.data.price : "";
        service["quantity"] = response.data.quantity
          ? response.data.quantity
          : "";
        // response.data.img_urls
        //   ? (service["img_urls"] = response.data.img_urls)
        //   : "";
        if (response.data.img_urls) {
          service["img_urls"] = response.data.img_urls;
        }
        setFormDataAll(service);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "price") {
      //const re = /^\d+(\.\d+)?$/;
      const re = /^(?!,$)[\d,.]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setFormDataAll({ ...formDataAll, [name]: value });
      }
    } else {
      setFormDataAll({ ...formDataAll, [name]: value });
    }
  };

  const handleUpload = async () => {
    const files = inputRef.current.files;
    setLoadingImg(true);
    if (files.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 5242880) {
          setImgErr(true);
        } else {
          formData.append("media", files[i]);
        }
      }

      if (formData) {
        await axios
          .post(API_URL + "upload/multi-upload", formData, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            let arr = formDataAll["img_urls"] ? formDataAll["img_urls"] : [];

            response.data.forEach((item) => {
              arr.push(item.media);
            });
            setLoadingImg(false);
            setFormDataAll({ ...formDataAll, img_urls: arr });
            setCount(count + 1);
          })
          .catch(function (error) {
            setLoadingImg(false);
            console.log(error);
            toastError("Images not added");
          });
      }
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formDataAll.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formDataAll.description.trim()) {
      errors.description = "Description is required";
    }

    if (!formDataAll.price) {
      errors.price = "Price is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      formDataAll["user_id"] = userInfo.user_id;
      formDataAll["seller_name"] = userInfo.first_name;
      setLoading(true);
      if (productId) {
        try {
          await axios
            .put(API_URL + "product/" + productId, formDataAll, {
              headers: { authorization: "Bearer " + token },
            })
            .then(function (response) {
              setLoading(false);
              toastSuccess("Product updated successfully");
              closeModal();
            })
            .catch(function (error) {
              toastError(error.response.data.message || "Product not updated");
              console.log(error);
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          toastError(error || "Product not updated");
          console.error("Error:", error);
        }
      } else {
        try {
          await axios
            .post(API_URL + "product", formDataAll, {
              headers: { authorization: "Bearer " + token },
            })
            .then(function (response) {
              setLoading(false);
              toastSuccess("Product added successfully");
              closeModal();
            })
            .catch(function (error) {
              toastError(error.response.data.message || "Product not added");
              console.log(error);
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          toastError(error || "Product not added");
          console.error("Error:", error);
        }
      }
    }
  };

  const handleDeleteImg = (img) => {
    const updatedItems = formDataAll["img_urls"].filter((item) => item !== img);
    setFormDataAll({ ...formDataAll, img_urls: updatedItems });
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="block border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          <div className="inline-block">
            {productId ? "Update" : "Add"} Product{" "}
          </div>
        </div>
      </div>

      <div className="">
        <>
          {loadingImg ? (
            <div className="mt-[-40px]">
              <Spinner />
            </div>
          ) : (
            <div className="file-container addWork">
              <label className="text-grey3 text-base w-full">Add Image</label>
              <label
                htmlFor="fileInput"
                className="bg-grey2 rounded p-4 mt-1 font-semibold opacity-80 cursor-pointer text-lg"
              >
                <img alt="add" src="img/Add2.png" />
              </label>
              <input
                type="file"
                accept="image/*"
                multiple
                id="fileInput"
                ref={inputRef}
                onChange={handleUpload}
                className="file-input"
              />
              {imgErr ? (
                <div className="text-xs text-red">
                  Image more than 5 MB will not get uploaded
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
        {formDataAll["img_urls"] ? (
          <div className="gap-4 grid lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-5 xs:grid-cols-3 xxs:grid-cols-3 max-h-[300px] overflow-y-scroll">
            {formDataAll["img_urls"].map((img) => {
              return (
                <div className="relative">
                  <img className="" src={img} alt="products" />
                  <img
                    alt="delete"
                    onClick={() => handleDeleteImg(img)}
                    className="absolute top-2 right-2 cursor-pointer"
                    src="img/deleteR.png"
                  />
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}

        <form onSubmit={handleSubmit}>
          <label className="text-grey3 text-base w-full mt-2">Name</label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Product Name"
            name="name"
            value={formDataAll.name}
            onChange={handleChange}
          />
          {errors.name && (
            <span className="text-red text-xs">{errors.name}</span>
          )}

          <label className="text-grey3 text-base w-full mt-3">
            Product Details
          </label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Product Details"
            name="description"
            value={formDataAll.description}
            onChange={handleChange}
          />
          {errors.description && (
            <span className="text-red text-xs">{errors.description}</span>
          )}

          <label className="text-grey3 text-base w-full mt-3">Quantity</label>
          <input
            type="number"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Product Name"
            name="quantity"
            value={formDataAll.quantity}
            onChange={handleChange}
          />
          {errors.quantity && (
            <span className="text-red text-xs">{errors.quantity}</span>
          )}

          <label className="text-grey3 text-base w-full mt-3">Price</label>
          <label
            className={` ${
              matches ? "w-[10%]" : "w-[5%]"
            } inline-block border border-grey1 text-center p-2 rounded mr-1`}
          >
            $
          </label>
          <input
            type="text"
            className={` ${
              matches ? "w-[88%]" : "w-[93%]"
            } inline-block border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base rounded `}
            placeholder="Price"
            name="price"
            value={formDataAll.price}
            onChange={handleChange}
          />
          {errors.price && (
            <span className="text-red text-xs">{errors.price}</span>
          )}

          <div className="float-right mt-4">
            {loading ? (
              <Spinner /> // Show the spinner while loading
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                >
                  Cancel
                </button>

                <input
                  type="submit"
                  value={productId ? "Update" : "Add"}
                  name="Add"
                  className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                />
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProfessionalProducts;
