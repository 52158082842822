import React, { useState, useEffect } from "react";
import Model from "./model";
import HoursAdd from "./hoursAdd";
import axios from "axios";
import TimeSlotViewAll from "./timeSlotViewAll";
import { toastSuccess, toastError } from "../hooks/toaster";
import DeleteModal from "./deleteModal";

function TimeSlotView({ page }) {
  const token = localStorage.getItem("userToken");
  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [modalIsOpenView, setIsOpenModalView] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [timeSlotData, setTimeSlotData] = useState();
  const [timeSlotData3, setTimeSlotData3] = useState();
  const [timeSlotType, setTimeSlotType] = useState("basic");
  const API_URL = process.env.REACT_APP_API_URL;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [modalIsOpenDelete, setIsOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [loadingDelete, setLoadingDelete] = useState(false);

  const openModalDelete = (id) => {
    setDeleteId(id);
    setIsOpenModalDelete(true);
  };

  const closeModalDelete = () => {
    fetchData();
    setIsOpenModalDelete(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoadingInit(true);
    try {
      const response = await axios.get(
        API_URL + "slot/get-slot-by-userid/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setLoadingInit(false);
        setTimeSlotData(response.data);
        setTimeSlotData3(response.data.slice(0, 3));
        if (response.data[0]) {
          setTimeSlotType(response.data[0].type);
        }
      }
    } catch (error) {
      setLoadingInit(false);
      console.error("Error fetching data:", error);
    }
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const openModalView = () => {
    setIsOpenModalView(true);
  };

  const closeModalView = () => {
    setIsOpenModalView(false);
  };

  const handleDelete = async () => {
    setLoadingDelete(true);
    try {
      await axios
        .delete(API_URL + "slot/delete-slot/" + deleteId, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          fetchData();
          setLoadingDelete(false);
          toastSuccess("Slot deleted successfully");
          setIsOpenModalDelete(false);
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Slot not deleted");
          console.log(error);
          setLoadingDelete(false);
        });
    } catch (error) {
      setLoadingDelete(false);
      toastError(error || "Slot not deleted");
      console.error("Error:", error);
    }
  };

  return (
    <div
      className={` ${
        page === "enthu" ? "border border-grey9 p-2 rounded" : ""
      } `}
    >
      <div className="block border-b border-grey9 pb-3">
        <div className="inline-block text-base font-semibold ">Time Slots</div>
        {page !== "enthu" ? (
          <div className="inline-block float-right">
            <button onClick={() => openModal()}>
              <img alt="user" src="img/Add2.png" />
            </button>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="mt-4">
        <div className="text-xs">
          <div className="border-b border-grey9 pb-3">
            {timeSlotData3 && timeSlotData3.length > 0 ? (
              <div className="">
                {timeSlotData3.map((time) => {
                  return (
                    <div>
                      <div className="block mt-2 pb-2">
                        <div className=" inline-block">
                          <div className=" font-semibold">
                            <span className="block">
                              Type :{" "}
                              <span className="text-grey3 font-normal">
                                {time.type}
                              </span>
                            </span>
                            {time.type === "advance" ? (
                              <span className="inline-block">
                                Day :{" "}
                                <span className="text-grey3 font-normal">
                                  {time.slot_day}
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                            <span className="block">
                              Time :{" "}
                              <span className="text-grey3 font-normal">
                                {time.time}
                              </span>
                            </span>
                            <span className="block">
                              Capacity :{" "}
                              <span className="text-grey3 font-normal">
                                {time.capacity}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="inline-block float-right">
                          <button onClick={() => openModalDelete(time._id)}>
                            <img
                              className="mt-1 h-[16px] w-[13px]"
                              alt="user"
                              src="img/Delete2.png"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="mt-3 ">
            <span className="inline text-base font-semibold">
              <button onClick={() => openModalView()}>View All</button>
            </span>
          </div>
        </div>
      </div>

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={<HoursAdd closeModal={closeModal} fetchData={fetchData} />}
        dialogClassName="modal-width-profile"
      />

      <Model
        modalIsOpen={modalIsOpenView}
        closeModal={closeModalView}
        content={
          <TimeSlotViewAll
            closeModal={closeModalView}
            timeSlotData={timeSlotData}
          />
        }
        dialogClassName="modal-width-40"
      />

      <Model
        modalIsOpen={modalIsOpenDelete}
        closeModal={closeModalDelete}
        content={
          <DeleteModal
            closeModalDelete={closeModalDelete}
            title="Image"
            handleDelete={handleDelete}
            loading={loadingDelete}
          />
        }
        dialogClassName="modal-width-30"
      />
    </div>
  );
}

export default TimeSlotView;
