import React from "react";
import axios from "axios";

const SocialIcons = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const handleGoogle = async () => {
    window.open(API_URL + `user/google/callback`, "_self");

    // setLoading(true);

    // try {
    //   await axios
    //     .get(API_URL + "user/google", {})
    //     .then(function (response) {
    //       // localStorage.setItem("userInfo", JSON.stringify(response.data.user));
    //       // setLoading(false);
    //       // closeModalSignIn();
    //       // if (response.data.user.interest_status) {
    //       //   navigate("/enthusiastDashboard");
    //       // } else {
    //       //   if (response.data.user.user_type === "professional") {
    //       //     navigate("/professionalDashboard");
    //       //   } else {
    //       //     setModalIsOpenInterest(true);
    //       //   }
    //       // }
    //     })
    //     .catch(function (error) {
    //       // setLoginFailError("Wrong Email Or Password, Please try again.");
    //       // console.log(error, "error");
    //       // setLoading(false);
    //     });
    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };

  return (
    <>
      <div className="justify-center flex mt-3">
        <a href="/#">
          <img alt="fb" src="img/fb.png" className="w-[35px] mr-4" />
        </a>
        <a href="/#" onClick={() => handleGoogle()}>
          <img alt="google" src="img/google.png" className="w-[35px] mr-4" />
        </a>
        <a href="/#">
          <img alt="apple" src="img/apple.png" className="w-[35px] mr-4" />
        </a>
        <a href="/#">
          <img alt="insta" src="img/insta.png" className="w-[35px] mr-4" />
        </a>
      </div>
    </>
  );
};

export default SocialIcons;
