import React, { useState } from "react";
import Spinner from "./spinner";
import Model from "./model";
import axios from "axios";
import UploadCoverPic from "./uploadCoverPic";
import { UseMediaQuery } from "../hooks/useMediaQuery";

const CoverPic = ({ coverPicData, closeUpdateModal }) => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const [modalIsOpen, setIsOpenModal] = useState(false);

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <div className="px-4 pt-4 pb-4 block ">
      <div className="border-b border-grey9 pb-4">
        <div className="inline-block font-semibold ">Cover Photo</div>
        <div className="inline-block float-right">
          <button onClick={() => openModal()}>
            <img alt="user" src="img/edit.png" />
          </button>
        </div>
      </div>

      <div class={`mt-3 container11 ${matches ? "h-[165px]" : "h-[400px]"} `}>
        <img
          src={coverPicData ? coverPicData : "img/img8.png"}
          alt="Cover Image"
          class="image11"
        />
        <div class="background-color11"></div>
      </div>

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={
          <UploadCoverPic
            coverPicData={coverPicData}
            closeModal={closeModal}
            closeUpdateModal={closeUpdateModal}
          />
        }
        dialogClassName="modal-width-profile"
      />
    </div>
  );
};

export default CoverPic;
