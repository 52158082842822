import React, { useState } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Model from "./model";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function SubCategoryListView({
  category,
  subCategory,
  openModalUpdateSub,
  openModalDeleteSub,
}) {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const API_URL = process.env.REACT_APP_API_URL;
  const matches = UseMediaQuery("(max-width: 992px)");
  const [modalIsOpen, setIsOpenModal] = useState(false);

  const getCatName = (id) => {
    let name = "";
    const index = category.findIndex((user) => user._id === id);
    if (index >= 0) {
      name = category[index].name;
    }
    return name;
  };

  return (
    <div>
      {subCategory.length > 0 ? (
        <>
          {subCategory.map((cat, index) => {
            return (
              <div className="mt-3 ">
                <div
                  className={` ${
                    matches ? "grid grid-cols-5" : "block"
                  } bg-grey1 rounded border border-grey7 mr-2  p-2`}
                >
                  <div
                    className={`${matches ? "col-span-4" : ""} inline-block`}
                  >
                    <img
                      className={` ${
                        matches ? "max-h-[20px]" : "max-h-[40px]"
                      } inline-block mr-2`}
                      alt="sub category"
                      src={cat.image ? cat.image : "img/noImg.jpg"}
                    />
                    <div
                      className={` inline-block ${
                        matches ? "max-w-[200px]" : ""
                      } `}
                    >
                      <div className="inline-block text-black2 lg:text-base md:text-base sm:text-base xs:text-sm xxs:text-sm">
                        {cat.name}
                      </div>
                      <div className="inline-block ml-3 mr-3"> ----</div>
                      <div className="inline-block text-black2 lg:text-base md:text-base sm:text-base xs:text-sm xxs:text-sm">
                        {getCatName(cat.category_id)}
                      </div>
                    </div>
                  </div>
                  <div className={` ${matches ? "inline" : ""} float-right`}>
                    <button
                      className={`align-top inline-block ${
                        matches ? "float-right" : ""
                      } `}
                      onClick={(id) => openModalUpdateSub(cat._id)}
                    >
                      <img
                        alt="user"
                        src="img/edit.png"
                        className={` ${
                          matches
                            ? "h-[24px] w-[24px] ml-[7px]"
                            : "align-top inline-block"
                        }`}
                      />
                    </button>
                    <button
                      className={`align-top inline-block ${
                        matches ? "float-right" : ""
                      } `}
                      onClick={(id) => openModalDeleteSub(cat._id)}
                    >
                      <img
                        alt="user"
                        src="img/Delete2.png"
                        className={` ${
                          matches ? "" : "align-top inline-block"
                        } h-[17px] w-[17px] ml-[7px] mt-[3px]`}
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <span className="text-center mt-3 block font-semibold text-black2 text-base">
          No categories added yet.
        </span>
      )}
    </div>
  );
}

export default SubCategoryListView;
