import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import UpcomingAppointment from "./upcomingAppointments";
import TopSellingService from "./topSellingService";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Post from "./post";

function MyPost() {
  const matches = UseMediaQuery("(max-width: 992px)");
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const [page, setPage] = useState(1);
  const [postData, setPostData] = useState();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const fetchData = async (option = "") => {
    setLoading(true);
    let pageNum = option === "create" ? 1 : page;
    try {
      const response = await axios.get(
        API_URL +
          "post/get-all-professional-post/" +
          userInfo.user_id +
          "?limit=5&page=" +
          pageNum,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setCount(count + 1);
        if (postData && option !== "create") {
          setPostData((prevData) => [...prevData, ...response.data]);
        } else {
          setPostData(response.data);
        }
        setPage(page + 1);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataFun = () => {
    fetchData("create");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;

    if (scrollTop + clientHeight >= scrollHeight - 20 && !loading) {
      fetchData(); // Load more data when scrolled to the bottom (with a 20px buffer)
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div>
      <div className="bg-white1 ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div
              className={` min-h-screen ${
                matches ? "mx-2 my-2" : "mx-4 my-4"
              } `}
            >
              <div className="grid grid-cols-6 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-4 xs:grid-cols-4 xxs:grid-cols-4 gap-3">
                <div className={` ${matches ? "col-span-6" : "col-span-4"} `}>
                  <Post
                    postData={postData}
                    loading={loading}
                    pageName="mypost"
                    fetchDataFun={fetchDataFun}
                  />
                </div>
                {!matches ? (
                  <>
                    {userInfo.user_type === "professional" ? (
                      <div className="col-span-2 mt-4">
                        <>
                          {/* <UpcomingAppointment /> */}
                          <TopSellingService />
                        </>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPost;
