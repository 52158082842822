import React from "react";
import Menu from "./menu";
import Header from "./header";
import Footer from "./mobileFooter";
import { UseMediaQuery } from "../hooks/useMediaQuery";

function MobileMenuEnthu() {
  const matches = UseMediaQuery("(max-width: 992px)");
  return (
    <div className="bg-white1 ">
      <div className="flex h-full">
        <Menu />
        <div className="w-full ">
          <Header />
          <div className={`mx-2 mb-2 h-screen`}>
            <div
              className="grid gap-2 xxs:gap-2 xs:gap-2 sm:gap-2 md:gap-3 lg:gap-3 xl:gap-3
            grid-cols-3 xxs:grid-cols-3 xs:grid-cols-3 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7"
            >
              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="enthusiastDashboard" className="block">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    Dashboard
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="dashboard"
                    src="img/dash1.4.png"
                    className=" mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="bookings" className="block">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    My Bookings
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="appointments"
                    src="img/ua.png"
                    className=" mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="products" className="block">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    My Products
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="appointments"
                    src="img/ua.png"
                    className=" mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              {/* <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="#">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-2 mt-3 mb-[120px] inline-block">
                    Services / Stylist
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="appointments"
                    src="img/Services.png"
                    className="  mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="mostFamousProfessionals" className="block">
                  <span className="mobileMenuTitle text-black2 text-[10px] font-semibold px-1 mt-3 mb-[120px] inline-block">
                    Famous Professionals
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="appointments"
                    src="img/Professionals.png"
                    className="  mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="mySalons" className="block">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    My Salons
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="appointments"
                    src="img/ms.png"
                    className="  mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div> */}

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="stem" className="block">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    STEM Program
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="appointments"
                    src="img/sp.png"
                    className="  mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>
            </div>
          </div>

          {matches ? <Footer /> : ""}
        </div>
      </div>
    </div>
  );
}

export default MobileMenuEnthu;
