import React, { useState, useEffect } from "react";
import Model from "./model";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";
import Spinner from "./spinner";
import CategoryListView from "./categoryListView";
import SubCategoryListView from "./subcategoryListView";
import AddCategory from "./addCategory";
import DeleteModal from "./deleteModal";
import AddSubCategory from "./addSubCategory";

const CategoryList = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [modalIsOpenSub, setIsOpenModalSub] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [categoryId, setCategoryId] = useState();
  const [categoryDeleteId, setCategoryDeleteId] = useState();
  const [modalIsOpenDelete, setIsOpenModalDelete] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState();
  const [subCategoryDeleteId, setSubCategoryDeleteId] = useState();
  const [modalIsOpenDeleteSub, setIsOpenModalDeleteSub] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL + "interest/category", {
        headers: { authorization: "Bearer " + token },
      });
      if (response) {
        setCategory(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      const response = await axios.get(
        API_URL + "interest/get-all-sub-category",
        {
          headers: { authorization: "Bearer " + token },
        }
      );
      if (response) {
        setSubCategory(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const openModalSub = () => {
    setIsOpenModalSub(true);
  };

  const closeModalSub = () => {
    fetchData();
    setIsOpenModalSub(false);
    setSubCategoryId("");
  };

  const closeModal = () => {
    fetchData();
    setIsOpenModal(false);
    setCategoryId("");
  };

  const openModalUpdate = (id) => {
    setCategoryId(id);
    setIsOpenModal(true);
  };

  const openModalUpdateSub = (id) => {
    setSubCategoryId(id);
    setIsOpenModalSub(true);
  };

  const openModalDelete = (id) => {
    setCategoryDeleteId(id);
    setIsOpenModalDelete(true);
  };

  const openModalDeleteSub = (id) => {
    setSubCategoryDeleteId(id);
    setIsOpenModalDeleteSub(true);
  };

  const closeModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const closeModalDeleteSub = () => {
    setIsOpenModalDeleteSub(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await axios
        .delete(API_URL + "interest/category/" + categoryDeleteId, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          fetchData();
          setLoading(false);
          toastSuccess("Category deleted successfully");
          setIsOpenModalDelete(false);
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Category not deleted");
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      toastError(error || "Category not deleted");
      console.error("Error:", error);
    }
  };

  const handleDeleteSub = async () => {
    setSubLoading(true);
    try {
      await axios
        .delete(API_URL + "interest/sub-category/" + subCategoryDeleteId, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          fetchData();
          setSubLoading(false);
          toastSuccess("Sub Category deleted successfully");
          setIsOpenModalDeleteSub(false);
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Category not deleted");
          console.log(error);
          setSubLoading(false);
        });
    } catch (error) {
      setSubLoading(false);
      toastError(error || "Category not deleted");
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div className="px-4 pt-4 pb-4 block ">
        <div className="border-b border-grey9 pb-4">
          <div className="inline-block font-semibold ">Categories</div>
          <div className="inline-block float-right">
            <button onClick={() => openModal()}>
              <img src="img/Add2.png" alt="user" />
            </button>
          </div>
        </div>

        {category ? (
          <CategoryListView
            category={category}
            openModalUpdate={openModalUpdate}
            openModalDelete={openModalDelete}
          />
        ) : (
          <Spinner />
        )}

        <Model
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          content={
            <AddCategory closeModal={closeModal} categoryId={categoryId} />
          }
          dialogClassName="modal-width-profile"
        />

        <Model
          modalIsOpen={modalIsOpenDelete}
          closeModal={closeModalDelete}
          content={
            <DeleteModal
              closeModalDelete={closeModalDelete}
              title="Category"
              handleDelete={handleDelete}
              loading={loading}
            />
          }
          dialogClassName="modal-width-30"
        />
      </div>

      <div className="px-4 pt-4 pb-4 block ">
        <div className="border-b border-grey9 pb-4">
          <div className="inline-block font-semibold ">Sub Categories</div>
          <div className="inline-block float-right">
            <button onClick={() => openModalSub()}>
              <img src="img/Add2.png" alt="user" />
            </button>
          </div>
        </div>

        {subCategory ? (
          <SubCategoryListView
            category={category}
            subCategory={subCategory}
            openModalUpdateSub={openModalUpdateSub}
            openModalDeleteSub={openModalDeleteSub}
          />
        ) : (
          <Spinner />
        )}

        <Model
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          content={
            <AddCategory closeModal={closeModal} categoryId={categoryId} />
          }
          dialogClassName="modal-width-profile"
        />

        <Model
          modalIsOpen={modalIsOpenSub}
          closeModal={closeModalSub}
          content={
            <AddSubCategory
              category={category}
              closeModal={closeModalSub}
              subCategoryId={subCategoryId}
            />
          }
          dialogClassName="modal-width-profile"
        />

        <Model
          modalIsOpen={modalIsOpenDeleteSub}
          closeModal={closeModalDeleteSub}
          content={
            <DeleteModal
              closeModalDelete={closeModalDeleteSub}
              title="Sub Category"
              handleDelete={handleDeleteSub}
              loading={subLoading}
            />
          }
          dialogClassName="modal-width-30"
        />
      </div>
    </div>
  );
};

export default CategoryList;
