import React, { useState, useEffect } from "react";
import axios from "axios";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import MyProductsData from "./myProductsData";
import { Spinner } from "react-bootstrap";

function MyPurchasedProduct() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const matches = UseMediaQuery("(max-width: 992px)");
  const [loading, setLoading] = useState(false);
  const [productsData, setProductsData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "product/purchase-products/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setLoading(false);
        setProductsData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  return (
    <div className="bg-white1 ">
      <div className="flex">
        <Menu />
        <div className="w-full ">
          <Header />
          <div
            className={` ${matches ? "mx-2 my-2" : "mx-4 my-4"} min-h-screen`}
          >
            <div className={` ${matches ? "col-span-6" : "col-span-4"} `}>
              <div className="bg-white rounded border border-grey7">
                <div className={` ${matches ? "p-2.5" : "p-4"}`}>
                  <div className=" text-black4 text-base font-semibold">
                    My Products
                  </div>

                  {!loading ? (
                    <div className="mt-3">
                      <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 xs:grid-cols-2 xxs:grid-cols-2  gap-3">
                        <MyProductsData productData={productsData} />
                      </div>
                    </div>
                  ) : (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {matches ? <Footer /> : ""}
        </div>
      </div>
    </div>
  );
}

export default MyPurchasedProduct;
