import React, { useState, useRef } from "react";
import axios from "axios";
import Spinner from "./spinner";
import { isNumber } from "../hooks/useMediaQuery";
import { toastSuccess, toastError } from "../hooks/toaster";

const EditProfileEnthu = ({ closeModal, primaryDetail, closeUpdateModal }) => {
  const inputRef = useRef();
  const token = localStorage.getItem("userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);

  const [formData, setFormData] = useState({
    first_name: primaryDetail
      ? primaryDetail.first_name && primaryDetail.first_name !== undefined
        ? primaryDetail.first_name
        : ""
      : "",
    last_name: primaryDetail
      ? primaryDetail.last_name && primaryDetail.last_name !== undefined
        ? primaryDetail.last_name
        : ""
      : "",
    address_line1: primaryDetail
      ? primaryDetail.address_line1 && primaryDetail.address_line1 !== undefined
        ? primaryDetail.address_line1
        : ""
      : "",
    address_line2: primaryDetail
      ? primaryDetail.address_line2 && primaryDetail.address_line2 !== undefined
        ? primaryDetail.address_line2
        : ""
      : "",
    city: primaryDetail
      ? primaryDetail.city && primaryDetail.city !== undefined
        ? primaryDetail.city
        : ""
      : "",
    state: primaryDetail
      ? primaryDetail.state && primaryDetail.state !== undefined
        ? primaryDetail.state
        : ""
      : "",
    country: primaryDetail
      ? primaryDetail.country && primaryDetail.country !== undefined
        ? primaryDetail.country
        : ""
      : "",
    pincode: primaryDetail
      ? primaryDetail.pincode && primaryDetail.pincode !== undefined
        ? primaryDetail.pincode
        : ""
      : "",
    phone_number: primaryDetail
      ? primaryDetail.phone_number && primaryDetail.phone_number !== undefined
        ? primaryDetail.phone_number
        : ""
      : "",
    profile_picture: primaryDetail
      ? primaryDetail.profile_picture &&
        primaryDetail.profile_picture !== undefined
        ? primaryDetail.profile_picture
        : ""
      : "",
  });
  const [errors, setErrors] = useState({});
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [count, setCount] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleImg = async () => {
    const files = inputRef.current.files;

    if (files.length > 0) {
      setLoadingImg(true);

      const formData1 = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData1.append("media", files[i]);
      }

      await axios
        .post(API_URL + "upload/single-upload", formData1, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          setFormData({
            ...formData,
            profile_picture: response.data.media,
          });
          setLoadingImg(false);
          setCount(count + 1);
        })
        .catch(function (error) {
          setLoadingImg(false);
          console.log(error);
        });
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.first_name.trim()) {
      errors.first_name = "Name is required";
    }

    if (!formData.phone_number.trim()) {
      errors.phone_number = "Phone No is required";
    }

    if (!formData.address_line1.trim()) {
      errors.address_line1 = "Address is required";
    }

    if (!formData.pincode.trim()) {
      errors.pincode = "Postal Code is required";
    }

    if (!formData.city.trim()) {
      errors.city = "City is required";
    }

    if (!formData.state.trim()) {
      errors.state = "State is required";
    }

    if (!formData.country.trim()) {
      errors.country = "Country is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      formData["user_id"] = userInfo.user_id;

      setLoading(true);
      try {
        await axios
          .put(
            API_URL + "user/update-primary-salon/" + userInfo.user_id,
            formData,
            { headers: { authorization: "Bearer " + token } }
          )
          .then(function (response) {
            toastSuccess("Details updated successfully");
            setLoading(false);
            closeUpdateModal();
          })
          .catch(function (error) {
            toastError(error.response.data.message || "Details not updated");
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          {primaryDetail
            ? primaryDetail.first_name + " " + primaryDetail.last_name
            : ""}{" "}
          Primary Details
        </div>
        <form onSubmit={handleSubmit}>
          <button type="button">
            <div className="file-container addWork">
              <label
                htmlFor="fileInput"
                className="text-green1 font-semibold opacity-80 cursor-pointer text-lg"
              >
                + Upload Profile Picture
              </label>
              <input
                type="file"
                accept="image/*"
                id="fileInput"
                ref={inputRef}
                onChange={() => handleImg()}
                className="file-input"
              />
            </div>
          </button>
          {formData["profile_picture"] ? (
            loadingImg ? (
              <Spinner />
            ) : (
              <img
                src={formData["profile_picture"]}
                alt="user profile"
                className="h-[150px] mt-1"
              />
            )
          ) : loadingImg ? (
            <Spinner />
          ) : (
            ""
          )}

          <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 xxs:grid-cols-1 gap-2">
            <div>
              <label className="text-grey3 text-base w-full mt-3">
                First Name
              </label>
              <input
                type="text"
                className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
                placeholder="Jessica's"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
              />
              {errors.first_name && (
                <span className="text-red text-xs">{errors.first_name}</span>
              )}
            </div>
            <div>
              <label className="text-grey3 text-base w-full mt-3">
                Last Name
              </label>
              <input
                type="text"
                className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
                placeholder="Salon"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
              {errors.last_name && (
                <span className="text-red text-xs">{errors.last_name}</span>
              )}
            </div>
          </div>

          <label className="text-grey3 text-base w-full mt-3">
            Phone Number
          </label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded"
            placeholder="Enter mobile number"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            onKeyDown={(event) => isNumber(event)}
          />
          {errors.phone_number && (
            <span className="text-red text-xs">{errors.phone_number}</span>
          )}

          <label className="text-grey3 text-base w-full mt-3">
            Address Line 1
          </label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Professional Salon / 376 Broadway "
            name="address_line1"
            value={formData.address_line1}
            onChange={handleChange}
          />
          {errors.address_line1 && (
            <span className="text-red text-xs">{errors.address_line1}</span>
          )}

          <label className="text-grey3 text-base w-full mt-3">
            Address Line 2
          </label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Ste 21, American Pharoah, Saratoga Springs"
            name="address_line2"
            value={formData.address_line2}
            onChange={handleChange}
          />

          <label className="text-grey3 text-base w-full mt-3">City</label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Chicago"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
          {errors.city && (
            <span className="text-red text-xs">{errors.city}</span>
          )}

          <label className="text-grey3 text-base w-full mt-3">State</label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="New York"
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
          {errors.state && (
            <span className="text-red text-xs">{errors.state}</span>
          )}

          <label className="text-grey3 text-base w-full mt-3">Country</label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="United States"
            name="country"
            value={formData.country}
            onChange={handleChange}
          />
          {errors.country && (
            <span className="text-red text-xs">{errors.country}</span>
          )}

          <label className="text-grey3 text-base w-full mt-3">
            Postal Code
          </label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="12866"
            name="pincode"
            value={formData.pincode}
            onChange={handleChange}
          />
          {errors.pincode && (
            <span className="text-red text-xs">{errors.pincode}</span>
          )}

          <div className="float-right mt-4">
            {loading ? (
              <Spinner /> // Show the spinner while loading
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                >
                  Cancel
                </button>
                <input
                  type="submit"
                  value="Update"
                  name="Update"
                  className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                />
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfileEnthu;
