import React from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { NavLink } from "react-router-dom";

function EnthusiastMenu() {
  const matches = UseMediaQuery("(max-width: 992px)");
  return (
    <div>
      {matches ? (
        ""
      ) : (
        // <div className="dash">
        //   <div className="burgerMenu2" onClick={() => menuIsOpen(true)}>
        //     <button className="btnMenu w-full"></button>
        //     <span className="menuSpan first"></span>
        //     <span className="menuSpan second"></span>
        //     <span className="menuSpan third"></span>
        //   </div>

        //   {isOpen ? (
        //     <div className="menuWrap">
        //       <div>
        //         <div className="crossMenu">
        //           <button onClick={() => menuIsOpen(false)}>close</button>
        //           <span>
        //             <span className="crossSpan"></span>
        //             <span className="crossSpan2"></span>
        //           </span>
        //         </div>
        //       </div>
        //       <div className="menuInner">
        //         <div className="menuList">
        //           <NavLink
        //             exact
        //             activeClassName="active"
        //             id="home"
        //             className={`menu-item`}
        //             to="/enthusiastDashboard"
        //           >
        //             Dashboard
        //           </NavLink>
        //           <NavLink
        //             exact
        //             activeClassName="active"
        //             id="home"
        //             className="menu-item mt-3"
        //             to="/bookings"
        //           >
        //             My Bookings / Salons
        //           </NavLink>
        //           <NavLink
        //             exact
        //             activeClassName="active"
        //             className="menu-item mt-3"
        //             to="/#"
        //           >
        //             Services / Stylist
        //           </NavLink>
        //           <NavLink
        //             exact
        //             activeClassName="active"
        //             id="home"
        //             className="menu-item mt-3"
        //             to="/mostFamousProfessionals"
        //           >
        //             Most Famous Professionals
        //           </NavLink>
        //           <NavLink
        //             exact
        //             activeClassName="active"
        //             id="home"
        //             className="menu-item mt-3"
        //             to="/mySalons"
        //           >
        //             My Salons
        //           </NavLink>
        //         </div>
        //       </div>
        //     </div>
        //   ) : (
        //     ""
        //   )}
        // </div>
        <div className="bg-white border-r border-grey7  h-screen pt-5 pl-3 pr-3 min-w-[250px]">
          <div className="">
            <img alt="logo" src="img/logo.png" />
          </div>
          <div className="mt-8 deskMenu">
            <NavLink
              className="w-full block commonA"
              exact
              activeClassName="active"
              to="/"
            >
              <span className="inline commonMenu homeMenu">s</span>
              <span className="ml-3">Home</span>
            </NavLink>
            <NavLink
              className="w-full block mt-4 commonA"
              exact
              activeClassName="active"
              to="/enthusiastDashboard"
            >
              <span className="inline commonMenu dashMenu">s</span>
              <span className="ml-3">Dashboard</span>
            </NavLink>
            <NavLink
              className="w-full block mt-4 commonA"
              exact
              activeClassName="active"
              to="/myPost"
            >
              <span className="inline commonMenu postMenu">s</span>
              <span className="ml-3">My Post</span>
            </NavLink>
            <NavLink
              to="/bookings"
              exact
              activeClassName="active"
              className="w-full block mt-4 commonA"
            >
              <span className="inline commonMenu bookingMenu">s</span>
              <span className="ml-3">My Bookings </span>
            </NavLink>
            <NavLink
              to="/products"
              exact
              activeClassName="active"
              className="w-full block mt-4 commonA"
            >
              <span className="inline commonMenu productMenu">s</span>
              <span className="ml-3">My Products </span>
            </NavLink>
            <NavLink
              to="/#"
              exact
              activeClassName="active"
              className="w-full block mt-4 commonA"
            >
              <span className="inline commonMenu servicesMenu">s</span>
              <span className="ml-3">Services / Stylist</span>
            </NavLink>

            <NavLink
              to="/stem"
              exact
              activeClassName="active"
              className="w-full block mt-4 commonA"
            >
              <span className="inline commonMenu stemMenu">s</span>
              <span className="ml-3">STEM Program</span>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
}

export default EnthusiastMenu;
