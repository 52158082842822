import React from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Header from "./header";
import Footer from "./mobileFooter";

function TopSellingService() {
  const matches = UseMediaQuery("(max-width: 992px)");

  return (
    <>
      {matches ? <Header /> : ""}
      <div
        className={` ${
          matches ? "" : "border border-grey7"
        } bg-white rounded p-4 mt-4`}
      >
        <div className="block">
          <div className="inline text-base font-semibold">
            Top Selling Services
          </div>
        </div>

        <div className="mt-3">
          <div className="border border-grey7 rounded px-2 py-2 block">
            <div className="inline-block align-sub">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
              <div className="text-xs text-grey3">Haircut</div>
            </div>
            <div className="inline-block float-right">
              <div className="text-green1 text-sm font-bold text-end">
                $85.00
              </div>
              <div className="text-xs text-grey3">
                <img alt="user" src="img/rating.png" className="inline mr-1" />
                5.0 (1000)
              </div>
            </div>
          </div>

          <div className="border border-grey7 rounded px-2 py-2 block mt-3">
            <div className="inline-block align-sub">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
              <div className="text-xs text-grey3">Haircut</div>
            </div>
            <div className="inline-block float-right">
              <div className="text-green1 text-sm font-bold text-end">
                $85.00
              </div>
              <div className="text-xs text-grey3">
                <img alt="user" src="img/rating.png" className="inline mr-1" />
                5.0 (1000)
              </div>
            </div>
          </div>

          <div className="border border-grey7 rounded px-2 py-2 block mt-3">
            <div className="inline-block align-sub">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
              <div className="text-xs text-grey3">Haircut</div>
            </div>
            <div className="inline-block float-right">
              <div className="text-green1 text-sm font-bold text-end">
                $85.00
              </div>
              <div className="text-xs text-grey3">
                <img alt="user" src="img/rating.png" className="inline mr-1" />
                5.0 (1000)
              </div>
            </div>
          </div>

          <div className="border border-grey7 rounded px-2 py-2 block mt-3">
            <div className="inline-block align-sub">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
              <div className="text-xs text-grey3">Haircut</div>
            </div>
            <div className="inline-block float-right">
              <div className="text-green1 text-sm font-bold text-end">
                $85.00
              </div>
              <div className="text-xs text-grey3">
                <img alt="user" src="img/rating.png" className="inline mr-1" />
                5.0 (1000)
              </div>
            </div>
          </div>

          {matches ? (
            ""
          ) : (
            <div className="text-green1 font-bold mt-4 text-sm">View all</div>
          )}
        </div>
      </div>
      {matches ? <Footer /> : ""}
    </>
  );
}

export default TopSellingService;
