import React from "react";
import Spinner from "./spinner";

const DeleteModal = ({
  closeModalDelete,
  title,
  handleDelete,
  loading,
  headerTitle,
  customMsg,
}) => {
  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          {headerTitle ? headerTitle : "Delete"} {title}
        </div>
        <div className="mt-4 mb-4">
          Are you sure want to {headerTitle ? headerTitle : "delete"} ?
        </div>
        {customMsg ? (
          <div className="text-red text-xs mb-4">{customMsg}</div>
        ) : (
          ""
        )}
        <div className="float-right">
          {!loading ? (
            <>
              <button
                onClick={closeModalDelete}
                className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
              >
                {headerTitle ? "Close" : "Cancel"}
              </button>

              <button
                onClick={handleDelete}
                className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
              >
                {headerTitle ? headerTitle : "Delete"}
              </button>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
