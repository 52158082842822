import React, { useState, useEffect, useRef } from "react";

import Spinner from "./spinner";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";

const UploadCoverPic = ({ coverPicData, closeModal, closeUpdateModal }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const inputRefCover = useRef();
  const [formData, setFormData] = useState({
    cover_pic: coverPicData,
  });
  const [loadingCoverImg, setLoadingCoverImg] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleImg = async () => {
    const files = inputRefCover.current.files;

    if (files.length > 0) {
      setLoadingCoverImg(true);

      const formData1 = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData1.append("media", files[i]);
      }

      await axios
        .post(API_URL + "upload/single-upload", formData1, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          setFormData({
            ...formData,
            cover_pic: response.data.media,
          });
          setLoadingCoverImg(false);
          setCount(count + 1);
        })
        .catch(function (error) {
          setLoadingCoverImg(false);
          console.log(error);
        });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData["user_id"] = userInfo.user_id;
    setLoading(true);
    try {
      await axios
        .put(
          API_URL + "user/update-primary-salon/" + userInfo.user_id,
          formData,
          { headers: { authorization: "Bearer " + token } }
        )
        .then(function (response) {
          toastSuccess("Cover Photo updated successfully");
          setLoading(false);
          closeModal();
          closeUpdateModal();
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Cover Photo not updated");
          console.log(error);
          setLoading(false);
          closeModal();
        });
    } catch (error) {
      setLoading(false);
      closeModal();
      console.error("Error:", error);
    }
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="block border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          <div className="inline-block">Cover Photo</div>
        </div>
        <>
          <form onSubmit={handleSubmit}>
            <button className="mt-2" type="button ">
              <div className="file-container addWork">
                <label
                  htmlFor="fileInput"
                  className="text-green1 font-semibold opacity-80 cursor-pointer text-lg"
                >
                  + Upload Cover Picture
                </label>
                <label className="text-grey3 ml-2 text-xs align-middle">
                  - suggested size: 460x920 (HxW)
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="fileInput"
                  ref={inputRefCover}
                  onChange={() => handleImg("cover_pic")}
                  className="file-input"
                />
              </div>
            </button>
            {formData["cover_pic"] ? (
              loadingCoverImg ? (
                <Spinner />
              ) : (
                <img
                  src={formData["cover_pic"]}
                  alt="user profile"
                  className="h-[150px] mt-3"
                />
              )
            ) : loadingCoverImg ? (
              <Spinner />
            ) : (
              ""
            )}

            <div className="float-right mt-4">
              {loading ? (
                <Spinner /> // Show the spinner while loading
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => closeModal()}
                    className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                  >
                    Cancel
                  </button>
                  <input
                    type="submit"
                    value="Update"
                    name="Update"
                    className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                  />
                </>
              )}
            </div>
          </form>
        </>
      </div>
    </div>
  );
};

export default UploadCoverPic;
