import React from "react";
import axios from "axios";

function Comments({ commentData }) {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const handleDelete = async (commentId) => {
    try {
      await axios
        .delete(
          API_URL + "post/delete-comment/" + commentData._id + "/" + commentId,
          { headers: { authorization: "Bearer " + token } }
        )
        .then(function (response) {});
    } catch (error) {
      console.error("Error:", error);
    }
  };
  let commentList = commentData.comments;
  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-2">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          Comments
        </div>
        <div className="">
          {commentList.map((comment) => {
            return (
              <div className="mt-2">
                {comment.user_id ? (
                  <>
                    <span className="">
                      <img
                        src={
                          comment.user_id.profile_picture
                            ? comment.user_id.profile_picture
                            : "img/default.jpg"
                        }
                        className="rounded-full h-[30px] w-[30px] inline"
                      />
                    </span>
                    <span className="ml-3 font-bold">
                      {comment.user_id.first_name}
                    </span>
                    <span className="ml-1">{comment.comment_text}</span>
                    <span className="float-right h-[20px] w-[20px]">
                      <button onClick={() => handleDelete(comment._id)}>
                        <img src="img/Delete2.png" />
                      </button>
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Comments;
