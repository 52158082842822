import React, { useState, useEffect } from "react";
import AddProfessionalServices from "./addProfessionalService";
import Model from "./model";
import axios from "axios";
import DeleteModal from "./deleteModal";
import { toastSuccess, toastError } from "../hooks/toaster";
import Spinner from "./spinner";
import ServiceList from "./servicesList";

const ProfessionalServices = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const [services, setServices] = useState();
  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [modalIsOpenDelete, setIsOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [serviceId, setServiceId] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL + "service/get-all-professional-service/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setServices(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    fetchData();
    setIsOpenModal(false);
    setServiceId("");
  };

  const openModalUpdate = (id) => {
    setServiceId(id);
    setIsOpenModal(true);
  };

  const openModalDelete = (id) => {
    setDeleteId(id);
    setIsOpenModalDelete(true);
  };

  const closeModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await axios
        .delete(API_URL + "service/professional-service/" + deleteId, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          fetchData();
          setLoading(false);
          toastSuccess("Service deleted successfully");
          setIsOpenModalDelete(false);
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Service not deleted");
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      toastError(error || "Service not deleted");
      console.error("Error:", error);
    }
  };

  return (
    <div className="px-4 pt-4 pb-4 block ">
      <div className="border-b border-grey9 pb-4">
        <div className="inline-block font-semibold ">Our Services</div>
        <div className="inline-block float-right">
          <button onClick={() => openModal()}>
            <img src="img/Add2.png" alt="user" />
          </button>
        </div>
      </div>
      <>
        {services ? (
          <ServiceList
            services={services}
            openModalUpdate={openModalUpdate}
            openModalDelete={openModalDelete}
          />
        ) : (
          <Spinner />
        )}
      </>

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={
          <AddProfessionalServices
            closeModal={closeModal}
            serviceId={serviceId}
          />
        }
        dialogClassName="modal-width-profile"
      />

      <Model
        modalIsOpen={modalIsOpenDelete}
        closeModal={closeModalDelete}
        content={
          <DeleteModal
            closeModalDelete={closeModalDelete}
            title="Service"
            handleDelete={handleDelete}
            loading={loading}
          />
        }
        dialogClassName="modal-width-30"
      />
    </div>
  );
};

export default ProfessionalServices;
