import React, { useState, useEffect } from "react";
import Spinner from "./spinner";
import axios from "axios";
import { isNumber } from "../hooks/useMediaQuery";

function EnthuSignUp({ closeModal }) {
  const API_URL = process.env.REACT_APP_API_URL;
  const [signUpError, setSignUpError] = useState(false);
  const [errors, setErrors] = useState({});
  const [loadingSignUp, setLoadingSignUp] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    confirmpassword: "",
    user_type: "enthusiastic",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.first_name.trim()) {
      errors.first_name = "First Name is required";
    }

    if (!formData.last_name.trim()) {
      errors.last_name = "Last Name is required";
    }

    if (!formData.phone_number.trim()) {
      errors.phone_number = "Mobile No is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }

    if (formData.password !== formData.confirmpassword) {
      errors.password = "Passwords do not match";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setLoadingSignUp(true);
      try {
        //return true;
        await axios
          .post(API_URL + "user/signup", formData, {
            headers: {
              //"Content-Type": "multipart/form-data",
              // Add any other necessary headers
            },
          })
          .then(function (response) {
            localStorage.setItem("userToken", response.data.token);
            localStorage.setItem(
              "userInfo",
              JSON.stringify(response.data.user)
            );

            //login api
            handleLogin();
          })
          .catch(function (error) {
            setSignUpError(error.response.data.message);
            console.log(error);
            setLoadingSignUp(false);
          });
      } catch (error) {
        setLoadingSignUp(false);
        console.error("Error:", error);
      }
    }
  };

  const handleLogin = async () => {
    let formData1 = {};
    formData1["identifier"] = formData["email"];
    formData1["password"] = formData["password"];
    try {
      await axios
        .post(API_URL + "user/login", formData1, {
          headers: {
            //"Content-Type": "multipart/form-data",
            // Add any other necessary headers
          },
        })
        .then(function (response) {
          localStorage.setItem("userToken", response.data.token);
          localStorage.setItem("userInfo", JSON.stringify(response.data.user));
          setTimeout(function () {
            closeModal(response.data);
            setLoadingSignUp(false);
          }, 2000);
        });
    } catch (error) {
      setLoadingSignUp(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* <div className="text-center font-bold mb-3">
          please Login before booking an appointment
        </div> */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="text-grey3 text-sm">First Name*</label>
            <input
              type="text"
              className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
              placeholder="Enter your first name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
            />
            {errors.first_name && (
              <span className="text-red text-xs">{errors.first_name}</span>
            )}
          </div>
          <div>
            <label className="text-grey3 text-sm">Last Name*</label>
            <input
              type="text"
              className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
              placeholder="Enter your last name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
            />
            {errors.last_name && (
              <span className="text-red text-xs">{errors.last_name}</span>
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mt-2.5">
          <div>
            <label className="text-grey3 text-sm">Mobile No*</label>
            <div>
              <div className="grid grid-cols-6 gap-2">
                <input
                  type="text"
                  className="col-span-1 w-10 border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm rounded"
                  placeholder="+1"
                />
                <input
                  type="text"
                  className="col-span-5 border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
                  placeholder="Enter mobile number"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  onKeyDown={(event) => isNumber(event)}
                />
              </div>
              {errors.phone_number && (
                <span className="text-red text-xs">{errors.phone_number}</span>
              )}
            </div>
          </div>
          <div>
            <label className="text-grey3 text-sm w-full">Email*</label>
            <input
              type="text"
              className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
              placeholder="Enter your email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && (
              <span className="text-red text-xs">{errors.email}</span>
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mt-2.5">
          <div>
            <label className="text-grey3 text-sm">Password*</label>
            <input
              type="password"
              className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && (
              <span className="text-red text-xs">{errors.password}</span>
            )}
          </div>
          <div>
            <label className="text-grey3 text-sm">Confirm Password*</label>
            <input
              type="password"
              className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
              placeholder="Confirm Password"
              name="confirmpassword"
              value={formData.confirmpassword}
              onChange={handleChange}
            />
          </div>
        </div>

        {loadingSignUp ? (
          <Spinner /> // Show the spinner while loading
        ) : (
          <>
            {signUpError ? (
              <div className="mt-3 text-red text-center">{signUpError}</div>
            ) : (
              ""
            )}
            <input
              type="submit"
              value="Login"
              name="Sign Up"
              className="font-bold bg-green1 text-white w-full !py-3 px-5 text-base mt-4 rounded shadowEffect hover:opacity-60"
            />
          </>
        )}
      </form>
    </div>
  );
}
export default EnthuSignUp;
