import React, { useState, useRef } from "react";
import axios from "axios";
import Spinner from "./spinner";
import { toastSuccess, toastError } from "../hooks/toaster";
//import CryptoJS from "crypto-js";

const RecurringPay = ({ closeModal, bookingId }) => {
  const token = localStorage.getItem("userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [formData, setFormData] = useState({
    recurring_type: "weekly",
    card_no: "",
    cv: "",
    exp_month: "01",
    exp_year: "2024",
  });

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(50), (val, index) => currentYear + index);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.card_no.trim()) {
      errors.card_no = "Card No is required";
    }

    if (!formData.cv.trim()) {
      errors.cv = "CVV is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // const encodedData = (data) => {
  //   const secretKey = "pelitocitfopguhijoytuiyfljcgdtyugu";
  //   const dataToEncrypt = "Sensitive information";

  //   const encryptedData = CryptoJS.AES.encrypt(
  //     dataToEncrypt,
  //     secretKey
  //   ).toString();
  //   return encryptedData;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      let formDataNew = {};
      //const encoded = btoa(text);
      formDataNew["recurring_status"] = true;
      formDataNew["recurring_type"] = formData["recurring_type"];
      formDataNew["card_no"] = btoa(formData["card_no"]); //encodedData(formData["card_no"]);
      formDataNew["cv"] = btoa(formData["cv"]); //encodedData(formData["cv"]);
      formDataNew["exp_month"] = btoa(formData["exp_month"]); //encodedData(formData["exp_month"]);
      formDataNew["exp_year"] = btoa(formData["exp_year"]); //encodedData(formData["exp_year"]);

      setLoading(true);
      try {
        await axios
          .put(
            API_URL + "service/update-recurring-status/" + bookingId,
            formDataNew,
            { headers: { authorization: "Bearer " + token } }
          )
          .then(function (response) {
            toastSuccess("Recurring added successfully");
            setLoading(false);
            closeModal();
          })
          .catch(function (error) {
            toastError(error.response.data.message || "Recurring not added");
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          Recurring Payment
        </div>
        <form onSubmit={handleSubmit}>
          <label className="text-grey3 text-base w-full mt-3">
            Recurring Type
          </label>
          <select
            name="recurring_type"
            className="p-2 border border-grey9 rounded w-full"
            id="month"
            value={formData["recurring_type"]}
            onChange={handleChange}
          >
            <option value="weekly">Weekly</option>
            <option value="bi-weekly">Bi-weekly</option>
            <option value="monthly">Monthly</option>
          </select>

          <label className="text-grey3 text-base w-full mt-3">
            Card Number
          </label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="card No"
            name="card_no"
            value={formData.card_no}
            onChange={handleChange}
          />
          {errors.card_no && (
            <span className="text-red text-xs">{errors.card_no}</span>
          )}

          <label className="text-grey3 text-base w-full mt-3">CVV</label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="CVV"
            name="cv"
            value={formData.cv}
            onChange={handleChange}
          />
          {errors.cv && <span className="text-red text-xs">{errors.cv}</span>}

          <label className="text-grey3 text-base w-full mt-3">Exp Month</label>
          <select
            name="exp_month"
            className="p-2 border border-grey9 rounded w-full"
            id="month"
            value={formData["exp_month"]}
            onChange={handleChange}
          >
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>

          <label className="text-grey3 text-base w-full mt-3">Exp Year:</label>
          <select
            className="p-2 border border-grey9 rounded w-full"
            name="exp_year"
            id="year"
            value={formData["exp_year"]}
            onChange={handleChange}
          >
            {years.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </select>

          <div className="float-right mt-4">
            {loading ? (
              <Spinner /> // Show the spinner while loading
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                >
                  Cancel
                </button>
                <input
                  type="submit"
                  value="Submit"
                  name="Update"
                  className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                />
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RecurringPay;
