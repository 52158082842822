import React, { useState, useEffect } from "react";
import Model from "./model";
import axios from "axios";
import DeleteModal from "./deleteModal";
import { toastSuccess, toastError } from "../hooks/toaster";
import AddProfessionalProducts from "./addProfessionalProducts";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { Spinner } from "react-bootstrap";
import AddToBag from "./addToBag";

const ProfessionalProducts = ({ page, userId }) => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [modalIsOpenDelete, setIsOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [productId, setProductId] = useState();
  const [loadingWish, setLoadingWish] = useState(false);
  const [wishId, setWishId] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [productData, setProductData] = useState();
  const [wishlistProductData, setWishlistProductData] = useState();
  const user_id = userId ? userId : userInfo.user_id;
  const [wishlistErr, setWishlistErr] = useState(false);

  const description = (text) => {
    const words = text.split(" ");

    // Display only the first 5 words
    const truncatedText = words.slice(0, 4).join(" ");
    return words.length > 5 ? `${truncatedText} ...` : truncatedText;
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    fetchData();
    setIsOpenModal(false);
    setProductId("");
    setIsOpenModalDelete(false);
    setDeleteId("");
    setLoadingDelete(false);
  };

  const handleUpdateModal = (id) => {
    setProductId(id);
    setIsOpenModal(true);
  };

  const openModalDelete = (id) => {
    setDeleteId(id);
    setIsOpenModalDelete(true);
  };

  useEffect(() => {
    fetchData();
    getAllWishlistProds();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "product/get-all-product/" + user_id
        // { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setProductData(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const closeModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleDelete = async () => {
    setLoadingDelete(true);
    try {
      const response = await axios.delete(API_URL + "product/" + deleteId, {
        headers: { authorization: "Bearer " + token },
      });
      if (response) {
        toastSuccess("Product deleted successfully");
        fetchData();
        closeModalDelete();
        setLoading(false);
        setLoadingDelete(false);
      }
    } catch (error) {
      toastError("Product not deleted successfully");
      setLoading(false);
      console.error("Error fetching data:", error);
      setLoadingDelete(false);
    }
  };

  const fetchWishlist = (id) => {
    if (wishlistProductData) {
      const index = wishlistProductData.findIndex(
        (user) => user.product_id._id === id
      );
      return index;
    }
    return -1;
  };

  const getAllWishlistProds = async () => {
    if (userInfo && userInfo.user_id) {
      try {
        const response = await axios.get(
          API_URL + "wishlist/by-userid/" + userInfo.user_id,
          { headers: { authorization: "Bearer " + token } }
        );
        if (response) {
          setWishlistProductData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleWishlist = async (id) => {
    if (userInfo && userInfo.user_id) {
      setWishlistErr("");
      setWishId(id);
      let formData = {};
      const index = wishlistProductData.findIndex(
        (user) => user.product_id === id
      );

      if (index >= 0) {
        await axios
          .delete(API_URL + "wishlist/" + wishlistProductData[index]._id, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            setWishId("");
            setLoading(false);
            toastSuccess("Wishlist deleted successfully");
            getAllWishlistProds();
          })
          .catch(function (error) {
            setWishId("");
            toastError(
              error.response ? error.response.data.message : "" || "Not deleted"
            );
            console.log(error);
            setLoading(false);
          });
      } else {
        formData["customer_user_id"] = userInfo.user_id;
        formData["seller_user_id"] = userId;
        formData["product_id"] = id;
        await axios
          .post(API_URL + "wishlist", formData, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            setWishId("");
            setLoading(false);
            toastSuccess("Wishlisted successfully");
            getAllWishlistProds();
          })
          .catch(function (error) {
            setWishId("");
            toastError(
              error.response
                ? error.response.data.message
                : "" || "Not wishlisted"
            );
            console.log(error);
            setLoading(false);
          });
      }
    } else {
      setWishlistErr("Please login to wishlist a product");
    }
  };

  return (
    <div className={` ${page === "forEnthu" ? "" : "px-4 pt-4"}  pb-4 block `}>
      {page === "forEnthu" ? (
        ""
      ) : (
        <div className="border-b border-grey9 pb-4">
          <div className="inline-block font-semibold ">Our Products</div>
          <div className="inline-block float-right">
            <button onClick={() => openModal()}>
              <img src="img/Add2.png" alt="user" />
            </button>
          </div>
        </div>
      )}
      {productData && productData.length > 0 ? (
        <div
          className={`mt-3 ${
            productData.length > 8 ? "overflow-y-scroll max-h-[330px]" : ""
          }`}
        >
          <div className="grid grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 xxs:grid-cols-2 gap-2">
            {productData.map((products) => {
              let product = products.item;
              let rating = products.rating;
              return (
                <div className="border border-grey-9 rounded p-2">
                  <div className="relative">
                    {product.img_urls && product.img_urls.length > 0 ? (
                      <>
                        <div
                          className={` ${
                            matches ? "min-h-[160px]" : "min-h-[180px]"
                          }`}
                        >
                          <img
                            src={product.img_urls[0]}
                            alt="all product"
                            className={` ${
                              matches
                                ? "max-w-[135px] max-h-[160px] "
                                : "max-w-[170px] max-h-[180px]"
                            } rounded`}
                          />
                        </div>
                      </>
                    ) : (
                      <img
                        src="img/noImg.jpg"
                        className={` ${
                          matches
                            ? "max-w-[135px] max-h-[160px] "
                            : "max-w-[170px] max-h-[180px]"
                        } rounded`}
                      />
                    )}
                  </div>
                  <div className="block mt-2">
                    <div className=" inline-block text-black2 text-sm">
                      {product.name}
                    </div>
                    <div className="inline-block float-right text-green1 font-bold">
                      ${product.price}
                    </div>
                  </div>

                  <div className="text-[10px]">
                    {product.description
                      ? description(product.description)
                      : ""}
                  </div>

                  <div className="text-[8px] mt-1 text-black0">
                    <img
                      alt="user"
                      src="img/rating.png"
                      className="inline mr-1 h-[10px]"
                    />{" "}
                    {rating
                      ? rating.avgRating + " (" + rating.totalRatings + ")"
                      : 0}
                  </div>

                  <div className="mt-3 text-[9px] ">
                    {wishlistErr ? (
                      <div className="block w-full text-red mb-1">
                        {wishlistErr}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="grid gap-2 grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 xxs:grid-cols-2 ">
                      {page === "forEnthu" ? (
                        <>
                          <AddToBag userId={userId} product={product} />

                          {wishId === product._id ? (
                            <Spinner />
                          ) : (
                            <button
                              onClick={() => handleWishlist(product._id)}
                              className={` ${
                                fetchWishlist(product._id) >= 0
                                  ? "font-bold bg-orange1"
                                  : ""
                              } p-2 bg-grey11 rounded`}
                            >
                              {fetchWishlist(product._id) >= 0
                                ? "Wishlisted"
                                : "Wishlist"}
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => handleUpdateModal(product._id)}
                            className="p-2 bg-grey11 rounded"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => openModalDelete(product._id)}
                            className="p-2 bg-grey11 rounded"
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>
          {page === "forEnthu" ? (
            ""
          ) : (
            <div className="text-center font-bold mt-3">
              No Products added yet
            </div>
          )}
        </>
      )}

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={
          <AddProfessionalProducts
            closeModal={closeModal}
            productId={productId}
          />
        }
        dialogClassName="modal-width-profile"
      />

      <Model
        modalIsOpen={modalIsOpenDelete}
        closeModal={closeModalDelete}
        content={
          <DeleteModal
            closeModalDelete={closeModalDelete}
            title="Product"
            handleDelete={handleDelete}
            loading={loadingDelete}
          />
        }
        dialogClassName="modal-width-30"
      />
    </div>
  );
};

export default ProfessionalProducts;
