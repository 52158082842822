import React from "react";
import Menu from "./menu";
import Header from "./header";
import Footer from "./mobileFooter";
import { UseMediaQuery } from "../hooks/useMediaQuery";

function MobileMenu() {
  const matches = UseMediaQuery("(max-width: 992px)");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <div className="bg-white1 ">
      <div className="flex h-full">
        <Menu />
        <div className="w-full ">
          <Header />
          <div className={`mx-2 mb-2 min-h-screen`}>
            {/* <div className="grid grid-cols-3 mt-4 mb-3 gap-2"> */}
            <div
              className="grid gap-2 xxs:gap-2 xs:gap-2 sm:gap-2 md:gap-3 lg:gap-3 xl:gap-3
            grid-cols-3 xxs:grid-cols-3 xs:grid-cols-3 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7"
            >
              {/* <div className="border border-grey9 rounded-xl bg-white block relative">
                <a href="professionalDashboard">
                  <span className="text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    Dashboard
                  </span>
                  <img src="img/dash1.3.png" className="mobileImg2" />
                </a>
              </div> */}
              {/* <div className="border border-grey9 rounded-xl bg-white block relative">
                <div className="container">
                  <div className="half-circle"></div>
                  <img src="img/dash1.4.png" className="image" />
                </div>
              </div> */}

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="professionalDashboard">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 inline-block">
                    Dashboard
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    src="img/dash1.4.png"
                    className="mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="professionalProfile">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    Edit Profile
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="dashboard"
                    src="img/Profile1.1.png"
                    className="mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              {/* <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="professionalProfile">
                  <span className="text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    Edit Profile
                  </span>
                  <img
                    alt="dashboard"
                    src="img/Profile.png"
                    className="mobileImg2"
                  />
                </a>
              </div> */}

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="myPost">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    My Post
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="/myPost"
                    src="img/post1.2.png"
                    className="mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a
                  href={` ${
                    userInfo && userInfo.profile_name
                      ? userInfo.profile_name
                      : ""
                  } `}
                  className="block"
                >
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    Virtual Profile
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="/myPost"
                    src="img/vp.png"
                    className="mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="appointment">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    Appointments
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    src="img/app1.3.png"
                    alt="appointments"
                    class="mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="addAppointment">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-1 mt-3 mb-[120px] inline-block">
                    Add Appointments
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="appointments"
                    src="img/ua.png"
                    className=" mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="ratingsProfessional" className="block">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    Ratings
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="appointments"
                    src="img/Ratings.png"
                    className="mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="timeSlotProfessional" className="block">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-2 mt-3 mb-[120px] inline-block">
                    Business Hours
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="appointments"
                    src="img/bh.png"
                    className=" mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="stem" className="block">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    STEM Program
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="stem"
                    src="img/sp.png"
                    className=" mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="tax" className="block">
                  <span className="mobileMenuTitle text-black2 text-xs font-semibold px-3 mt-3 mb-[120px] inline-block">
                    Advisory
                  </span>
                  <div className="bgMobileMenu"></div>
                  <img
                    alt="stem"
                    src="img/advs.jpg"
                    className=" mobileImg max-w-[70%] xxs:max-w-[70%] xs:max-w-[70%] sm:max-w-[70%] md:max-w-[55%] lg:max-w-[55%] xl:max-w-[50%]"
                  />
                </a>
              </div>

              {/* <div className="border border-grey9 rounded-xl bg-white block relative mt-3">
                <a href="tutorial" className="block">
                  <span className="text-black2 text-xs font-semibold px-3 mt-3 mb-28 inline-block">
                    Tutorial
                  </span>
                  <span className="inline-block float-right">
                    <img
                      alt="stem"
                      src="img/tutorial2.png"
                      className="max-h-[70px] bottom-0 float-right right-0 absolute"
                    />
                  </span>
                </a>
              </div> */}
            </div>
          </div>

          {matches ? <Footer /> : ""}
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
