// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CheckoutForm.css */

/* .payment-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.InputContainer input {
  border: 1px solid #000;
}
input.InputElement.is-empty.Input.Input--empty {
  border: 1px solid;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
} */

#card-element {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.stripePay button {
  background-color: #007bff !important;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 50px;
}

.stripePay .error-message {
  color: #dc3545;
  margin-top: 10px;
}
.stripePay input {
  border: 1px solid red;
}
.ElementsApp input {
  border: 1px solid red !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/CheckoutForm.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;;;;;;;;;;;;;;;;;;;;;;GAsBG;;AAEH;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,gCAAgC;AAClC","sourcesContent":["/* CheckoutForm.css */\n\n/* .payment-form {\n  max-width: 400px;\n  margin: 0 auto;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n.InputContainer input {\n  border: 1px solid #000;\n}\ninput.InputElement.is-empty.Input.Input--empty {\n  border: 1px solid;\n}\n\n.form-group {\n  margin-bottom: 20px;\n}\n\nlabel {\n  display: block;\n  margin-bottom: 8px;\n} */\n\n#card-element {\n  border: 1px solid #ccc;\n  padding: 10px;\n  border-radius: 4px;\n}\n\n.stripePay button {\n  background-color: #007bff !important;\n  color: #fff;\n  padding: 10px 20px;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  margin-top: 50px;\n}\n\n.stripePay .error-message {\n  color: #dc3545;\n  margin-top: 10px;\n}\n.stripePay input {\n  border: 1px solid red;\n}\n.ElementsApp input {\n  border: 1px solid red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
