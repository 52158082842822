import React, { useState, useEffect } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Model from "./model";
import axios from "axios";
import ScheduleShift from "./scheduleShift";

function MemberList({
  memberData,
  openModalShift,
  openModalUpdate,
  openModalDelete,
}) {
  const matches = UseMediaQuery("(max-width: 992px)");
  const [openSchedule, setOpenSchedule] = useState(false);
  const [memberDataModal, setMemberDataModal] = useState();

  const openModalSchedule = (member) => {
    setMemberDataModal(member);
    setOpenSchedule(true);
  };

  const closeModalSchedule = () => {
    setOpenSchedule(false);
  };

  return (
    <div>
      {memberData &&
        memberData.map((members) => {
          let member = members.item;
          let rating = members.rating;
          return (
            <>
              <div className="rounded border border-grey-9 mt-3">
                <div className="p-3 grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 xxs:grid-cols-1 ">
                  <div className=" inline-flex">
                    <img
                      className="rounded max-w-[100px] max-h-[100px] inline-block"
                      src={
                        member.profile_picture
                          ? member.profile_picture
                          : "img/default.jpg"
                      }
                    />
                    <div className="inline-block ml-6">
                      <div className="font-bold">
                        {member.first_name + " " + member.last_name}
                      </div>
                      <div className="">{member.job_title}</div>
                      <div className="text-sm">
                        <label>{rating ? rating.avgRating : 0}</label>
                        <img
                          src="img/star.png"
                          className="ml-0.5 inline !h-[10px] !w-[10px] align-baseline"
                        />{" "}
                        {rating ? "(" + rating.totalRatings + ")" : ""}
                      </div>
                    </div>
                  </div>
                  <div className={` ${matches ? "mt-3" : "float-right"}`}>
                    <button
                      className={` inline-block ${
                        matches ? "" : "float-right"
                      } text-xs p-2 border border-grey-9 rounded`}
                      onClick={() => openModalSchedule(member)}
                    >
                      Custom Shifts
                    </button>
                    <button
                      className={` inline-block ${
                        matches ? "" : "float-right"
                      } text-xs p-2 border border-grey-9 rounded  mr-1`}
                      onClick={(id, fname) =>
                        openModalShift(member._id, member.first_name)
                      }
                    >
                      Set Regular Shift
                    </button>
                    <button
                      className={`mt-1 align-top inline-block ${
                        matches ? "" : "float-right"
                      } `}
                      onClick={(id) => openModalUpdate(member._id)}
                    >
                      <img
                        alt="user"
                        src="img/edit.png"
                        className={` ${
                          matches
                            ? "h-[24px] w-[24px] ml-[7px]"
                            : "align-top inline-block"
                        }`}
                      />
                    </button>
                    <button
                      className={`mt-1 align-top inline-block ${
                        matches ? "" : "float-right"
                      } `}
                      onClick={(id) => openModalDelete(member._id)}
                    >
                      <img
                        alt="user"
                        src="img/Delete2.png"
                        className={` ${
                          matches ? "" : "align-top inline-block"
                        } h-[17px] w-[17px] ml-[7px] mt-[3px]`}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        })}

      <Model
        modalIsOpen={openSchedule}
        closeModal={closeModalSchedule}
        content={
          <ScheduleShift
            closeModal={closeModalSchedule}
            memberDataModal={memberDataModal}
          />
        }
        dialogClassName="modal-width-80"
      />
    </div>
  );
}

export default MemberList;
