import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { logout } from "../hooks/commonFunctions";

function Header() {
  const API_URL = process.env.REACT_APP_API_URL;
  const matches = UseMediaQuery("(max-width: 992px)");
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleLogout = async () => {
    if (dropdown) {
      setDropdown(false);
    }
    logout();
    //localStorage.removeItem("userInfo");
    //localStorage.removeItem("userToken");
    // document.cookie =
    //   "connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" +
    //   API_URL +
    //   "; secure";
    // try {
    //   const response = await axios.get(API_URL + "user/logout");
    //   if (response) {
    //   }
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }

    //navigate("/");
  };

  if (!userInfo) {
    handleLogout();
  }

  return (
    <div
      className={` ${
        matches ? "sticky top-0 z-50  border-b border-[#F0F1F6]" : ""
      } bg-white`}
    >
      <div
        className={` ${
          matches
            ? "grid grid-cols-2  text-center"
            : "relative  h-[90px] border-b border-grey7  block w-full"
        } px-4 py-2.5`}
      >
        <div
          className={` ${
            matches ? "mt-auto mb-auto text-left " : "welcomeHeader"
          } ivymode`}
        >
          Welcome {matches ? "" : "to Pelito"}
          <img alt="welcome" src="img/Hand.svg" className="inline ml-2" />
        </div>
        <div className="float-right align-super">
          <a href="/wishlist">
            <img
              src="img/wishlist.png"
              className="inline-block h-[25px] w-[25px] mr-1 align-baseline"
            />
          </a>
          <a href="/cart">
            <img
              src="img/cart.png"
              className="inline-block h-[20px] w-[20px] mr-5 align-super"
            />
          </a>
          <div
            className={` ${
              matches ? "w-fit ml-auto" : ""
            } border border-grey7 rounded inline-flex px-2 py-2 cursor-pointer`}
            onClick={() => {
              handleDropdown();
            }}
          >
            <div
              className={` ${
                matches ? "image-container-40" : "image-container-50"
              } mr-2`}
            >
              <img
                alt="profile"
                className={`  rounded`}
                src={` ${
                  userInfo && userInfo.profile_picture
                    ? userInfo.profile_picture
                    : "img/default.jpg"
                }`}
              />
            </div>
            <div className="flex ">
              {!matches ? (
                <div className="mr-2">
                  {userInfo ? (
                    <>
                      <div className="text-base font-semibold">
                        {userInfo.first_Name + " " + userInfo.last_Name}
                      </div>
                      <div className="text-[9px] font-normal">
                        {userInfo.email}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="mt-1">
                <img alt="dots" src="img/dots.png" className="" />
              </div>
            </div>

            {dropdown ? (
              <div
                className="absolute w-[200px] mr-5 right-0 z-10 top-[75px] mt-2 border border-1 border-light-gray origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                <div className="py-2 2xl:py-3" role="none">
                  <a
                    href={` ${
                      userInfo.user_type === "enthusiastic"
                        ? "enthusiastProfile"
                        : userInfo.user_type === "professional"
                        ? "professionalProfile"
                        : "adminProfile"
                    }`}
                    className=" block w-full px-3 2xl:px-5 py-1 text-left text-sm 2xl:text-md font-medium hover:bg-green1 hover:text-[#fff]"
                  >
                    Edit Profile
                  </a>
                  <a
                    href="changePassword"
                    className=" block w-full px-3 2xl:px-5 py-1 text-left text-sm 2xl:text-md font-medium hover:bg-green1 hover:text-[#fff]"
                  >
                    Change Password
                  </a>
                  {userInfo && userInfo.user_type != "admin" ? (
                    <a
                      href="tutorial"
                      className=" block w-full px-3 2xl:px-5 py-1 text-left text-sm 2xl:text-md font-medium hover:bg-green1 hover:text-[#fff]"
                    >
                      Tutorial
                    </a>
                  ) : (
                    ""
                  )}
                  {userInfo && userInfo.user_type === "professional" ? (
                    <a
                      href="inviteUsers"
                      className=" block w-full px-3 2xl:px-5 py-1 text-left text-sm 2xl:text-md font-medium hover:bg-green1 hover:text-[#fff]"
                    >
                      Invite Users
                    </a>
                  ) : (
                    ""
                  )}
                  <button
                    onClick={() => handleLogout()}
                    type="button"
                    className=" block w-full px-3 2xl:px-5 py-1 text-left text-sm 2xl:text-md font-medium hover:bg-green1 hover:text-[#fff]"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-3"
                  >
                    Logout
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Header;
