import React, { useState, useEffect } from "react";
import Spinner from "./spinner";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";
import { UseMediaQuery } from "../hooks/useMediaQuery";

const AddProfessionalServices = ({ closeModal, serviceId }) => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const API_URL = process.env.REACT_APP_API_URL;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [formData, setFormData] = useState({
    service_name: "",
    description: "",
    price: "",
    period: "",
    hashtag: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (serviceId) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setLoadingInit(true);
    try {
      const response = await axios.get(
        API_URL + "service/professional-service/" + serviceId,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        let service = {};
        service["service_name"] = response.data.service_name
          ? response.data.service_name
          : "";
        service["description"] = response.data.description
          ? response.data.description
          : "";
        service["price"] = response.data.price ? response.data.price : "";
        service["period"] = response.data.period ? response.data.period : "";

        setFormData(service);
        setLoadingInit(false);
      }
    } catch (error) {
      setLoadingInit(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "price") {
      //const re = /^\d+(\.\d+)?$/;
      const re = /^(?!,$)[\d,.]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.service_name.trim()) {
      errors.service_name = "Name is required";
    }

    if (!formData.description.trim()) {
      errors.description = "Description is required";
    }
    if (!formData.price) {
      errors.price = "Price is required";
    }

    if (!formData.period.trim()) {
      errors.period = "Period is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      formData["user_id"] = userInfo.user_id;
      if (formData["hashtag"]) {
        formData["hashtag"] = formData["hashtag"]
          .split(",")
          .map((hash) => hash.trim());
      }
      setLoading(true);
      try {
        if (serviceId) {
          await axios
            .put(
              API_URL + "service/professional-service/" + serviceId,
              formData,
              { headers: { authorization: "Bearer " + token } }
            )
            .then(function (response) {
              setLoading(false);
              toastSuccess("Service updated successfully");
              closeModal();
            })
            .catch(function (error) {
              toastError(error.response.data.message || "Service not updated");
              console.log(error);
              setLoading(false);
            });
        } else {
          await axios
            .post(API_URL + "service/professional-service", formData, {
              headers: { authorization: "Bearer " + token },
            })
            .then(function (response) {
              setLoading(false);
              toastSuccess("Service added successfully");
              closeModal();
            })
            .catch(function (error) {
              toastError(error.response.data.message || "Service not added");
              console.log(error);
              setLoading(false);
            });
        }
      } catch (error) {
        setLoading(false);
        toastError(error || "Service not added");
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          {serviceId ? "Update" : "Add"} Service
        </div>
        {!loadingInit ? (
          <form onSubmit={handleSubmit}>
            <label className="text-grey3 text-base w-full">Service Name</label>
            <input
              type="text"
              className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
              placeholder="Schwarzkopf Color Regrowth Application"
              name="service_name"
              value={formData.service_name}
              onChange={handleChange}
            />
            {errors.service_name && (
              <span className="text-red text-xs">{errors.service_name}</span>
            )}

            <label className="text-grey3 text-base w-full mt-3">
              Service Description
            </label>
            <input
              type="text"
              className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
              placeholder="Wash, Cut & Style included"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
            {errors.description && (
              <span className="text-red text-xs">{errors.description}</span>
            )}

            <label className="text-grey3 text-base w-full mt-3">Price</label>
            <div className="block">
              <label
                className={` ${
                  matches ? "w-[10%]" : "w-[5%]"
                } inline-block border border-grey1 text-center p-2 rounded mr-1`}
              >
                $
              </label>
              <input
                type="text"
                className={`${
                  matches ? "w-[88%]" : "w-[93%]"
                } border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base inline-block rounded`}
                placeholder="100"
                name="price"
                value={formData.price}
                // value={
                //   formData.price
                //     ? parseFloat(formData.price).toFixed(2)
                //     : formData.price
                // }
                onChange={handleChange}
              />
            </div>
            {errors.price && (
              <span className="text-red text-xs">{errors.price}</span>
            )}

            <label className="text-grey3 text-base w-full mt-3">
              Service Time Period
            </label>
            <div className="block">
              <label
                className={` ${
                  matches ? "w-[25%]" : "w-[10%]"
                } inline-block border border-grey1 text-center p-2 rounded mr-1`}
              >
                Minutes
              </label>
              <input
                type="text"
                className={` ${
                  matches ? "w-[73%]" : "w-[89%]"
                } inline-block border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base rounded `}
                placeholder="90"
                name="period"
                value={formData.period}
                onChange={handleChange}
              />
            </div>
            {errors.period && (
              <span className="text-red text-xs">{errors.period}</span>
            )}

            <label className="text-grey3 text-base w-full mt-3">Hashtags</label>
            <label className="text-grey3 text-xs w-full ">
              Note : Add hashtags in comma separated
            </label>
            <input
              type="text"
              className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
              placeholder="kid's haircut, women's haircut"
              name="hashtag"
              value={formData.hashtag}
              onChange={handleChange}
            />
            {errors.hashtag && (
              <span className="text-red text-xs">{errors.hashtag}</span>
            )}

            <div className="float-right mt-4">
              {loading ? (
                <Spinner /> // Show the spinner while loading
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => closeModal()}
                    className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                  >
                    Cancel
                  </button>

                  <input
                    type="submit"
                    value={serviceId ? "Update" : "Add"}
                    name="Add"
                    className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                  />
                </>
              )}
            </div>
          </form>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default AddProfessionalServices;
