import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "./spinner";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { toastSuccess, toastError } from "../hooks/toaster";
import timeOptions from "./timeOptions";

const AddMemberShift = ({ closeModal, memberId, memberName, fetchData }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const matches = UseMediaQuery("(max-width: 992px)");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [shiftData, setShiftData] = useState();
  const [formDataAdv, setFormDataAdv] = useState({
    startTimeMonday: "08:00 AM",
    endTimeMonday: "08:00 PM", // "",
    startTimeTuesday: "08:00 AM",
    endTimeTuesday: "08:00 PM", // "",
    startTimeWednesday: "08:00 AM",
    endTimeWednesday: "08:00 PM", // "",
    startTimeThursday: "08:00 AM",
    endTimeThursday: "08:00 PM", // "",
    startTimeFriday: "08:00 AM",
    endTimeFriday: "08:00 PM", // "",
    startTimeSaturday: "08:00 AM",
    endTimeSaturday: "08:00 PM", // "",
    startTimeSunday: "08:00 AM",
    endTimeSunday: "08:00 PM", // "",
  });
  const [dayStatus, setDayStatus] = useState({
    Monday: "on",
    Tuesday: "on",
    Wednesday: "on",
    Thursday: "on",
    Friday: "on",
    Saturday: "on",
    Sunday: "on",
  });
  const [errorTime, setErrorTime] = useState("");

  const dayArr = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  useEffect(() => {
    fetchDataShift();
  }, []);

  const fetchDataShift = async () => {
    try {
      const response = await axios.get(
        API_URL + "slot/shift-for-member/" + memberId,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        let dayTime = {};
        setShiftData(response.data);
        dayArr.forEach((day) => {
          const index = response.data.findIndex(
            (user) => user.shift_day === day
          );
          if (index >= 0) {
            if (
              response.data[index].on_off &&
              response.data[index].on_off === "off"
            ) {
              setDayStatus((prevSchedule) => ({
                ...prevSchedule,
                [day]: response.data[index].on_off,
              }));
            } //else {
            let name = "startTime" + day;
            let nameEnd = "endTime" + day;
            dayTime[name] = response.data[index].start_time;
            dayTime[nameEnd] = response.data[index].end_time;

            setFormDataAdv((prevSchedule) => ({
              ...prevSchedule,
              [name]: dayTime[name],
            }));

            setFormDataAdv((prevSchedule) => ({
              ...prevSchedule,
              [nameEnd]: dayTime[nameEnd],
            }));
            // }
          }
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleStartTimeChangeAdv = (e, day) => {
    // const { name, value } = e.target;
    const name = e.name + day;
    setFormDataAdv({ ...formDataAdv, [name]: e.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {};
    let dataEdit = {};
    let advc = [];
    let advcEdit = [];

    dayArr.forEach((day) => {
      let advDay = {};
      if (formDataAdv["startTime" + day] && formDataAdv["endTime" + day]) {
        advDay["salon_id"] = userInfo.user_id;
        advDay["member_id"] = memberId;

        advDay["start_time"] = formDataAdv["startTime" + day];
        advDay["end_time"] = formDataAdv["endTime" + day];
        const shiftday = shiftData.findIndex((user) => user.shift_day === day); //check if entry already exist then update it

        if (shiftday > -1) {
          // if true then add it into update Array
          advDay["slot_day"] = day;
          advDay["shift_id"] = shiftData[shiftday]._id;
          advcEdit.push(advDay);
        } else {
          // if false then add it to Add array
          advDay["shift_day"] = day;
          advc.push(advDay);
        }
      }
    });
    data["data"] = advc;
    dataEdit["data"] = advcEdit;
    //return true;
    if (advc.length > 0) {
      //add
      try {
        await axios
          .post(API_URL + "slot/add-slot", data, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            toastSuccess("Time Slot updated successfully");
            setLoading(false);
            closeModal();
          })
          .catch(function (error) {
            toastError(error.response.data.message || "Time Slot not updated");
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
      }
    }

    if (advcEdit.length > 0) {
      //edit
      try {
        await axios
          .put(API_URL + "slot/shift-for-member", dataEdit, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            toastSuccess("Time Slot updated successfully");
            setLoading(false);
            closeModal();
          })
          .catch(function (error) {
            toastError(error.response.data.message || "Time Slot not updated");
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
      }
    }

    setLoading(false);
  };

  const getTimeValue = (type, dayName) => {
    let name = type + dayName;
    return formDataAdv[name];
  };

  const handleOnOff = async (e, day) => {
    const isChecked = e.target.checked; // Get the checked state of the checkbox
    setDayStatus({ ...dayStatus, [day]: isChecked ? "on" : "off" }); // Set dayStatus based on isChecked

    let data = {};
    data["member_id"] = memberId;
    data["day"] = day;
    data["on_off"] = isChecked ? "on" : "off";

    setLoading(true);

    try {
      await axios
        .put(API_URL + "working-hours/set-on-off", data, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          toastSuccess(day + " updated successfully");
          fetchDataShift();
          setLoading(false);
        })
        .catch(function (error) {
          toastError(error.response.data.message || day + " not updated");
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  function switchOnOff(dayName) {
    return (
      <div
        className={` ${
          matches ? "inline-flex" : "mt-1"
        } col-span-1 inline-block`}
      >
        <div className="inline-block float-right">
          <label class="switch ml-2 align-middle">
            <input
              type="checkbox"
              //checked={dayStatus[dayName] === "on" ? "checked" : ""}
              checked={dayStatus[dayName] === "on"}
              onClick={(e) => handleOnOff(e, dayName)}
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    );
  }

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="block border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          <div className="inline-block">Set {memberName}'s Regular Shift</div>
        </div>

        <div className="">
          {matches ? (
            ""
          ) : (
            <label className="float-right text-lg lg:text-lg md:text-lg sm:text-sm xs:text-sm xxs:text-xs">
              Day On/Off
            </label>
          )}
          <form className="inline-block w-full" onSubmit={handleSubmit}>
            {dayArr.map((dayName) => {
              return (
                <div className="mt-2 grid grid-cols-6 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-6 xs:grid-cols-1 xxs:grid-cols-1">
                  <div className="mt-1 col-span-1">
                    <label
                      htmlFor="startTime"
                      className="text-lg font-bold inline-block"
                    >
                      {dayName} :
                    </label>
                    {matches ? switchOnOff(dayName) : ""}
                  </div>
                  <div className="mt-1 col-span-2">
                    <label htmlFor="startTime" className="text-lg">
                      Start Time :
                    </label>
                    <select
                      name="startTime"
                      className="ml-2 p-2 border border-grey-9 rounded"
                      id="startTime"
                      value={getTimeValue("startTime", dayName)}
                      onChange={(e, day) =>
                        handleStartTimeChangeAdv(e.target, dayName)
                      }
                    >
                      {timeOptions.map((time, index) => (
                        <option key={index} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                    {errors.startTime && (
                      <span className="text-red text-xs block">
                        {errors.startTime}
                      </span>
                    )}
                  </div>

                  <div className="mt-1 col-span-2">
                    <div className="">
                      <label htmlFor="endTime" className="text-lg">
                        End Time :
                      </label>
                      <select
                        name="endTime"
                        className="ml-2 p-2 border border-grey-9 rounded"
                        id="endTime"
                        value={getTimeValue("endTime", dayName)}
                        //onChange={handleStartTimeChangeAdv}
                        onChange={(e, day) =>
                          handleStartTimeChangeAdv(e.target, dayName)
                        }
                      >
                        {timeOptions.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                      {errors.endTime && (
                        <span className="text-red text-xs block">
                          {errors.endTime}
                        </span>
                      )}
                    </div>
                  </div>

                  {!matches ? switchOnOff(dayName) : ""}

                  {/* <div className="mt-1 col-span-1">
                    <div className="inline-block float-right">
                      <label class="switch ml-2 align-middle">
                        <input
                          type="checkbox"
                          //checked={dayStatus[dayName] === "on" ? "checked" : ""}
                          checked={dayStatus[dayName] === "on"}
                          onClick={(e) => handleOnOff(e, dayName)}
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div> */}
                </div>
              );
            })}

            <div className="float-right mt-4">
              {loading ? (
                <Spinner /> // Show the spinner while loading
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => closeModal()}
                    className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                  >
                    Cancel
                  </button>
                  <input
                    type="submit"
                    value="Update"
                    name="Update"
                    className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                  />
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddMemberShift;
