import React, { useState, useRef } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Spinner from "./spinner";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";
import { replaceLinks } from "../hooks/commonFunctions";

function ProfessionalCreatePost({ fetchData }) {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const matches = UseMediaQuery("(max-width: 992px)");
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [textareaHeight, setTextAreaHeight] = useState("auto");
  const inputRef = useRef();
  const inputRefV = useRef();
  const [imgUrls, setImgUrls] = useState([]);
  const [vidUrls, setVidUrls] = useState([]);
  const [count, setCount] = useState(0);
  const [imgErr, setImgErr] = useState(false);
  const [vidErr, setVidErr] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    //const formattedText = value.replace(/\n/g, "<br />");
    const words = value.trim().split(/\s+/);
    if (words.length <= 200) {
      setText(value);

      // Calculate the height of the textarea based on its content
      const lineHeight = 18; // Adjust this value according to your font size
      const rows = value.split("\n").length;
      setTextAreaHeight(`${lineHeight * rows}px`);
    }
  };

  const handleImg = async () => {
    const files = inputRef.current.files;

    if (files.length > 0) {
      setLoading(true);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 5242880) {
          setImgErr(true);
          setLoading(false);
        } else {
          formData.append("media", files[i]);
        }
      }

      await axios
        .post(API_URL + "upload/multi-upload", formData, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          let arr = imgUrls ? imgUrls : [];
          setLoading(false);
          response.data.forEach((item) => {
            arr.push(item.media);
          });
          setImgUrls(arr);
          setCount(count + 1);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const handleVideo = async () => {
    const files = inputRefV.current.files;

    if (files.length > 0) {
      setLoading(true);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 50 * 1024 * 1024) {
          setVidErr(true);
          setLoading(false);
        } else {
          formData.append("media", files[i]);
        }
      }

      await axios
        .post(API_URL + "upload/multi-upload", formData, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          let arr = vidUrls ? vidUrls : [];
          setLoading(false);
          response.data.forEach((item) => {
            arr.push(item.media);
          });
          setVidUrls(arr);
          setCount(count + 1);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const handleSubmit = async () => {
    if (text || imgUrls.length > 0 || vidUrls.length > 0) {
      setLoading(true);
      try {
        let formData = {};
        formData["user_id"] = userInfo.user_id;
        if (text) {
          const replacedText = replaceLinks(text);
          formData["caption"] = replacedText;
        }
        if (imgUrls.length > 0) {
          let arr = [];
          imgUrls.forEach((item) => {
            arr.push(item);
          });

          formData["image_url"] = arr;
        }

        if (vidUrls.length > 0) {
          let arr = [];

          vidUrls.forEach((item) => {
            arr.push(item);
          });

          formData["video_url"] = arr;
        }
        await axios
          .post(API_URL + "post/professional-post", formData, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            setLoading(false);
            toastSuccess("Post created successfully");
            setImgUrls([]);
            setVidUrls([]);
            setText("");
            setTextAreaHeight("auto");
            fetchData("create");
          })
          .catch(function (error) {
            toastError(error.response.data.message || "Post not created");
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        toastError(error || "Post not created");
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="bg-white rounded border border-grey7">
      <div className="p-4">
        <div className="flex ">
          <div
            className={` ${
              matches ? "image-container-44" : "image-container-40"
            } `}
          >
            <img
              alt="user_profile"
              src={` ${
                userInfo && userInfo.profile_picture
                  ? userInfo.profile_picture
                  : "img/default.jpg"
              }`}
              className="rounded-full inline"
            />
          </div>
          <textarea
            style={{ height: textareaHeight, minHeight: "65px" }}
            value={text}
            onChange={handleChange}
            className={` ${
              matches ? "ml-1" : "ml-2"
            } w-full  p-2 inline bg-white1 rounded`}
            placeholder="What's on your mind"
          />
        </div>
        <div className="flow-root mt-3 block">
          <>
            <div className="inline-block align-sub">
              <button type="button">
                <div className="file-container addWork">
                  <img className="inline" src="img/img.png" alt="upload" />
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    id="fileInput"
                    ref={inputRef}
                    onChange={handleImg}
                    className="file-input"
                  />
                </div>
              </button>
              <button type="button">
                <div className="file-container addWork">
                  <img
                    className="inline ml-2"
                    src="img/video.png"
                    alt="video"
                  />
                  <input
                    type="file"
                    accept="video/*"
                    multiple
                    id="fileInput"
                    ref={inputRefV}
                    onChange={handleVideo}
                    className="file-input"
                  />
                </div>
              </button>
            </div>
            <div className="inline-block float-right">
              <button
                onClick={() => handleSubmit()}
                type="button"
                className="bg-black2 text-white px-3 py-2 rounded text-lg lg:text-lg md:text-lg sm:text-lg xxs:text-sm shadowEffect hover:opacity-60"
              >
                Create a Post
              </button>
            </div>
            {imgErr ? (
              <div className="text-xs text-red mt-2">
                Image more than 5 MB will not get uploaded
              </div>
            ) : (
              ""
            )}
            {vidErr ? (
              <div className="text-xs text-red mt-2">
                Video more than 50 MB will not get uploaded
              </div>
            ) : (
              ""
            )}
          </>
          {loading ? (
            <div className="mt-[-43px]">
              <Spinner />
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="">
          {imgUrls ? (
            <div className="block mt-3">
              {imgUrls.map((media) => {
                return (
                  <div className="inline-block mr-2">
                    <img
                      src={media}
                      className="w-[115px] h-[115px]"
                      alt="postImage"
                    />{" "}
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="">
          {vidUrls ? (
            <div className="block mt-3">
              {vidUrls.map((media) => {
                return (
                  <div className="inline-block mr-2">
                    <video controls width="200" height="150">
                      <source src={media} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfessionalCreatePost;
