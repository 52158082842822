import React, { useState, useEffect } from "react";
import timeOptions from "./timeOptions";
import Spinner from "./spinner";
import { toastSuccess, toastError } from "../hooks/toaster";
import axios from "axios";

function ContactHoursAdd({ closeModal, hours, fetchData }) {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorTime, setErrorTime] = useState("");
  const [formDataAdv, setFormDataAdv] = useState({
    startTimeMonday: "10:00 AM",
    endTimeMonday: "07:00 PM",
    startTimeTuesday: "10:00 AM",
    endTimeTuesday: "07:00 PM",
    startTimeWednesday: "10:00 AM",
    endTimeWednesday: "07:00 PM",
    startTimeThursday: "10:00 AM",
    endTimeThursday: "07:00 PM",
    startTimeFriday: "10:00 AM",
    endTimeFriday: "07:00 PM",
    startTimeSaturday: "10:00 AM",
    endTimeSaturday: "07:00 PM",
    startTimeSunday: "10:00 AM",
    endTimeSunday: "07:00 PM",
  });

  useEffect(() => {
    setData();
  }, []);

  const setData = () => {
    if (hours && hours.length > 0) {
      let dayTime = {};
      dayArr.forEach((day) => {
        const index = hours.findIndex((user) => user.day === day);
        if (index >= 0) {
          let name = "startTime" + day;
          let nameEnd = "endTime" + day;

          const timeString = hours[index].time; //"10:00 AM - 07:00 PM";

          // Using regular expression to extract start and end times
          const regex = /(\d{2}:\d{2} [APMapm]+)/g;
          const [startTime, endTime] = timeString.match(regex);
          dayTime[name] = startTime;
          dayTime[nameEnd] = endTime;

          setFormDataAdv((prevSchedule) => ({
            ...prevSchedule,
            [name]: dayTime[name],
          }));

          setFormDataAdv((prevSchedule) => ({
            ...prevSchedule,
            [nameEnd]: dayTime[nameEnd],
          }));
        }
      });
    }
  };

  const dayArr = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const getTimeValue = (type, dayName) => {
    let name = type + dayName;
    return formDataAdv[name];
  };

  const handleStartTimeChangeAdv = (e, day) => {
    // const { name, value } = e.target;
    const name = e.name + day;
    setFormDataAdv({ ...formDataAdv, [name]: e.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let dataEdit = {};
    let advcEdit = [];

    dayArr.forEach((day) => {
      let status = "add";
      let advDay = {};
      const shiftday = hours
        ? hours.findIndex((user) => user.day === day)
        : "-1"; //check if entry already exist then update it
      if (formDataAdv["startTime" + day] && formDataAdv["endTime" + day]) {
        advDay["time"] =
          formDataAdv["startTime" + day] + " - " + formDataAdv["endTime" + day];

        if (shiftday > -1) {
          // if true then add it into update Array
          // advDay["day"] = day;
          // advcEdit.push(advDay);
          dataEdit = {
            day: day,
            time:
              formDataAdv["startTime" + day] +
              " - " +
              formDataAdv["endTime" + day],
          };
          status = "edit";
          editHour(dataEdit, hours[shiftday]._id);
        } else {
          // if false then add it to Add array
          advDay["user_id"] = userInfo.user_id;
          advDay["day"] = day;
          addHour(advDay);
        }
      }

      //dataEdit = advcEdit;

      if (advDay && status === "add") {
        //add
        addHour(advDay);
      }
      // if (advcEdit.length > 0 && status === "edit") {
      //   //edit
      //   editHour(dataEdit, hours[shiftday]._id);
      // }
    });
    toastSuccess("Time updated successfully");
    setLoading(false);
  };

  const addHour = async (data) => {
    try {
      await axios
        .post(API_URL + "working-hours", data, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          setLoading(false);
          fetchData();
          closeModal();
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Time not updated");
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const editHour = async (dataEdit, id) => {
    try {
      await axios
        .put(API_URL + "working-hours/" + id, dataEdit, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          setLoading(false);
          fetchData();
          closeModal();
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Time not updated");
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="block border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          <div className="inline-block">Set Contact Hours</div>
        </div>
        <form onSubmit={handleSubmit}>
          {dayArr.map((dayName) => {
            return (
              <div className="mt-2 grid grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-5 xs:grid-cols-1 xxs:grid-cols-1">
                <div className="mt-1 col-span-1">
                  <label htmlFor="startTime" className="text-lg font-bold">
                    {dayName} :
                  </label>
                </div>
                <div className="mt-1 col-span-2">
                  <label htmlFor="startTime" className="text-lg">
                    Start Time :
                  </label>
                  <select
                    name="startTime"
                    className="ml-2 p-2 border border-grey-9 rounded"
                    id="startTime"
                    value={getTimeValue("startTime", dayName)}
                    onChange={(e, day) =>
                      handleStartTimeChangeAdv(e.target, dayName)
                    }
                  >
                    {timeOptions.map((time, index) => (
                      <option key={index} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                  {errors.startTime && (
                    <span className="text-red text-xs block">
                      {errors.startTime}
                    </span>
                  )}
                </div>

                <div className="mt-1 col-span-2">
                  <div className="">
                    <label htmlFor="endTime" className="text-lg">
                      End Time :
                    </label>
                    <select
                      name="endTime"
                      className="ml-2 p-2 border border-grey-9 rounded"
                      id="endTime"
                      value={getTimeValue("endTime", dayName)}
                      //onChange={handleStartTimeChangeAdv}
                      onChange={(e, day) =>
                        handleStartTimeChangeAdv(e.target, dayName)
                      }
                    >
                      {timeOptions.map((time, index) => (
                        <option key={index} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                    {errors.endTime && (
                      <span className="text-red text-xs block">
                        {errors.endTime}
                      </span>
                    )}
                  </div>
                </div>
                {errorTime && (
                  <p
                    style={{
                      display: "block",
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {errorTime}
                  </p>
                )}
              </div>
            );
          })}

          <div className="float-right mt-4">
            {loading ? (
              <Spinner /> // Show the spinner while loading
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                >
                  Cancel
                </button>
                <input
                  type="submit"
                  value="Update"
                  name="Update"
                  className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                />
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactHoursAdd;
