import React from "react";
import ProfessionalMenu from "./professionalMenu";
import EnthusiastMenu from "./enthusiastMenu";
import AdminMenu from "./adminMenu";

function Menu() {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : "";

  return (
    <div className="bg-white">
      {userInfo.user_type === "enthusiastic" ? (
        <EnthusiastMenu />
      ) : userInfo.user_type === "professional" ? (
        <ProfessionalMenu />
      ) : (
        <AdminMenu />
      )}
    </div>
  );
}

export default Menu;
