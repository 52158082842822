import React, { useState } from "react";
import {
  SegmentedGroup,
  SegmentedItem,
  Datepicker,
} from "@mobiscroll/react-lite";
import CalendarNav from "./CalendarNav"; // Import CalendarNav component
import CalendarPrev from "./CalendarPrev"; // Import CalendarPrev component
import CalendarNext from "./CalendarNext"; // Import CalendarNext component

const StartRating = () => {
  const [calendarType, setCalendarType] = useState("week");

  const changeView = (event) => {
    setCalendarType(event.target.value);
  };

  const calendarHeader = () => (
    <React.Fragment>
      <CalendarNav className="custom-view-nav" />
      <div className="custom-view">
        <SegmentedGroup value={calendarType} onChange={changeView}>
          <SegmentedItem value="week" icon="material-date-range" />
          <SegmentedItem value="month" icon="material-event-note" />
        </SegmentedGroup>
      </div>
      <CalendarPrev />
      <CalendarNext />
    </React.Fragment>
  );

  return (
    <>
      {console.log(calendarType, "typppp")}
      <Datepicker
      //controls={["calendar"]}
      // calendarType={calendarType}
      // calendarSize={1}
      // renderCalendarHeader={calendarHeader}
      />
    </>
  );
};

export default StartRating;
