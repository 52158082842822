import React from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Header from "./header";
import Footer from "./mobileFooter";

function MySalons() {
  const matches = UseMediaQuery("(max-width: 992px)");
  const salons = [];

  for (let i = 0; i < 5; i++) {
    salons.push(
      <div className="mt-3">
        <div className="border border-grey7 rounded px-2 py-2 block relative">
          <div className="inline-block align-sub">
            <img
              alt="user"
              src="img/img1.png"
              className="rounded border border-grey7  w-[80px] h-[80px]"
            />
          </div>
          <div className="inline-block ml-2">
            <div className="text-xs text-black2 font-semibold">Uptown Hair</div>
            <div className="text-[10px] text-grey3">
              In the cut barber lounge 5.4 mi
            </div>
            <div className="text-[8px] text-grey3 mt-1">
              <img
                alt="user"
                src="img/rating.png"
                className="inline mr-1 h-[10px]"
              />
              5.0 (1000)
            </div>

            <div className="block mt-1.5">
              <div className="inline">
                <div className="inline border border-grey9 p-1.5 rounded bg-grey10 text-[9px]">
                  Men’s haircut
                </div>
                <div className="inline border border-grey9 p-1.5 ml-2 rounded bg-grey10 text-[9px]">
                  Kid haircut
                </div>
                <div className="inline border border-grey9 p-1.5 ml-2 rounded bg-grey10 text-[9px]">
                  15+
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block absolute bottom-1 right-3">
            <button className="inline float-right bg-green1 px-2.5 py-1.5 rounded text-[9px] text-white">
              Book
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {matches ? <Header /> : ""}
      <div
        className={` ${
          matches ? "" : "border border-grey7"
        } bg-white rounded p-4`}
      >
        {/* {matches ? (
        <>
          <img alt="user" src="img/larr.png" className="inline" />
          <button
            className="text-sm font-semibold mb-3 ml-1.5"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </>
      ) : (
        ""
      )} */}

        <div className="block">
          <div className="inline text-base font-semibold">My Salons</div>
          <img alt="user" src="img/HeartP.png" className="inline float-right" />
        </div>

        {salons}
      </div>
      {matches ? <Footer /> : ""}
    </>
  );
}

export default MySalons;
