import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import Spinner from "./spinner";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";

const RateService = ({ closeModal, rateBooking }) => {
  const token = localStorage.getItem("userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [rating, setRating] = useState(0);
  const [ratingSalon, setRatingSalon] = useState(0);
  const [comment, setComment] = useState();
  const [loading, setLoading] = useState(false);

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleReset = () => {
    setRating(0);
  };

  const handleRatingSalon = (rate) => {
    setRatingSalon(rate);
  };

  const handleResetSalon = () => {
    setRatingSalon(0);
  };

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = {};
    let formData1 = {};
    formData["salon_id"] = rateBooking.seller_user_id._id;
    formData["user_id"] = userInfo.user_id;
    formData["type"] = "salon";
    formData["rating"] = ratingSalon;
    formData["text"] = comment;

    formData1["member_id"] = rateBooking.member_id._id;
    formData1["user_id"] = userInfo.user_id;
    formData1["type"] = "member";
    formData1["rating"] = rating;

    setLoading(true);
    try {
      await axios
        .post(API_URL + "rating", formData, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          toastSuccess("Ratings added successfully");
          setLoading(false);
          closeModal();
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Ratings not updated");
          console.log(error);
          closeModal();
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }

    try {
      await axios
        .post(API_URL + "rating", formData1, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          Add Ratings
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label className=" text-lg w-full mt-3">
              Rate{" "}
              {rateBooking.member_id.first_name +
                " " +
                rateBooking.member_id.last_name}
            </label>

            <Rating
              onClick={handleRating}
              initialValue={rating}
              allowFraction={true}
            />

            <button className="ml-1" onClick={handleReset}>
              Reset
            </button>
          </div>

          <div className="mt-1">
            <label className=" text-lg w-full mt-3">
              Rate {rateBooking.seller_user_id.salon_name}
            </label>

            <Rating
              onClick={handleRatingSalon}
              initialValue={ratingSalon}
              allowFraction={true}
            />

            <button className="ml-1" onClick={handleResetSalon}>
              Reset
            </button>
          </div>

          <div className="">
            <label className="text-grey3 text-base w-full mt-3">
              Add Comment
            </label>
            <input
              type="text"
              className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
              placeholder="Comment "
              name="email_array"
              value={comment}
              onChange={handleComment}
            />
          </div>

          <div className="float-right mt-4">
            {loading ? (
              <Spinner /> // Show the spinner while loading
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                >
                  Cancel
                </button>
                <input
                  type="submit"
                  value="Submit"
                  name="Update"
                  className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                />
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RateService;
