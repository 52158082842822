import React from "react";

function TermsCondition({ closeModal }) {
  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          Terms and Conditions
        </div>
        <div className="">
          Welcome to{" "}
          <a className="underline" target="_blank" href="https://pelito.net/">
            Pelito.net!
          </a>{" "}
          These terms and conditions govern your use of our platform and
          services. Please read them carefully before accessing or using{" "}
          <a className="underline" target="_blank" href="https://pelito.net/">
            Pelito.net
          </a>
          .
          <div className="mt-3">
            1. Acceptance of Terms: By accessing or using{" "}
            <a className="underline" target="_blank" href="https://pelito.net/">
              Pelito.net
            </a>
            , you agree to be bound by these terms and conditions. If you do not
            agree with any part of these terms, you may not use our platform.
          </div>
          <div className="mt-3">
            2. Use of{" "}
            <a className="underline" target="_blank" href="https://pelito.net/">
              Pelito.net
            </a>
            : You may use{" "}
            <a className="underline" target="_blank" href="https://pelito.net/">
              Pelito.net{" "}
            </a>{" "}
            for lawful purposes only. You must not violate any laws, infringe on
            any rights, or engage in any activities that could harm Pelito.net
            or its users.
          </div>
          <div className="mt-3">
            3. User Content: By uploading content to{" "}
            <a className="underline" target="_blank" href="https://pelito.net/">
              Pelito.net
            </a>
            , you grant us a non-exclusive, royalty-free license to use,
            reproduce, modify, adapt, publish, translate, and distribute the
            content. You retain ownership of your content, and Pelito.net will
            not claim any rights to it.
          </div>
          <div className="mt-3">
            4. Privacy: We respect your privacy and are committed to protecting
            your personal information. Our Privacy Policy outlines how we
            collect, use, and disclose your information. By using{" "}
            <a className="underline" target="_blank" href="https://pelito.net/">
              Pelito.net
            </a>
            , you consent to the terms of our Privacy Policy.
          </div>
          <div className="mt-3">
            5. Intellectual Property: All content and materials on{" "}
            <a className="underline" target="_blank" href="https://pelito.net/">
              Pelito.net
            </a>
            , including text, graphics, logos, images, and software, are the
            property of{" "}
            <a className="underline" target="_blank" href="https://pelito.net/">
              Pelito.net
            </a>{" "}
            or its licensors and are protected by copyright and other
            intellectual property laws.
          </div>
          <div className="mt-3">
            6. Limitation of Liability:{" "}
            <a className="underline" target="_blank" href="https://pelito.net/">
              Pelito.net
            </a>{" "}
            is provided on an “as is” and “as available” basis. We make no
            warranties or representations about the accuracy, reliability, or
            completeness of the platform. In no event shall{" "}
            <a className="underline" target="_blank" href="https://pelito.net/">
              Pelito.net
            </a>{" "}
            be liable for any indirect, incidental, special, or consequential
            damages arising out of or in connection with your use of the
            platform.
          </div>
          <div className="mt-3">
            7. Dispute Resolution: Any disputes arising from these terms and
            conditions or your use of{" "}
            <a className="underline" target="_blank" href="https://pelito.net/">
              Pelito.net
            </a>{" "}
            shall be resolved through arbitration in the state of Rhode Island,
            in accordance with the rules of the American Arbitration
            Association.
          </div>
          <div className="mt-3">
            8. Governing Law: These terms and conditions shall be governed by
            and construed in accordance with the laws of the state of Rhode
            Island, without regard to its conflict of law provisions.
          </div>
          <div className="mt-3">
            9. Severability: If any provision of these terms and conditions is
            found to be invalid or unenforceable, the remaining provisions shall
            remain in full force and effect.
          </div>
          <div className="mt-3">
            10. Effective Date: These terms and conditions are effective as of
            January 1, 2024We may update or modify these terms from time to
            time, so please check back periodically for any changes.
          </div>
          <div className="mt-5">
            By using{" "}
            <a className="underline" target="_blank" href="https://pelito.net/">
              Pelito.net
            </a>
            , you agree to abide by these terms and conditions. If you have any
            questions or concerns, please contact us at support@pelito.net
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsCondition;
