import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import axios from "axios";
import Spinner from "./spinner";
import { toastSuccess, toastError } from "../hooks/toaster";
import AddInviteUsers from "./addInviteUser";
import Model from "./model";

function InviteUsers() {
  const matches = UseMediaQuery("(max-width: 992px)");
  const token = localStorage.getItem("userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  const FRONT_URL = process.env.REACT_APP_FRONT_URL;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [invites, setInvites] = useState();

  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [modalIsOpenDelete, setIsOpenModalDelete] = useState(false);
  const [bodyStyle, setBodyStyle] = useState("break-spaces");

  useEffect(() => {
    fetchData();
    if (matches) {
      setBodyStyle("pre");
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL + "user/get-all-invites/" + userInfo.user_id,
        {
          headers: { authorization: "Bearer " + token },
        }
      );
      if (response) {
        setInvites(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    fetchData();
    setIsOpenModal(false);
  };

  const copyToClipboard = (text) => {
    const textToCopy = text.replace(/<a\b[^>]*>(.*?)<\/a>/g, "$1");
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toastSuccess("Text Copied");
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
        alert("Failed to copy text to clipboard.");
      });
  };

  return (
    <div>
      <div className="bg-white1 ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div
              className={` ${
                matches ? "mx-2 my-2 min-h-screen" : "mx-4 my-4"
              } `}
            >
              <div className="">
                <div className="inline-block font-bold ">
                  Invite Users
                  {userInfo && userInfo.profile_name ? (
                    <button
                      onClick={() =>
                        copyToClipboard(FRONT_URL + userInfo.profile_name)
                      }
                      className="ml-2 font-normal text-xs p-2 rounded border border-grey9 bg-white"
                    >
                      Copy Virtual Profile Link
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="inline-block float-right">
                  <button onClick={() => openModal()}>
                    <img src="img/Add2.png" alt="user" />
                  </button>
                </div>
              </div>
              {invites && invites.length > 0 ? (
                <div class="bg-white mt-3 mb-3 rounded flex flex-col overflow-x-auto">
                  <div class="sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                      <div class="overflow-x-auto">
                        <table class="min-w-full text-left text-sm font-light">
                          <thead class="border-b font-medium dark:border-neutral-500">
                            <tr>
                              <th scope="col" class="px-6 py-4">
                                Recipient
                              </th>
                              <th scope="col" class="px-6 py-4">
                                Subject
                              </th>
                              <th scope="col" class="px-6 py-4">
                                Body
                              </th>
                              {/* <th></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {invites &&
                              invites.map((invite) => {
                                return (
                                  <tr class="border-b dark:border-neutral-500">
                                    <td
                                      style={{
                                        whiteSpace: "pre",
                                        wordBreak: "break-all",
                                      }}
                                      class="whitespace-nowrap px-6 py-2"
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            invite.email_array.join(",\n"),
                                        }}
                                      />
                                    </td>

                                    <td class="whitespace-nowrap px-6 py-2">
                                      {invite.subject}
                                    </td>
                                    <td
                                      style={{
                                        whiteSpace: bodyStyle,
                                        wordBreak: "break-all",
                                      }}
                                      class="whitespace-nowrap px-6 py-2 max-w-[100%]"
                                    >
                                      <button
                                        onClick={() =>
                                          copyToClipboard(invite.body_html)
                                        }
                                        className="p-2 rounded border border-grey9 bg-white"
                                      >
                                        Copy Text
                                      </button>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: invite.body_html,
                                        }}
                                      />
                                    </td>
                                    {/* <td class="whitespace-nowrap px-6 py-2">
                                      <button
                                        onClick={() =>
                                          copyToClipboard(invite.body_html)
                                        }
                                        className="p-2 rounded border border-grey9 bg-white"
                                      >
                                        Copy Text
                                      </button>
                                    </td> */}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>
      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={<AddInviteUsers closeModal={closeModal} />}
        dialogClassName="modal-width-profile"
      />
    </div>
  );
}

export default InviteUsers;
