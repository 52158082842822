import React, { useState } from "react";
import TermsCondition from "./termsConditions";
import Model from "./model";

function OuterFooter() {
  const [modalIsOpen, setIsOpenModal] = useState(false);

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <div className="">
      <div>
        <img alt="pelito" src="img/home7.png" />
      </div>

      <div>
        <div className="justify-center flex bg-white lg:mt-[-115px] md:mt-[-95px] sm:mt-[-85px] xs:mt-[-75px] ">
          <div className="text-center font-normal lg:text-2xl md:text-2xl sm:text-xl xs:text-lg xxs:text-lg bg-white">
            <img alt="logo" src="img/logo.png" className=" inline-block" />
            <div className="ivymode font-normal text-5xl mt-8 justify-center flex">
              <img
                alt="pelito"
                src="img/home8.png"
                className="lg:w-auto md:w-72 sm:w-64 xs:w-64 xxs:w-64"
              />
            </div>
            <div className="mt-6">
              support@pelito.com
              {/* <img alt="phone" src="img/phone.png" className="inline h-7 w-7" />
              <span className="inline ml-2">(308) 555-0121</span> */}
            </div>
            {/* <div className="mt-6">
              <span className="mt-2">
                2972 Westheimer Rd. Santa Ana, <br /> Illinois 85486
              </span>
            </div> */}
            <div className="mt-8">
              <img alt="fb" src="img/fb1.png" className="inline mr-4" />
              <img alt="insta" src="img/insta1.png" className="inline mr-4" />
              <img
                alt="linkedin"
                src="img/linkedin.png"
                className="inline mr-4"
              />
              <img alt="youtube" src="img/YouTube.png" className="inline" />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20 mb-10 text-center text-grey2">
        Copyright © 2023 | All Rights Reserved |{" "}
        <span className="text-grey3">
          <button onClick={() => openModal()}>Terms and Conditions</button> |
          Privacy Policy
        </span>
      </div>

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={<TermsCondition closeModal={closeModal} />}
        dialogClassName="modal-width-profile"
      />
    </div>
  );
}

export default OuterFooter;
