import React from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Header from "./header";
import Footer from "./mobileFooter";

function UpcomingAppointment() {
  const matches = UseMediaQuery("(max-width: 992px)");

  return (
    <>
      {matches ? <Header /> : ""}
      <div
        className={` ${
          matches ? "" : "border border-grey7"
        } bg-white rounded p-4`}
      >
        <div className="block">
          <div className="inline text-base font-semibold">
            Upcoming Appointments
          </div>
          <div className="inline float-right">
            <img alt="user" src="img/larr.png" className="inline" />
            <img alt="user" src="img/rarr.png" className="inline ml-3" />
          </div>
        </div>

        <div className="border-b border-grey8 mt-3"></div>

        <div className="text-xs grid grid-cols-7  gap-2 py-3">
          <div className="inline-block bg-white1 border border-grey9 py-2 px-2.5 text-center">
            <div className="block text-green1">Thu</div>
            <div className="block text-grey3 mt-1">23</div>
          </div>
          <div className="inline-block bg-white1 border border-grey9 py-2 px-2.5 text-center">
            <span className="block text-green1">Fri</span>
            <span className="block text-grey3 mt-1">24</span>
          </div>
          <div className="inline-block bg-white1 border border-grey9 py-2 px-2.5 text-center ">
            <div className="block text-green1">Sat</div>
            <div className="block text-grey3 mt-1">25</div>
          </div>
          <div className="inline-block bg-white1 border border-grey9 py-2 px-2.5 text-center ">
            <div className="block text-green1">Sun</div>
            <div className="block text-grey3 mt-1">26</div>
          </div>
          <div className="inline-block bg-white1 border border-grey9 py-2 px-2.5 text-center ">
            <div className="block text-green1">Mon</div>
            <div className="block text-grey3 mt-1">27</div>
          </div>
          <div className="inline-block bg-green1 border border-grey9 py-2 px-2.5 text-center rounded">
            <div className="block text-white">Tue</div>
            <div className="block text-white mt-1">28</div>
          </div>
          <div className="inline-block bg-white1 border border-grey9 py-2 px-2.5 text-center ">
            <div className="block text-green1">Wed</div>
            <div className="block text-grey3 mt-1">29</div>
          </div>
        </div>

        <div className="">
          <div className="border border-grey7 rounded px-2 py-2 block">
            <div className="inline-block align-sub">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
              <div className="text-xs text-grey3">Haircut</div>
            </div>
            <div className="inline-block float-right">
              <div className="text-green1 text-sm font-bold text-end">
                $85.00
              </div>
              <div className="text-xs text-grey3">6:00 PM - 7:00 PM</div>
            </div>
          </div>

          <div className="border border-grey7 rounded px-2 py-2 block mt-3">
            <div className="inline-block align-sub">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
              <div className="text-xs text-grey3">Haircut</div>
            </div>
            <div className="inline-block float-right">
              <div className="text-green1 text-sm font-bold text-end">
                $85.00
              </div>
              <div className="text-xs text-grey3">6:00 PM - 7:00 PM</div>
            </div>
          </div>

          <div className="border border-grey7 rounded px-2 py-2 block mt-3">
            <div className="inline-block align-sub">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
              <div className="text-xs text-grey3">Haircut</div>
            </div>
            <div className="inline-block float-right">
              <div className="text-green1 text-sm font-bold text-end">
                $85.00
              </div>
              <div className="text-xs text-grey3">6:00 PM - 7:00 PM</div>
            </div>
          </div>

          <div className="border border-grey7 rounded px-2 py-2 block mt-3">
            <div className="inline-block align-sub">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
              <div className="text-xs text-grey3">Haircut</div>
            </div>
            <div className="inline-block float-right">
              <div className="text-green1 text-sm font-bold text-end">
                $85.00
              </div>
              <div className="text-xs text-grey3">6:00 PM - 7:00 PM</div>
            </div>
          </div>

          <div className="border border-grey7 rounded px-2 py-2 block mt-3">
            <div className="inline-block align-sub">
              <img
                alt="user"
                src="img/user.png"
                className="rounded-full border border-grey7 p-0.5 w-[32px] h-[32px]"
              />
            </div>
            <div className="inline-block ml-2">
              <div className="text-sm text-black2">Savannah Nguyen</div>
              <div className="text-xs text-grey3">Haircut</div>
            </div>
            <div className="inline-block float-right">
              <div className="text-green1 text-sm font-bold text-end">
                $85.00
              </div>
              <div className="text-xs text-grey3">6:00 PM - 7:00 PM</div>
            </div>
          </div>

          {matches ? (
            ""
          ) : (
            <div className="text-green1 font-bold mt-4 text-sm">View all</div>
          )}
        </div>
      </div>
      {matches ? <Footer /> : ""}
    </>
  );
}

export default UpcomingAppointment;
