import React from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Header from "./header";
import Footer from "./mobileFooter";
import ContactHours from "./contactHours";
import TimeSlotView from "./timeSlotView";

function TimeSlotProfessional() {
  const matches = UseMediaQuery("(max-width: 992px)");

  return (
    <>
      {matches ? <Header /> : ""}
      <div
        className={` ${
          matches ? "min-h-screen" : "border border-grey7"
        } bg-white rounded p-4 mt-4 `}
      >
        <ContactHours />
      </div>
      {/* <div
        className={` ${
          matches ? "" : "border border-grey7"
        } bg-white rounded p-4 mt-4`}
      >
        <TimeSlotView />
      </div> */}
      {matches ? <Footer /> : ""}
    </>
  );
}

export default TimeSlotProfessional;
