import React, { useState } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import OuterMenu from "./outerMenu";
import OuterFooter from "./outerFooter";
import Footer from "./mobileFooter";
import PaymentSummary from "./paymentSummary";
import ProductSummary from "./productSummary";

const Cart = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const [type, setType] = useState("service");

  const handleType = (type) => {
    setType(type);
  };

  return (
    <div className="">
      <div className=" mb-5">
        <div className="mt-3 border-b border-grey2 pb-2">
          <div className="pb-2">
            <OuterMenu page="other" cartClr="black" />
          </div>
        </div>

        <div className="mt-12 xl:px-44 lg:px-44 lg3:px-36 lg2:px-36 md:px-28 sm:px-20 xs:px-10 xxs:px-4">
          <div class="grid grid-cols-2 mt-4 border border-grey1 rounded p-1">
            <button
              onClick={() => handleType("service")}
              class={` ${
                type === "service"
                  ? "bg-green1 text-white rounded"
                  : "   lg:grid-cols-2 md:grid-cols-2 sm-lg:grid-cols-1 xs:lg:grid-cols-1 xxs:lg:grid-cols-1 p-2"
              }`}
            >
              Service
            </button>
            <button
              onClick={() => handleType("product")}
              class={` ${
                type === "product"
                  ? "bg-green1 text-white rounded"
                  : " lg:grid-cols-2 md:grid-cols-2 sm-lg:grid-cols-1 xs:lg:grid-cols-1 xxs:lg:grid-cols-1 p-2"
              }`}
            >
              Products
            </button>
          </div>

          <div className="">
            {type === "service" ? <PaymentSummary /> : <ProductSummary />}
          </div>
        </div>

        <div className="mt-5">
          <OuterFooter />
        </div>
      </div>
      {matches ? <Footer /> : ""}
    </div>
  );
};

export default Cart;
