import React, { useState, useEffect } from "react";
import axios from "axios";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import MySalons from "./mySalon";
import MyBookingsData from "./myBookingsData";
import { toastSuccess, toastError } from "../hooks/toaster";
import DeleteModal from "./deleteModal";
import Model from "./model";

function Bookings() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const matches = UseMediaQuery("(max-width: 992px)");
  const [activeBooking, setActiveBooking] = useState("upcoming");
  const [loading, setLoading] = useState(false);
  const [bookingData, setBookingData] = useState();
  const [bookingCompletedData, setBookingCompletedData] = useState();
  const [bookingCancelData, setBookingCancelData] = useState();
  const [currentData, setCurrentData] = useState();
  const [modalIsOpenDelete, setIsOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [customMsg, setCustomMsg] = useState();

  useEffect(() => {
    fetchData();
    fetchDataCompleted();
    fetchCancelledData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "service/get-booked-booking-service/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setLoading(false);
        setBookingData(response.data);
        setCurrentData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchDataCompleted = async () => {
    try {
      const response = await axios.get(
        API_URL + "service/get-completed-booking-service/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setBookingCompletedData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCancelledData = async () => {
    try {
      const response = await axios.get(
        API_URL + "service/get-cancel-booking-service/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setBookingCancelData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const closeModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleCancel = async (id) => {
    setLoadingCancel(true);
    setDeleteId(id);
    try {
      await axios
        .put(API_URL + "service/cancel-booking/" + id, {
          headers: {
            authorization: "Bearer " + token,
            //"Content-Type": "multipart/form-data",
            // Add any other necessary headers
          },
        })
        .then(function (response) {
          setCustomMsg(
            response.data.message +
              " If you want to cancel appointment without refund, you can proceed."
          );
          setLoadingCancel(false);

          setIsOpenModalDelete(true);
        })
        .catch(function (error) {
          setLoadingCancel(false);
        });
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const handleDelete = async () => {
    setLoadingDelete(true);
    let data = {};
    data["cancel_status"] = true;
    try {
      const response = await axios.put(
        API_URL + "service/cancel-booking/" + deleteId,
        data,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        fetchData();
        fetchDataCompleted();
        fetchCancelledData();
        setLoadingDelete(false);
        setIsOpenModalDelete(false);
        toastSuccess("Booking cancelled successfully");
        setDeleteId("");
      }
    } catch (error) {
      setLoadingDelete(false);
      setIsOpenModalDelete(false);
      console.error("Error fetching data:", error);
      toastError("Booking not cancelled");
      setDeleteId("");
    }
  };

  const handleBooking = (status) => {
    if (status === "upcoming") {
      setCurrentData(bookingData);
    } else if (status === "completed") {
      setCurrentData(bookingCompletedData);
    } else if (status === "cancelled") {
      setCurrentData(bookingCancelData);
    } else {
    }
    setActiveBooking(status);
  };

  return (
    <div className="bg-white1 ">
      <div className="flex">
        <Menu />
        <div className="w-full ">
          <Header />
          <div
            className={` ${matches ? "mx-2 my-2" : "mx-4 my-4"} min-h-screen`}
          >
            <div className="grid grid-cols-6 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-4 xs:grid-cols-4 xxs:grid-cols-4 gap-3">
              <div className={` ${matches ? "col-span-6" : "col-span-4"} `}>
                <div className="bg-white rounded border border-grey7">
                  <div className={` ${matches ? "p-2.5" : "p-4"}`}>
                    <div className=" text-black4 text-base font-semibold">
                      My Bookings
                    </div>
                    <div className="grid grid-cols-3 rounded border border-grey7 p-1 mt-3">
                      <button
                        onClick={() => handleBooking("upcoming")}
                        className={` ${
                          activeBooking === "upcoming"
                            ? "bg-green1 text-white"
                            : "text-black"
                        } px-2 py-2 rounded`}
                      >
                        Upcoming
                      </button>
                      <button
                        onClick={() => handleBooking("completed")}
                        className={` ${
                          activeBooking === "completed"
                            ? "bg-green1 text-white"
                            : "text-black"
                        } px-2 py-2 rounded`}
                      >
                        Completed
                      </button>
                      <button
                        onClick={() => handleBooking("cancelled")}
                        className={` ${
                          activeBooking === "cancelled"
                            ? "bg-green1 text-white"
                            : "text-black"
                        } px-2 py-2 rounded`}
                      >
                        Cancelled
                      </button>
                    </div>

                    <div className="mt-3">
                      <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 xxs:grid-cols-1  gap-3">
                        <MyBookingsData
                          deleteId={deleteId}
                          bookingData={currentData}
                          activeBooking={activeBooking}
                          loading={loading}
                          handleCancel={handleCancel}
                          loadingCancel={loadingCancel}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!matches ? (
                <div className="col-span-2">
                  <>
                    <MySalons />
                  </>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {matches ? <Footer /> : ""}
        </div>
      </div>

      <Model
        modalIsOpen={modalIsOpenDelete}
        closeModal={closeModalDelete}
        content={
          <DeleteModal
            closeModalDelete={closeModalDelete}
            title="Booking"
            handleDelete={handleDelete}
            loading={loadingDelete}
            headerTitle="Cancel"
            customMsg={customMsg}
          />
        }
        dialogClassName="modal-width-30"
      />
    </div>
  );
}

export default Bookings;
