import React from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import OuterMenu from "./outerMenu";
import OuterFooter from "./outerFooter";
import Footer from "./mobileFooter";

const ThankYou = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  return (
    <div className="">
      <div className=" mb-5">
        <div className="mt-3 border-b border-grey2 pb-2">
          <div className="pb-2">
            <OuterMenu page="other" cartClr="black" />
          </div>
        </div>
        <div className="mt-12 xl:px-44 lg:px-44 lg3:px-36 lg2:px-36 md:px-28 sm:px-20 xs:px-10">
          Thank you so much for choosing Pelito for your beauty needs! We are
          thrilled to have you as our valued client.
          <br />
          <br />
          Your appointment has been confirmed , and our talented team can't wait
          to provide you with top-notch service. We are dedicated to making
          every visit a wonderful and rejuvenating experience.
          <br />
          <br /> If you have any special requests or questions before your
          appointment, feel free to reach out to us. We want to ensure that your
          time with us is nothing short of fantastic. <br />
          <br /> We appreciate your trust in us and look forward to pampering
          you soon!
        </div>

        <div className="mt-5">
          <OuterFooter />
        </div>
        {matches ? <Footer /> : ""}
      </div>
    </div>
  );
};

export default ThankYou;
