import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Spinner from "./spinner";
import { toastSuccess, toastError } from "../hooks/toaster";
import { isNumber } from "../hooks/useMediaQuery";

const AddMembers = ({ closeModal, memberId, fetchData }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [loadingImg, setLoadingImg] = useState(false);
  const inputRef = useRef();
  const [imgErr, setImgErr] = useState(false);
  const [count, setCount] = useState(0);
  const [formDataAll, setFormDataAll] = useState({
    first_name: "",
    last_name: "",
    job_title: "",
    capacity: "",
    profile_picture: "",
    contact_no: "",
    email: "",
  });

  useEffect(() => {
    if (memberId) {
      fetchDataNew();
    }
  }, []);

  const fetchDataNew = async () => {
    try {
      const response = await axios.get(API_URL + "member/" + memberId, {
        headers: { authorization: "Bearer " + token },
      });
      if (response) {
        let member = {};
        member["first_name"] = response.data.first_name
          ? response.data.first_name
          : "";
        member["last_name"] = response.data.last_name
          ? response.data.last_name
          : "";
        member["job_title"] = response.data.job_title
          ? response.data.job_title
          : "";
        member["capacity"] = response.data.capacity
          ? response.data.capacity
          : "";
        member["contact_no"] = response.data.contact_no
          ? response.data.contact_no
          : "";
        member["email"] = response.data.email ? response.data.email : "";

        member["profile_picture"] = response.data.profile_picture
          ? response.data.profile_picture
          : "";

        setFormDataAll(member);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUpload = async () => {
    const files = inputRef.current.files;
    setLoadingImg(true);
    if (files.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 5242880) {
          setImgErr(true);
        } else {
          formData.append("media", files[i]);
        }
      }

      if (formData) {
        await axios
          .post(API_URL + "upload/single-upload", formData, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            setLoadingImg(false);
            setFormDataAll({
              ...formDataAll,
              profile_picture: response.data.media,
            });
            setCount(count + 1);
          })
          .catch(function (error) {
            setLoadingImg(false);
            console.log(error);
            toastError("Images not added");
          });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      formDataAll["salon_id"] = userInfo.user_id;
      setLoading(true);
      if (memberId) {
        try {
          await axios
            .put(API_URL + "member/" + memberId, formDataAll, {
              headers: { authorization: "Bearer " + token },
            })
            .then(function (response) {
              setLoading(false);
              fetchData();
              toastSuccess("Member updated successfully");
              closeModal();
            })
            .catch(function (error) {
              toastError(error.response.data.message || "Member not updated");
              console.log(error);
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          toastError(error || "Member not updated");
          console.error("Error:", error);
        }
      } else {
        try {
          await axios
            .post(API_URL + "member", formDataAll, {
              headers: { authorization: "Bearer " + token },
            })
            .then(function (response) {
              setLoading(false);
              fetchData();
              toastSuccess("Member added successfully");
              closeModal();
            })
            .catch(function (error) {
              toastError(error.response.data.message || "Member not added");
              console.log(error);
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          toastError(error || "Member not added");
          console.error("Error:", error);
        }
      }
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formDataAll.first_name.trim()) {
      errors.first_name = "First Name is required";
    }

    if (!formDataAll.last_name.trim()) {
      errors.last_name = "Last Name is required";
    }

    if (!formDataAll.job_title) {
      errors.job_title = "Job Title is required";
    }

    if (!formDataAll.contact_no.trim()) {
      errors.contact_no = "Contact No is required";
    }

    if (!formDataAll.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formDataAll.email)) {
      errors.email = "Invalid email address";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataAll({ ...formDataAll, [name]: value });
  };

  const handleDeleteImg = () => {
    setFormDataAll({ ...formDataAll, profile_picture: "" });
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="block border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          <div className="inline-block">
            {memberId ? "Update" : "Add"} Member{" "}
          </div>
        </div>
      </div>

      <div className="">
        <>
          {loadingImg ? (
            <div className="mt-[-40px]">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="file-container addWork">
                <label className="text-grey3 text-base w-full">
                  Add Profile Picture
                </label>
                <label
                  htmlFor="fileInput"
                  className="bg-grey2 rounded p-4 mt-1 font-semibold opacity-80 cursor-pointer text-lg"
                >
                  <img alt="add" src="img/Add2.png" />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="fileInput"
                  ref={inputRef}
                  onChange={handleUpload}
                  className="file-input"
                />
                {imgErr ? (
                  <div className="text-xs text-red">
                    Image more than 5 MB will not get uploaded
                  </div>
                ) : (
                  ""
                )}
              </div>

              {formDataAll["profile_picture"] ? (
                <div className="relative max-h-[117px] mt-1 max-w-[117px]">
                  <img
                    className="h-[117px] w-[117px] rounded"
                    src={formDataAll["profile_picture"]}
                    alt="Member"
                  />
                  <img
                    alt="delete"
                    onClick={() => handleDeleteImg()}
                    className="absolute top-2 right-2 cursor-pointer"
                    src="img/deleteR.png"
                  />
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </>
        <form onSubmit={handleSubmit}>
          <label className="text-grey3 text-base w-full mt-2">First Name</label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="First Name"
            name="first_name"
            value={formDataAll.first_name}
            onChange={handleChange}
          />
          {errors.first_name && (
            <span className="text-red text-xs">{errors.first_name}</span>
          )}

          <label className="text-grey3 text-base w-full mt-2">Last Name</label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Last Name"
            name="last_name"
            value={formDataAll.last_name}
            onChange={handleChange}
          />
          {errors.last_name && (
            <span className="text-red text-xs">{errors.last_name}</span>
          )}

          <label className="text-grey3 text-base w-full mt-2">Job Title</label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Job Title"
            name="job_title"
            value={formDataAll.job_title}
            onChange={handleChange}
          />
          {errors.job_title && (
            <span className="text-red text-xs">{errors.job_title}</span>
          )}

          <label className="text-grey3 text-base w-full mt-2">
            Contact Number
          </label>
          <input
            type="text"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Contact Number"
            name="contact_no"
            value={formDataAll.contact_no}
            onChange={handleChange}
            onKeyDown={(event) => isNumber(event)}
          />
          {errors.contact_no && (
            <span className="text-red text-xs">{errors.contact_no}</span>
          )}

          <label className="text-grey3 text-base w-full mt-2">Email</label>
          <input
            type="email"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="Email"
            name="email"
            value={formDataAll.email}
            onChange={handleChange}
          />
          {errors.email && (
            <span className="text-red text-xs">{errors.email}</span>
          )}

          <label className="text-grey3 text-base w-full mt-2">
            Capacity to handle clients at once
          </label>
          <input
            type="number"
            className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
            placeholder="2"
            name="capacity"
            value={formDataAll.capacity}
            onChange={handleChange}
          />
          {errors.capacity && (
            <span className="text-red text-xs">{errors.capacity}</span>
          )}

          <div className="float-right mt-4">
            {loading ? (
              <Spinner />
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                >
                  Cancel
                </button>

                <input
                  type="submit"
                  value={memberId ? "Update" : "Add"}
                  name="Add"
                  className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                />
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMembers;
