import React, { useState, useEffect } from "react";
import Spinner from "./spinner";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";
import { UseMediaQuery } from "../hooks/useMediaQuery";

const AddCategory = ({ closeModal, categoryId }) => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const API_URL = process.env.REACT_APP_API_URL;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (categoryId) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setLoadingInit(true);
    try {
      const response = await axios.get(
        API_URL + "interest/category/" + categoryId,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        let category = {};
        category["name"] = response.data.name ? response.data.name : "";

        setFormData(category);
        setLoadingInit(false);
      }
    } catch (error) {
      setLoadingInit(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setLoading(true);
      try {
        if (categoryId) {
          await axios
            .put(API_URL + "interest/category/" + categoryId, formData, {
              headers: { authorization: "Bearer " + token },
            })
            .then(function (response) {
              setLoading(false);
              toastSuccess("Category updated successfully");
              closeModal();
            })
            .catch(function (error) {
              toastError(error.response.data.message || "Category not updated");
              console.log(error);
              setLoading(false);
            });
        } else {
          await axios
            .post(API_URL + "interest/category", formData, {
              headers: { authorization: "Bearer " + token },
            })
            .then(function (response) {
              setLoading(false);
              toastSuccess("Category added successfully");
              closeModal();
            })
            .catch(function (error) {
              toastError(error.response.data.message || "Category not added");
              console.log(error);
              setLoading(false);
            });
        }
      } catch (error) {
        setLoading(false);
        toastError(error || "Category not added");
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="p-2 lg:p-3 md:p-3 sm:p-3 xs:p-2 xxs:p-2 bg-white1 ">
      <div className="mt-4">
        <div className="border-b border-grey9 pb-3 mb-3 text-black2 font-bold text-2xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-lg ">
          {categoryId ? "Update" : "Add"} Category
        </div>
        {!loadingInit ? (
          <form onSubmit={handleSubmit}>
            <label className="text-grey3 text-base w-full">Name</label>
            <input
              type="text"
              className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
              placeholder="Category Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && (
              <span className="text-red text-xs">{errors.name}</span>
            )}

            <div className="float-right mt-4">
              {loading ? (
                <Spinner /> // Show the spinner while loading
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => closeModal()}
                    className="mr-3 text-base font-bold bg-grey2 text-white py-2.5 px-4 shadowEffect hover:opacity-60"
                  >
                    Cancel
                  </button>

                  <input
                    type="submit"
                    value={categoryId ? "Update" : "Add"}
                    name="Add"
                    className="font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                  />
                </>
              )}
            </div>
          </form>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default AddCategory;
