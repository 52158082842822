import React, { useState, useEffect } from "react";
import { Rating } from "react-simple-star-rating";
import Spinner from "./spinner";
import { getDateFormate } from "../hooks/commonFunctions";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";

const MyProductsData = ({ productData }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  const [idArr, setIdArr] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (productData) {
      let newArray = productData.reduce((acc, obj) => {
        acc[obj._id] = 0;
        return acc;
      }, {});
      setIdArr(newArray);
    }
  }, []);

  const updateState = (rate, id) => {
    setIdArr((prevState) => ({
      ...prevState,
      [id]: rate,
    }));
  };

  const handleRating = async (rate, id) => {
    updateState(rate, id);
    let formData = {};
    formData["product_id"] = id;
    formData["user_id"] = userInfo.user_id;
    formData["type"] = "product";
    formData["rating"] = rate;

    setLoading(true);
    try {
      await axios
        .post(API_URL + "rating", formData, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          toastSuccess("Ratings added successfully");
          setLoading(false);
        })
        .catch(function (error) {
          toastError(error.response.data.message || "Ratings not updated");
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <>
      {productData &&
        productData.length > 0 &&
        productData.map((products) => {
          let product = products.item;
          let rating = products.rating;
          return (
            <div className="text-black2">
              <div
                className={`  border-grey9"
                     border px-2.5 py-3 rounded`}
              >
                <div className="text-xs mb-2">
                  {getDateFormate(product.created)}
                </div>
                <div className="block">
                  <div className="inline-block align-bottom mr-2">
                    <img
                      className="max-h-[70px] inline-block"
                      src={
                        product && product.product_id
                          ? product.product_id.img_urls[0]
                          : "img/noImg.jpg"
                      }
                    />
                  </div>
                  <div className="inline-block">
                    <div className="text-lg font-medium ">
                      {product && product.product_id
                        ? product.product_id.name
                        : ""}
                    </div>
                    <div className=" text-grey3 text-sm ">
                      <span className="text-black">Price : </span>${" "}
                      {product && product.product_id
                        ? product.product_id.price
                        : ""}
                    </div>
                    <div className="text-sm font-small  ">
                      <span className="text-black">Qty : </span>
                      <span className="text-grey3">
                        {product ? product.quantity : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="block">
                  {rating ? (
                    <>
                      <img
                        alt="user"
                        src="img/rating.png"
                        className="inline mr-1 h-[10px]"
                      />
                      {rating.avgRating + " (" + rating.totalRatings + ")"}
                    </>
                  ) : (
                    <Rating
                      size="20"
                      onClick={(e) => handleRating(e, product.product_id._id)}
                      initialValue={
                        idArr && idArr[product._id] ? idArr[product._id] : 0
                      }
                      allowFraction={true}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}

      {productData && productData.length === 0 ? (
        <div className="text-center col-span-3">No Products available</div>
      ) : (
        ""
      )}
    </>
  );
};

export default MyProductsData;
