import React, { useState } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Model from "./model";
import BookService from "./calendarViewEnthusiast";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { createBrowserHistory } from "history";

function ServiceList({
  services,
  page,
  openModalUpdate,
  openModalDelete,
  salonId,
  SalonDetail,
  profileName,
}) {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const API_URL = process.env.REACT_APP_API_URL;
  const matches = UseMediaQuery("(max-width: 992px)");
  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [selectedService, setSelectedService] = useState();
  const [serviceSavedData, setServiceSavedData] = useState();
  const [loadingNext, setLoadingNext] = useState(false);

  const openModal = async (data) => {
    const dataToSend = {
      selectedService: data,
      salonId: salonId,
      SalonDetail: SalonDetail,
      profileName: profileName,
    };

    // history.push({
    //   pathname: "/bookService",
    //   state: { data: dataToSend },
    // });
    navigate("/bookService", { state: { data: dataToSend } });
    setSelectedService(data);
    //setIsOpenModal(true);
    // try {
    //   setLoadingNext(true);
    //   // setNextStatus(true);
    //   let formData = {};
    //   formData["customer_user_id"] = userInfo.user_id;
    //   formData["seller_user_id"] = salonId;
    //   formData["service_id"] = data._id;
    //   formData["price"] = data.price;
    //   const response = await axios.post(
    //     API_URL + "service/add-service-booking",
    //     formData
    //   );
    //   if (response) {
    //     setLoadingNext(false);
    //     setServiceSavedData(response.data.savedService);
    //     setSelectedService(data);
    //     setIsOpenModal(true);
    //   }
    // } catch (error) {
    //   setLoadingNext(false);
    //   console.error("Error fetching data:", error);
    // }
  };

  // const closeModal = () => {
  //   setIsOpenModal(false);
  // };

  return (
    <div className="overflow-y-scroll max-h-[330px]">
      {services.length > 0 ? (
        <>
          {!loadingNext ? (
            <>
              {services.map((service, index) => {
                return (
                  <div className="mt-3 ">
                    <div
                      className={` ${
                        matches ? "grid" : "block"
                      } bg-grey1 rounded border border-grey7 mr-2  p-3`}
                    >
                      <div
                        className={` inline-block ${
                          matches ? "max-w-[200px]" : ""
                        } `}
                      >
                        <div className="font-semibold text-black2 lg:text-xl md:text-xl sm:text-xl xs:text-base xxs:text-base">
                          {service.service_name}
                        </div>
                        <div className="lg:text-base md:text-base sm:text-base xs:text-sm xxs:text-sm text-grey3">
                          {service.description}
                        </div>
                        {service.hashtag ? (
                          <div className="lg:text-sm md:text-sm sm:text-sm xs:text-xs xxs:text-xs">
                            {service.hashtag
                              .map((hashtag) => `#${hashtag}`)
                              .join(", ")}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className={` ${
                          matches ? "inline" : "flex"
                        } float-right`}
                      >
                        <div className={`${matches ? "inline" : ""}  `}>
                          <div className={` inline-block  mr-3`}>
                            <div className="text-green1 lg:text-xl md:text-xl sm:text-xl xs:text-base xxs:text-base font-semibold">
                              $
                              {service.price
                                ? parseFloat(service.price).toFixed(2)
                                : 0}
                            </div>
                            <div className="lg:text-base md:text-base sm:text-base xs:text-sm xxs:text-sm">
                              {service.period} mins
                            </div>
                          </div>
                          {page ? (
                            <div
                              className={`inline-block align-top mt-2 ${
                                matches ? "float-right" : ""
                              }`}
                            >
                              <button
                                onClick={() => openModal(services[index])}
                                className="text-white bg-green1 text-center p-2 rounded "
                                type="button"
                              >
                                Book
                              </button>
                            </div>
                          ) : (
                            <>
                              <button
                                className={`align-top inline-block ${
                                  matches ? "float-right" : ""
                                } `}
                                onClick={(id) => openModalUpdate(service._id)}
                              >
                                <img
                                  alt="user"
                                  src="img/edit.png"
                                  className={` ${
                                    matches
                                      ? "h-[24px] w-[24px] ml-[7px]"
                                      : "align-top inline-block"
                                  }`}
                                />
                              </button>
                              <button
                                className={`align-top inline-block ${
                                  matches ? "float-right" : ""
                                } `}
                                onClick={(id) => openModalDelete(service._id)}
                              >
                                <img
                                  alt="user"
                                  src="img/Delete2.png"
                                  className={` ${
                                    matches ? "" : "align-top inline-block"
                                  } h-[17px] w-[17px] ml-[7px] mt-[3px]`}
                                />
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <Spinner />
          )}
        </>
      ) : (
        <span className="text-center mt-3 block font-semibold text-black2 text-base">
          No services added yet.
        </span>
      )}

      {/* <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={
          <BookService
            closeModal={closeModal}
            selectedService={selectedService}
            salonId={salonId}
            salonDetail={SalonDetail}
            profileName={profileName}
          />
        }
        dialogClassName="modal-width-profile"
      /> */}
    </div>
  );
}

export default ServiceList;
