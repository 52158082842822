import React, { useState, useEffect } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import DatePicker from "react-horizontal-datepicker";
import axios from "axios";
import Spinner from "./spinner";
import { toastSuccess } from "../hooks/toaster";
import Model from "./model";
import EnthuSignUp from "./enthuSignUp";
import { useNavigate } from "react-router-dom";
import { convertTime } from "../hooks/commonFunctions";

function BookServiceTime({
  serviceData,
  memberId,
  memberName,
  handleAnotherProfessional,
}) {
  const matches = UseMediaQuery("(max-width: 992px)");
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [slotFullData, setSlotFullData] = useState();
  const [slotData, setSlotData] = useState();
  const [breakData, setBreakData] = useState();
  const [disableData, setDisableData] = useState();
  const [loadingNext, setLoadingNext] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [checkoutStatus, setCheckoutStatus] = useState(false);
  const [selectedTimeSlotErr, setSelectedTimeSlotErr] = useState(false);
  const [onLeaveStatus, setOnLeaveStatus] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCount(count + 1);
  };

  const closeModal = (loginResponse) => {
    setOpenModel(false);
    handleNext(loginResponse.user, loginResponse.token);
  };

  const selectedDay = async (val) => {
    const currentDate = new Date(val);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setSelectedDate(val);
    setCount(count + 1);

    setLoading(true);
    try {
      const response = await axios.get(
        API_URL +
          "slot/get-slot-for-customer/" +
          formattedDate +
          "/" +
          memberId,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setSlotFullData(response.data);
        setBreakData(response.data.breakTimes);
        setDisableData(response.data.disableSlot);
        if (response.data.slot.on_off === "off") {
          setSlotData([]);
          setOnLeaveStatus(true);
        } else {
          const startTime = convertTime(response.data.slot.start_time);
          const endTime = convertTime(response.data.slot.end_time);

          const slotArr = calculateSlots(startTime, endTime);
          const finalSlotArr = [];
          slotArr.map((slot) => {
            if (response.data.breakTimes.length > 0) {
              if (checkBreakHour(slot, response.data.breakTimes)) {
              } else {
                finalSlotArr.push(slot);
              }
            } else {
              if (response && response.data && response.data.slot) {
                finalSlotArr.push(slot);
              }
            }
          });

          //now check for disabled slots
          const finalSlotArrAfterDisable = [];
          finalSlotArr.map((slot) => {
            if (response.data.disableSlot.length > 0) {
              if (checkDisableSlot(response.data.disableSlot, slot)) {
              } else {
                finalSlotArrAfterDisable.push(slot);
              }
            } else {
              if (response && response.data && response.data.slot) {
                finalSlotArrAfterDisable.push(slot);
              }
            }
          });

          setSlotData(finalSlotArrAfterDisable);
        }
        setCount(count + 1);
        setLoading(false);
      }
    } catch (error) {
      setSlotData([]);
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const checkDisableSlot = (disableDataList, slot_time) => {
    let status = false;
    disableDataList.map((timeString) => {
      const timeArray = timeString.split(", ");

      const startTime = convertTime(timeArray[0]);
      const endTime = convertTime(timeArray[1]);
      const checkTime = convertTime(slot_time);
      const isBetween = checkTime >= startTime && checkTime < endTime;
      if (isBetween === true) {
        status = isBetween;
      }
    });
    return status;
  };

  const checkBreakHour = (slot_time, breakTimes) => {
    let status = false;
    breakTimes.map((breakT) => {
      const startTime = convertTime(breakT.start_time);
      const endTime = convertTime(breakT.end_time);
      const checkTime = convertTime(slot_time);
      const isBetween = checkTime >= startTime && checkTime < endTime;
      if (isBetween === true) {
        status = isBetween;
      }
    });
    return status;
  };

  const calculateSlots = (startTime, endTime) => {
    const timeSlots = [];
    const interval = 30 * 60 * 1000; // 30 minutes in milliseconds

    let currentTime = new Date(startTime);

    while (currentTime <= endTime) {
      const formattedTime = currentTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      timeSlots.push(formattedTime);

      currentTime.setTime(currentTime.getTime() + interval);
    }
    return timeSlots;
  };

  function bookedDiv() {
    return (
      <div className="text-center mt-3">
        <img
          alt="calendar"
          src="img/calenderCross.png"
          className="h-[50px] w-[50px] mx-auto"
        />
        <span className="mt-3 block font-bold">
          {onLeaveStatus
            ? memberName + " is on leave today"
            : memberName + " is fully booked today"}
        </span>
        <span className="block text-grey3 text-sm">
          Please select another date or try with other professionals
        </span>
      </div>
    );
  }

  const addAnotherService = () => {
    navigate("/" + serviceData.profileName);
  };

  const handleNext = async (userInfo, token) => {
    if (checkoutStatus) {
      navigate("/cart");
    } else {
      if (selectedTimeSlot) {
        try {
          if (!userInfo) {
            setOpenModel(true);
          } else {
            setLoadingNext(true);

            var isoDate = new Date(
              selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
            ).toISOString();
            let formData = {};
            formData["customer_user_id"] = userInfo.user_id;
            formData["seller_user_id"] = serviceData.salonId;
            formData["service_id"] = serviceData.selectedService._id;
            formData["price"] = serviceData.selectedService.price;
            formData["day"] = isoDate;
            formData["other_charges"] = 0;
            formData["slot_id"] = slotFullData.slot._id;
            formData["booking_time"] = selectedTimeSlot;
            formData["member_id"] = memberId;
            formData["booking_user_type"] = "online";
            const response = await axios.post(
              API_URL + "service/add-service-booking",
              formData,
              {
                headers: {
                  authorization: "Bearer " + token,
                },
              }
            );
            if (response) {
              setLoadingNext(false);
              toastSuccess("Service added to cart successfully");
              setCheckoutStatus(true);
              //navigate("/" + serviceData.profileName);
              //setServiceSavedData(response.savedService);
              //setSelectedService(data);
              // setIsOpenModal(true);
            }
          }
        } catch (error) {
          setLoadingNext(false);
          console.error("Error fetching data:", error);
        }
        setSelectedTimeSlotErr("");
      } else {
        setSelectedTimeSlotErr("Please select time");
      }
    }
  };

  const handleSelectTimeSlot = (time) => {
    setSelectedTimeSlot(time);
  };

  return (
    <div className="lg:w-[80%] md:w-[80%] sm:w-[80%] xs:w-[90%] xxs:w-[90%]">
      <div className="mb-2  text-4xl">Select Time</div>
      <button
        onClick={() => handleAnotherProfessional()}
        className="mt-2 border border-grey9 rounded p-2"
      >
        Try with other professional
      </button>
      <div className="max-w-[100%] mt-4">
        <DatePicker
          color="#539d90"
          onChange={(date) => handleDateChange(date)}
          onClick={(date) => handleDateChange(date)}
          getSelectedDay={(date) => selectedDay(date)}
        />
      </div>

      <div className="mt-4 max-w-[800px]">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {slotData && slotData.length > 0 ? (
              <div className="inline-block w-full">
                <>
                  <div className="overflow-auto whitespace-nowrap pb-3">
                    {slotData.map((slot) => {
                      return (
                        <>
                          <button
                            onClick={() => handleSelectTimeSlot(slot)}
                            className={` ${
                              selectedTimeSlot === slot ? "bg-grey9" : ""
                            } w-auto ml-2 text-left hover:bg-grey9 cursor-pointer mt-2 p-2 rounded border border-grey-7`}
                          >
                            {slot}
                          </button>
                        </>
                      );
                    })}
                  </div>
                  <div className="mt-2">
                    {selectedTimeSlotErr ? (
                      <div className="text-red">{selectedTimeSlotErr}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="float-right">
                    {loadingNext ? (
                      <Spinner />
                    ) : (
                      <>
                        <button
                          type="button"
                          onClick={() => handleNext(userInfo, token)}
                          className={` ${
                            checkoutStatus ? "mr-2" : ""
                          } mt-4 text-base font-bold bg-green1 text-white py-2 px-3 rounded shadowEffect hover:opacity-60`}
                        >
                          {checkoutStatus ? "Checkout" : "Next"}
                        </button>
                        {checkoutStatus ? (
                          <button
                            type="button"
                            onClick={() => addAnotherService()}
                            className={` ${
                              matches ? "mt-2" : "mt-4"
                            } text-base border border-grey9 py-2 px-3 rounded shadowEffect hover:opacity-60`}
                          >
                            Add Another Service
                          </button>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </>
              </div>
            ) : (
              <> {bookedDiv()}</>
            )}
          </>
        )}
      </div>

      <Model
        modalIsOpen={openModel}
        closeModal={closeModal}
        content={<EnthuSignUp closeModal={closeModal} />}
        dialogClassName="modal-width-70"
      />
    </div>
  );
}

export default BookServiceTime;
