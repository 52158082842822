import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import axios from "axios";
import Spinner from "./spinner";
import { getDateFormate } from "../hooks/commonFunctions";

const ProfessionalReport = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(true);
  const [dataMain, setDataMain] = useState();
  const [data, setData] = useState();
  const [memberData, setMemberData] = useState();
  const [memberSelected, setMemberSelected] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "member/get-all-member/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setMemberData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      const response = await axios.get(
        API_URL + "service/seller-report/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setData(response.data);
        setDataMain(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    setMemberSelected(e.target.value);
  };

  const handleFilter = async () => {
    let filteredData = "";
    if (memberSelected && memberSelected != 0) {
      filteredData = dataMain.filter((item) =>
        item.member_id._id.toLowerCase().includes(memberSelected.toLowerCase())
      );
    }
    setData(filteredData);
  };

  const handleReset = () => {
    setMemberSelected("");
    setData(dataMain);
  };

  return (
    <div>
      <div className="bg-white1 ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div
              className={` ${
                matches ? "mx-2 my-2 min-h-screen" : "mx-4 my-4"
              } `}
            >
              <div className="font-bold ">Service History Report</div>

              <div className="inline-block  mt-1">
                <label className="ml-2">Member : </label>
                <select
                  name="member"
                  className="inline-block ml-2 p-2 border border-grey-9 rounded"
                  value={memberSelected}
                  onChange={handleChange}
                >
                  <option value="0">All</option>
                  {memberData &&
                    memberData.length > 0 &&
                    memberData.map((members) => {
                      let member = members.item;
                      return (
                        <option key={member._id} value={member._id}>
                          {member.first_name + " " + member.last_name}
                        </option>
                      );
                    })}
                </select>
              </div>

              {/* <div className="inline-block  mt-1">
                <label className="ml-2">Customer : </label>
                <select
                  name="customer"
                  className="inline-block ml-2 p-2 border border-grey-9 rounded"
                  // value={profSelected}
                  // onChange={handleChange}
                >
                  <option value="0">All</option>
                </select>
              </div> */}

              <div className="inline-block  mt-1">
                <button
                  onClick={handleFilter}
                  className="bg-green1 text-white ml-2 border border-grey-9 rounded font-bold px-3 py-2"
                >
                  Apply
                </button>

                <button
                  onClick={handleReset}
                  className="bg-white ml-2 border border-grey-9 rounded font-bold px-3 py-2"
                >
                  Reset
                </button>
              </div>

              <div class="bg-white mt-3 mb-3 rounded flex flex-col overflow-x-auto">
                <div class="sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div class="overflow-x-auto">
                      <table class="min-w-full text-left text-sm font-light">
                        <thead class="border-b font-medium dark:border-neutral-500">
                          <tr>
                            <th scope="col" class="text-center py-4">
                              Customer
                            </th>
                            <th scope="col" class="text-center py-4">
                              Service
                            </th>
                            <th scope="col" class="text-center py-4">
                              Member
                            </th>
                            <th scope="col" class="text-center py-4">
                              Date
                            </th>
                            <th scope="col" class="text-center py-4">
                              Time
                            </th>
                            <th scope="col" class="text-center py-4">
                              Amount
                            </th>
                            <th scope="col" class=" py-4">
                              Network Fees
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading ? (
                            data && data.length > 0 ? (
                              <>
                                {data.map((serviceData) => {
                                  return (
                                    <tr>
                                      <td class="whitespace-nowrap px-6 py-2 font-medium capitalize text-center">
                                        {serviceData.customer_user_id
                                          ? serviceData.customer_user_id
                                              .first_name
                                          : "-"}
                                      </td>
                                      <td class="whitespace-nowrap px-6 py-2 font-medium capitalize text-center">
                                        {serviceData.service_id
                                          ? serviceData.service_id.service_name
                                          : "-"}
                                      </td>
                                      <td class="whitespace-nowrap px-6 py-2 font-medium capitalize text-center">
                                        {serviceData.member_id
                                          ? serviceData.member_id.first_name +
                                            " " +
                                            serviceData.member_id.last_name
                                          : "-"}
                                      </td>
                                      <td class="whitespace-nowrap px-6 py-2 font-medium capitalize text-center">
                                        {getDateFormate(serviceData.day)}
                                      </td>
                                      <td class="whitespace-nowrap px-6 py-2 font-medium capitalize text-center">
                                        {serviceData.booking_time}
                                      </td>
                                      <td class="whitespace-nowrap px-6 py-2 font-medium capitalize text-center">
                                        ${serviceData.orignal_price}
                                      </td>
                                      <td class="whitespace-nowrap px-6 py-2 font-medium capitalize text-center">
                                        ${serviceData.tax}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center">
                                  No data available
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td colSpan="7">
                                <Spinner />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalReport;
